import axios from "axios";
import React, { useState, useContext } from 'react';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { classNames } from 'primereact/utils';
import AutenticacionContext from "./AutenticacionContext";
import { credencialesUsuario, respuestaAutenticacion } from "./auth.model";
import { guardarTokenLocalStorage, obtenerClaims } from "./manejadorJWT";
import { urlCuentas } from "utils/endpoints";
import { useHistory } from "react-router-dom";
import MostrarErrores from "../utils/MostrarErrores"

import './Login.css';

export const Registro = (props: { registro?: () => void }) => {
    const history = useHistory();
    const { actualizar } = useContext(AutenticacionContext);
    const [showMessage, setShowMessage] = useState(false);
    const [errores, setErrores] = useState<string[]>([]);

    async function create(credenciales: credencialesUsuario) {
        try {
            const respuesta = await axios.post<respuestaAutenticacion>(`${urlCuentas}/crear`, credenciales);
            guardarTokenLocalStorage(respuesta.data);
            actualizar(obtenerClaims());
            formik.resetForm();
            history.push("/ordenesdetrabajo");
        }
        catch (error: any) {
            setErrores(error.response ? error.response.data : [error.message]);
        }
    }

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            nombres: '',
            apellido: '',
        },
        validate: (data) => {
            let errors = { email: '', password: '', nombres: '', apellido: '' };
            let error: boolean = false;

            if (!data.email) {
                errors.email = 'Debe ingresar el correo.';
                error = true;
            }
            else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
                errors.email = 'Debe ingresar un correo válido Ej. example@email.com';
                error = true;
            }

            if (!data.password) {
                errors.password = 'Debe indicar la contraseña.';
                error = true;
            }

            if (!data.nombres) {
                errors.nombres = 'Debe ingresar un nombre.';
                error = true;
            }

            if (!data.apellido) {
                errors.apellido = 'Debe ingresar un apellido.';
                error = true;
            }

            if (error)
                return errors;
        },
        onSubmit: (data) => {
            create(data);
        },
    });

    //const isFormFieldValid = (name: string) => !!(formik.touched[name] && formik.errors[name]);
    const isFormFieldValid = (name: string) => !!(formik.getFieldMeta(name).touched && formik.getFieldMeta(name).error);

    const getFormErrorMessage = (name: string) => {
        // return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
        return isFormFieldValid(name) && <small className="p-error">{formik.getFieldMeta(name).error}</small>;
    };

    const dialogFooter = <div className="p-d-flex p-jc-center"><Button label="OK" className="p-button-text" onClick={() => setShowMessage(false)} /></div>;
    const passwordHeader = <h6>Contraseña</h6>;
    const passwordFooter = (
        <React.Fragment>
            <Divider />
            <p className="p-mt-2">Sugerencias</p>
            <ul className="p-pl-2 p-ml-2 p-mt-0" style={{ lineHeight: '1.5' }}>
                <li>Debe contener al menos, una letra minúscula</li>
                <li>Debe contener al menos, una letra mayúscula</li>
                <li>Debe contener al menos, un número</li>
                <li>8 caracteres como mínimo</li>
            </ul>
        </React.Fragment>
    );

    return (
        <div className="grid">
            <Dialog visible={showMessage} onHide={() => setShowMessage(false)} position="top" footer={dialogFooter} showHeader={false} breakpoints={{ '960px': '80vw' }} style={{ width: '30vw' }}>
                <div className="p-d-flex p-ai-center p-dir-col p-pt-6 p-px-3">
                    <i className="pi pi-check-circle" style={{ fontSize: '5rem', color: 'var(--green-500)' }}></i>
                    <h5>Registration Successful!</h5>
                </div>
            </Dialog>
            <div className="col-12 p-mx-auto p-mt-3">
                <div className="p-fluid">
                    <h5>Registrar un nuevo usuario</h5>
                    <form onSubmit={formik.handleSubmit} className="p-fluid">
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col p-mt-3">
                                <span className="p-float-label">
                                    <InputText id="nombres" name="nombres" value={formik.values.nombres} onChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('nombres') })} />
                                    <label htmlFor="nombres" className={classNames({ 'p-error': isFormFieldValid('nombres') })}>Nombre*</label>
                                </span>
                                {getFormErrorMessage('nombres')}
                            </div>
                            <div className="p-field p-col p-mt-3">
                                <span className="p-float-label">
                                    <InputText id="apellido" name="apellido" value={formik.values.apellido} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('apellido') })} />
                                    <label htmlFor="apellido" className={classNames({ 'p-error': isFormFieldValid('apellido') })}>Apellido*</label>
                                </span>
                                {getFormErrorMessage('apellido')}
                            </div>
                        </div>

                        <div className="p-field p-mt-3">
                            <span className="p-float-label p-input-icon-right">
                                <i className="pi pi-envelope" />
                                <InputText id="email" name="email" value={formik.values.email} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('email') })} />
                                <label htmlFor="email" className={classNames({ 'p-error': isFormFieldValid('email') })}>Email*</label>
                            </span>
                            {getFormErrorMessage('email')}
                        </div>
                        <div className="p-field p-mt-5">
                            <span className="p-float-label">
                                <Password id="password" name="password" value={formik.values.password} onChange={formik.handleChange} toggleMask
                                    className={classNames({ 'p-invalid': isFormFieldValid('password') })} header={passwordHeader} footer={passwordFooter} weakLabel="Débil" mediumLabel="Regular" strongLabel="Fuerte" />
                                <label htmlFor="password" className={classNames({ 'p-error': isFormFieldValid('password') })}>Contraseña*</label>
                            </span>
                            {getFormErrorMessage('password')}
                        </div>
                        <div className="p-grid p-justify-end">
                            <Button style={{ width: "100px" }} type="submit" label="Registrar" className="p-button-success p-col-2 p-mr-2 p-mt-2" />
                        </div>
                    </form>
                    <MostrarErrores errores={errores} />
                </div>

            </div>
        </div>
    );
}
