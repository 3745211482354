import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import DocumentPDF from "./DocumentPDF";
import DocumentWeb from "./DocumentWeb";
import { Dialog } from 'primereact/dialog';
import { useState } from "react";

export default function ViewDocumentPDF(props: any) {
    const [dialog, setDialog] = useState(true)
    return (
        <>
            <Dialog className='crud-dialog' closable={true} closeOnEscape={true} visible={dialog} blockScroll={true} position={'center'} onHide={() => {
                setDialog(false);
                if (props.onClose) {
                    props.onClose();
                }
            }} draggable={false} modal={true} >
                {props.viewWeb ?
                    <DocumentWeb props={props} />
                    :
                    <PDFViewer style={{ width: "100%", height: "90vh" }} >
                        <DocumentPDF props={props} />
                    </PDFViewer>
                }
            </Dialog>
        </>
    )
}