import { Button } from 'primereact/button';
import { useState, useRef, useEffect, useContext } from 'react';
import { Toolbar } from 'primereact/toolbar';
import { Toast } from 'primereact/toast';
import { TieredMenu } from 'primereact/tieredmenu';
import { IStockFiltros, IStock } from 'app/dto/stock.mode'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ITableColumn } from 'utils/interfaces'
import * as func from 'utils/formDataUtils'
import { GenericService } from 'app/service/GenericService';
import { urlStock } from 'app/service/Endpoints'
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { exportPDF } from 'app/service/exportPDF';

import Inventario from './Inventario'
import Transferencia from './Transferencia';
import Baja from './Baja'
import Entrada from './Entrada'
import Filtros from 'app/stock/Filtros'
import Movimientos from './Movimientos';
import MostrarErrores from "utils/MostrarErrores";
import * as template from 'app/general/DataTableTemplate'
import * as userControl from 'auth/UserDataControl'
import EntitiesContext from 'context/EntitiesContext';
import Autorizado from 'auth/Autorizado';
import AutenticacionContext from 'auth/AutenticacionContext';

export default function Stock(props: any) {

    const { claims } = useContext(AutenticacionContext)
    const entitiesContext = useContext(EntitiesContext);

    const [rows, setRows] = useState<IStock[]>();
    const [filters, setFilters] = useState<IStockFiltros>();
    const [refresh, setRefresh] = useState(true);
    const [globalFilter, setGlobalFilter] = useState('');
    const [devolucion, setDevolucion] = useState(false);
    const [selectedInsumo, setSelectedInsumo] = useState<any>(undefined);


    const [viewInventario, setViewInventario] = useState(false)
    const [viewTransferencia, setViewTransferencia] = useState(false)
    const [viewBaja, setViewBaja] = useState(false)
    const [viewEntrada, setViewEntrada] = useState(false)
    const [viewDetalle, setViewDetalle] = useState(false)
    const [errores, setErrores] = useState<string[]>([]);

    const [transferenciaValues, setTransferenciaValues] = useState({ empresa: undefined, deposito: undefined, insumo: undefined })

    const toast = useRef<Toast>(null);
    const menu = useRef<TieredMenu>(null);

    const service = new GenericService();


    // {
    //     "producto": {
    //     "id": 1,
    //     "nombre": "Rizoderma",
    //     "presentacion": "4 vejigas de 1L cada una",
    //     "unidad": "Mililitro"
    //     },
    //     "stock": 68
    //     },

    const lista: ITableColumn[] = [
        { id: 0, field: 'producto.id', hide: true, sortable: false, disabledEdit: true, header: 'Id', align: 'right', printable: true },
        { id: 1, field: 'producto.nombre', hide: false, sortable: true, disabledEdit: true, header: 'Insumo', printable: true },
        { id: 2, field: 'producto.presentacion', hide: false, sortable: true, disabledEdit: true, header: 'Presentación', printable: true },
        { id: 3, field: 'stock', hide: false, sortable: true, type: 'number', showMask: '#.##0,00', align: 'right', disabledEdit: false, header: 'Stock', printable: true },
        { id: 4, field: 'producto.unidad', hide: false, sortable: true, disabledEdit: true, header: 'Unidad', printable: true },
    ]

    useEffect(() => {
        let roles = claims.filter(claim => claim.nombre === 'role')
        if (refresh) {
            //console.log(filters?.insumotipo)
            let param = {
                empresaid: filters?.empresa ? filters?.empresa.id : undefined,
                depositoid: filters?.deposito ? filters?.deposito.id : undefined,
                insumotipo: filters?.insumotipo ? filters?.insumotipo.Id : undefined,
            }
            //console.log("param: ", param)
            if (roles.length !== 0) {
                service.get({ url: urlStock, creacion: undefined, params: param }).then(response => {
                    //console.log("data", data)
                    //console.log(JSON.parse(data[3]['condiciones']))
                    setSelectedInsumo(undefined);
                    if (response.success) {
                        setRows(response.data);
                        //setLoading(false)
                    } else {
                        setErrores(response.error)
                    }
                })
            }
            setRefresh(false)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refresh]);



    const items = [
        {
            label: 'por Compras',
            icon: 'pi pi-shopping-cart',
            items: [
                {
                    label: 'Compra',
                    icon: 'pi pi-arrow-up',

                    command: () => {
                        //toast.current!.show({ severity: 'success', summary: 'por Compras', detail: 'Compra', life: 3000 });
                        setDevolucion(false)
                        setViewEntrada(true)
                    }
                },
                {
                    label: 'Devolución',
                    icon: 'pi pi-arrow-down',
                    command: () => {
                        //toast.current!.show({ severity: 'warn', summary: 'por Compras', detail: 'Devolución', life: 3000 });
                        setDevolucion(true)
                        setViewEntrada(true)

                    }
                }
            ]
        },
        {
            label: 'Inventario',
            icon: 'pi pi-eye',
            command: () => {
                //toast.current!.show({ severity: 'warn', summary: 'Inventario', detail: 'Inventario', life: 3000 });
                setViewInventario(true)
            }
        },
        {
            label: 'Transferencia',
            icon: 'pi pi-sitemap',
            command: () => {
                //toast.current!.show({ severity: 'warn', summary: 'Inventario', detail: 'Inventario', life: 3000 });
                setViewTransferencia(true)
            }
        },
        {
            label: 'Baja',
            icon: 'pi pi-thumbs-down',
            command: () => {
                //toast.current!.show({ severity: 'warn', summary: 'Inventario', detail: 'Inventario', life: 3000 });
                setViewBaja(true)
            }
        }

    ];

    const RefreshMenu = () => {
        return (
            <>
                {/* <Link className="btn btn-success" to={'/ordendetrabajo'}>Agregar</Link> */}
                <Button icon="pi pi-refresh"
                    onClick={() => {
                        setRefresh(true);
                        toast.current!.show({ severity: 'success', detail: 'Leyendo datos...', life: 1000 });
                    }} className="p-button-success" style={{ marginRight: '.5em' }}
                />
                {/* <Button icon="pi pi-file-o" onClick={() => exportCSV(false)} className="p-mr-2" data-pr-tooltip="CSV" style={{ marginRight: '.5em' }} /> */}
                <Button icon="pi pi-file-pdf" onClick={exportPdf} className="p-button-warning p-mr-2" data-pr-tooltip="PDF" style={{ marginRight: '.5em' }} />
            </>
        )

    }


    const toolbarLeftTemplate = () => {
        if (userControl.ProcesoHabilitado({ user: entitiesContext.user, resource: 'Stock', process: 'agregar', showMessage: false })) {
            return (
                <>
                    <Button label="Agregar" icon="pi pi-bars" style={{ marginRight: '.5em' }} onClick={(event) => menu.current?.toggle(event)} aria-controls="popup_menu" aria-haspopup />
                    <TieredMenu model={items} popup ref={menu} id="popup_menu" />
                    <RefreshMenu />
                </>
            )
        } else {
            return (
                <RefreshMenu />
            )
        }
    };

    const exportColumns = lista!.map(col => col.printable ? ({ title: col.header, dataKey: col.header }) : undefined);

    const exportPdf = () => {
        //console.log(rows)
        //console.log(exportColumns)
        //console.log(filters?.insumotipo)
        let subTitle: string = "";
        subTitle = filters?.empresa ? filters?.empresa.razonsocial : ''
        subTitle = filters?.deposito ? subTitle + "; Depósito: " + filters?.deposito.nombre : subTitle
        subTitle = filters?.insumotipo ? subTitle + "; Tipo de Insumo: " + filters?.insumotipo.Nombre : subTitle
        const exportRows: any[] = [];

        rows!.forEach(element => {
            exportRows.push({
                Id: element.producto.id, Insumo: element.producto.nombre, Presentación: element.producto.presentacion,
                Stock: func.showNumber(Number(element.stock), '#.##0,00'), Unidad: element.producto.unidad
            })
        });
        //console.log(exportRows)
        exportPDF({ exportColumns: exportColumns, rows: exportRows, name: "Stock", title: "Stock de Insumos", subtitle: subTitle, columns: lista })
    }

    const actionBodyTemplate = (rowData: any) => {
        //console.log(rowData)
        return (
            <div>
                <Button icon="pi pi-sitemap" tooltip='Transferir' className="p-button-rounded p-button-default p-button-text" onClick={() => {
                    //console.log(rowData)
                    if (userControl.ProcesoHabilitado({ user: entitiesContext.user, resource: 'Stock', process: 'agregar', showMessage: true, processMessage: 'Transferir' })) {
                        setTransferenciaValues({ empresa: filters?.empresa, deposito: filters?.deposito, insumo: rowData.producto.id })
                        setViewTransferencia(true)
                    }
                }} />
            </div>
        );
    }

    const changedFilters = (values: IStockFiltros) => {
        //console.log("filters", values)
        setFilters(values)
        setRefresh(true)
    }

    const TableHeader = (
        <div className="table-header">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText style={{ width: '300px' }} className="" value={globalFilter} onChange={(e) => {
                    setGlobalFilter(e.target.value); //console.log(e.target.value) 
                }} placeholder="Buscar" />
            </span>
        </div>
    );

    const bodyTemplate = (data: any, colProps: any) => {
        return func.bodyTemplate(data, colProps, { columns: lista })
    }

    const dialogFooterTemplate = () => {
        return (
            <>
                <Divider />
                <Button label="Aceptar" icon="pi pi-check" onClick={() => setViewDetalle(false)} />
            </>
        )

    }

    return (
        <Autorizado autorizado={
            <>
                <div className="grid">
                    <div className="col-12">
                        <div className="card" style={{ minHeight: '86vh' }}>
                            {(!viewInventario && !viewTransferencia && !viewBaja && !viewEntrada) &&
                                <>
                                    <h5>Stock de insumos</h5>
                                    <Toolbar left={toolbarLeftTemplate}></Toolbar>
                                    <Toast ref={toast} />
                                    <Filtros values={filters} onChanged={(values: IStockFiltros) => changedFilters(values)} />
                                    <div className="card">
                                        <h6>Detalle</h6>
                                        {/* {console.log("", props.insumoseleccionadoaplicacion)} */}
                                        {/* onRowEditInit={onRowEditInit} onRowEditSave={onRowEditSave} onRowEditCancel={onRowEditCancel} */}
                                        {/* editor={(props) => edit(col, props)} body={bodyTemplate} */}
                                        <DataTable value={rows} sortField={'orden'} sortMode={'single'} dataKey="producto.id" globalFilter={globalFilter} emptyMessage="No hay registros." rowHover onRowReorder={undefined} sortOrder={1} className="p-datatable-sm" paginator rows={10} paginatorRight paginatorTemplate={template.dataTableTemplate} header={TableHeader} selectionMode="single" selection={selectedInsumo} loadingIcon={'pi pi-spinner'} onSelectionChange={e => { setSelectedInsumo(e.value); setViewDetalle(true) }} >
                                            {lista!.map(col =>
                                                !col.hide ?
                                                    <Column field={col.showField ? col.showField : !col.fieldcamelCase ? col.field : col.fieldcamelCase} key={col.id} className={col.align ? 'p-text-' + col.align : 'p-text-left'} header={col.header} filterType={col.type} sortable={col.sortable} body={bodyTemplate} >
                                                    </Column> : null)}
                                            <Column body={actionBodyTemplate} header="Acciones" />
                                        </DataTable>
                                        <MostrarErrores errores={errores} />
                                    </div>
                                </>}
                            {viewInventario && <Inventario hide={(refreshToBack) => {
                                setViewInventario(false)
                                if (refreshToBack) {
                                    setRefresh(true)
                                }
                            }} />}
                            {viewTransferencia && <Transferencia values={transferenciaValues} hide={(refreshToBack) => {
                                setViewTransferencia(false)
                                if (refreshToBack) {
                                    setRefresh(true)
                                }
                            }} />}
                            {viewBaja && <Baja hide={(refreshToBack) => {
                                setViewBaja(false)
                                if (refreshToBack) {
                                    setRefresh(true)
                                }
                            }} />}
                            {viewEntrada && <Entrada devolucion={devolucion} hide={(refreshToBack) => {
                                setViewEntrada(false)
                                if (refreshToBack) {
                                    setRefresh(true)
                                }
                            }} />}
                            {viewDetalle &&
                                <>
                                    <Dialog header={"Movimientos de " + selectedInsumo?.producto.nombre + ' / ' + selectedInsumo?.producto.presentacion} visible={viewDetalle} style={{ width: '75vw' }} maximizable modal
                                        contentStyle={{ height: '600px' }} onHide={() => setViewDetalle(false)} footer={dialogFooterTemplate}>
                                        <Movimientos empresa={filters?.empresa} deposito={filters?.deposito} insumo={selectedInsumo} />
                                    </Dialog>

                                </>}


                        </div>
                    </div>
                </div>
            </>} />
    )

}
