import Swal from "sweetalert2";
import { SweetAlertIcon } from "sweetalert2"
import { confirmDialog } from 'primereact/confirmdialog';

export function confirmar(
    onConfirm: any,
    titulo: string = "¿Desea borrar el registro?",
    textoBotonConfirmacion: string = "Borrar",
    invertColor: boolean = false,
    iconImage: SweetAlertIcon = 'warning',
    confirmColor: string = '#3085d6',
    cancelColor: string = '#d33',
) {
    if (invertColor) {
        let color = confirmColor;
        confirmColor = cancelColor;
        cancelColor = color;
    }
    Swal.fire({
        title: titulo,
        confirmButtonText: textoBotonConfirmacion,
        icon: iconImage,
        showCancelButton: true,
        confirmButtonColor: confirmColor,
        cancelButtonColor: cancelColor
    }).then(result => {
        if (result.isConfirmed) {
            onConfirm();
        }
    })
}

export function confirmDelete(
    event: any,
    onConfirm: any,
) {
    confirmDialog({
        message: 'Confirma eliminar el registro? Id ' + event.id,
        header: 'Eliminar registro',
        icon: 'pi pi-question-circle',
        rejectLabel: "Sí",
        rejectIcon: 'pi pi-check',
        acceptClassName: 'p-button-rounded p-button-success p-button-text',
        rejectClassName: 'p-button-rounded p-button-danger p-button-text',
        acceptIcon: 'pi pi-times',
        acceptLabel: "No",
        resizable: false,
        reject: () => onConfirm(event),
        accept: () => null,
    });
};

export function confirmClear(
    event: any,
    onConfirm: any,
) {
    confirmDialog({
        message: 'Confirma limpiar el formulario?',
        header: 'Limpiar formulario',
        icon: 'pi pi-question-circle',
        rejectLabel: "No",
        rejectIcon: 'pi pi-check',
        acceptClassName: 'p-button-rounded p-button-success p-button-text',
        rejectClassName: 'p-button-rounded p-button-danger p-button-text',
        acceptIcon: 'pi pi-times',
        acceptLabel: "Sí",
        resizable: false,
        reject: () => null,
        accept: () => onConfirm(event),
    });
};
