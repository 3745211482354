import { useState, useEffect, useRef, ReactNode, useContext } from 'react';
import { useHistory } from "react-router-dom";
import React from 'react'

import { Toolbar } from 'primereact/toolbar';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { MultiSelect } from 'primereact/multiselect';
import { Column } from 'primereact/column';
import { GenericService } from 'app/service/GenericService';
import { InputText } from 'primereact/inputtext';
import { ITableColumn, TOptions } from 'utils/interfaces'
import * as func from 'utils/formDataUtils'
import { urlOrdenDeTrabajo, urlOrdenDeTrabajoEstado, urlOrdenDeTrabajoFiltrado } from 'app/service/Endpoints'
import OrdenDeTrabajo from './OrdenDeTrabajo';
import { confirmDelete } from 'utils/Confirmar'
import EntitiesContext from 'context/EntitiesContext'
import { perfilusuarioDTO } from 'app/dto/usuarios.model'
import showMensaje from 'utils/Mensaje'
import MostrarErrores from "utils/MostrarErrores";
import Autorizado from 'auth/Autorizado'
import * as userControl from 'auth/UserDataControl'

import Opciones from 'utils/Opciones'

import ViewDocumentPDF from 'app/components/ViewDocumentPDF'
import * as template from 'app/general/DataTableTemplate'
import AutenticacionContext from 'auth/AutenticacionContext';

export default function OrdenesDeTrabajo(props: any) {

    const toast = useRef<Toast>(null);

    const history = useHistory();

    const entitiesContext = useContext(EntitiesContext);
    const { claims } = useContext(AutenticacionContext)

    const [refresh, setRefresh] = useState(false);
    const [globalFilter, setGlobalFilter] = useState('');
    const [rows, setRows] = useState<any[]>();
    const [loading, setLoading] = useState(true);
    const [editOrden, setEditOrden] = useState(false);
    const [exportToPDF, setExportToPDF] = useState();
    const [viewCambiarEstado, setViewCambiarEstado] = useState(false)
    const [options, setOptions] = useState<{ id: number, current?: string, title?: string, selected?: any, options: TOptions[] }>()
    const [userActivo, setUserActivo] = useState<perfilusuarioDTO>()
    const [errores, setErrores] = useState<string[]>([]);
    const [filtroEstado, setFiltroEstado] = useState(['En Proceso']);

    const service = new GenericService();

    const columns: ITableColumn[] = [
        { id: 0, field: 'id', isKey: true, hide: false, disabled: true, header: 'Id' },
        { id: 2, field: 'fecha', hide: false, required: true, colInput: 0, type: 'date', header: 'Fecha' },
        { id: 3, field: 'campaña.nombre', hide: true, required: true, colInput: 0, header: 'Campaña' },
        { id: 4, field: 'empresa.razonsocial', hide: false, header: 'Empresa', disabled: false, colInput: 1, sortable: false },
        { id: 5, field: 'usuario.nombres', hide: false, required: true, header: 'De' },
        { id: 5, field: 'usuariodestino.nombres', hide: false, required: true, header: 'Para' },
        { id: 5, field: 'establecimiento.nombre', hide: false, required: true, header: 'Establecimiento' },
        { id: 6, field: 'especie.nombre', hide: false, required: true, header: 'Especie' },
        { id: 7, field: 'labor.nombre', hide: false, required: true, header: 'Labor' },
        { id: 8, field: 'hectareas', hide: false, required: true, type: 'number', header: 'Has', align: 'right' },
        { id: 9, field: 'estado', hide: false, required: false, type: 'estado', header: 'Estado' },
    ]

    const toolbarLeftTemplate = () => {
        const estados = [
            { label: 'En Proceso', value: 'En Proceso' },
            { label: 'Pendientes', value: 'Pendientes' },
            { label: 'Enviadas', value: 'Enviadas' },
            { label: 'Aceptadas', value: 'Aceptadas' },
            { label: 'Rechazadas', value: 'Rechazadas' },
            { label: 'Finalizadas', value: 'Finalizadas' },
        ]
        return (
            <>
                <Button label="Agregar" icon="pi pi-plus" onClick={() => openNew()} style={{ marginRight: '.5em' }} />
                {/* <Link className="btn btn-success" to={'/ordendetrabajo'}>Agregar</Link> */}
                <Button icon="pi pi-refresh" onClick={() => setRefresh(true)} className="p-button-success" style={{ marginRight: '.5em' }} />
                {/* <Button icon="pi pi-file-o" onClick={() => exportCSV(false)} className="p-mr-2" data-pr-tooltip="CSV" style={{ marginRight: '.5em' }} /> */}
                {/* <Button icon="pi pi-file-pdf" onClick={exportPdf} className="p-button-warning p-mr-2" data-pr-tooltip="PDF" style={{ marginRight: '.5em' }} /> */}
                <div>
                    <span> Filtrar por estado </span>
                    <MultiSelect name="filtroEstado" id="filtroEstado" disabled={false} style={{ marginTop: "0.3rem" }} value={filtroEstado} options={estados} optionLabel="label" optionValue="value" display={'chip'} placeholder={'---seleccione---'} onChange={(e) => {
                        //console.log(e)
                        //console.log(e.value)
                        let values: string[] = [];
                        if (e.value !== null) {
                            values = e.value;
                        }
                        if (values.length !== 0) {
                            if (values.length > 1) {
                                if (values[values.length - 1] === "En Proceso") {
                                    values = ["En Proceso"]
                                } else {
                                    values = values.filter(x => x !== "En Proceso")
                                }

                            }
                        }
                        setFiltroEstado(values)
                        setRefresh(true)
                    }} emptyFilterMessage={'No hay resultados'} showSelectAll={false} showClear />
                </div>
            </>
        )
    };


    useEffect(() => {
        setErrores([])
        let url = ""
        let valores = ''
        let roles = claims.filter(claim => claim.nombre === 'role')
        if (refresh) {
            toast.current!.show({ severity: 'success', detail: 'Leyendo datos...', life: 1000 });
        }

        url = urlOrdenDeTrabajoFiltrado

        if (filtroEstado) {
            if (filtroEstado.length !== 0) {
                filtroEstado.forEach((x, index) => {
                    valores = valores + x + ";"
                })
                valores = valores.substring(0, valores.length - 1)
            }
        }
        //console.log(url)
        if (roles.length !== 0) {
            service.get({ url, creacion: undefined, params: { estados: valores } }).then(response => {
                if (response.success) {
                    let _rows: any[] = [...response.data]
                    //console.log("data", data)
                    //console.log(JSON.parse(data[3]['condiciones']))
                    _rows.forEach(element => {
                        let _lotes: any[] = [...element['lotes']]
                        let hectareas: number = 0;
                        _lotes.forEach(element => {
                            hectareas = hectareas + element.hectareasatrabajar
                        });
                        element.hectareas = hectareas;
                        //console.log(hectareas)
                    });
                    setRows(_rows);
                    //console.log(_rows)
                    setLoading(false)
                } else {
                    setErrores(response.error)
                    setLoading(false)
                }
                //console.log(data[0]['lotes'][0]['hectareasatrabajar'])
            })

        } else {
            setLoading(false)
        }
        setRefresh(false)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refresh]);



    useEffect(() => {
        if (entitiesContext.user) {
            setUserActivo(entitiesContext.user)
        }
    }, [entitiesContext.user])


    const TableHeader = (
        <div className="table-header">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText style={{ width: '300px' }} className="" value={globalFilter} onChange={(e) => { setGlobalFilter(e.target.value); console.log(e.target.value) }} placeholder="Buscar" />
            </span>
        </div>
    );

    const openNew = () => {
        //history.push("/ordendetrabajo")
        if (userControl.ProcesoHabilitado({ user: entitiesContext.user, resource: 'Órden de trabajo', process: 'agregar', showMessage: true })) {
            history.push("/ordendetrabajo")
        }
    }

    const edit = (row: any) => {
        //console.log(row)
        //history.push("/ordendetrabajo")
        if (userControl.ProcesoHabilitado({ user: entitiesContext.user, resource: 'Órden de trabajo', process: 'editar', showMessage: true })) {
            setEditOrden(row)
        }
    }

    const acceptDelete = (event: any) => {
        //alert(props.url)
        service.delete({ url: urlOrdenDeTrabajo, id: event.id, creacion: undefined }).then(data => {
            //alert(data)
            if (!data) {
                toast.current!.show({ severity: 'success', summary: 'Proceso finalizado', detail: 'Eliminar registro Id ' + event.id, life: 2000 })
                let _rows = [...rows!].filter(x => x.id !== event.id)
                setRows(_rows)
            } else {
                let men: ReactNode = <div>No se pudo eliminar el registro.<br />{data}</div>
                toast.current!.show({ severity: 'error', summary: 'Error', detail: men, life: 3000 });
            }
        })
    };

    const bodyTemplate = (data: any, colProps: any) => {
        //console.log(data.estado)
        let row = columns?.filter((f: any) => f.field === colProps.field)
        const { type } = row[0]
        let style: string = ''
        if (type === 'estado') {
            //console.log("llama a changed")
            return (
                <ChangedState rowData={data} />
            )
        }
        return (
            <React.Fragment>
                <span className={style}>
                    {
                        func.bodyTemplate(data, colProps, { columns: columns })
                    }
                </span>
            </React.Fragment>
        )
    };


    const actionBodyTemplate = (rowData: any) => {
        //alert(rowData.estado)
        return (
            <div>

                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-button-circle" onClick={() => edit(rowData)} />
                <span> </span>

                <Button icon="pi pi-file-pdf" style={{ color: 'var(--pink-500)', backgroundColor: 'var(--blue-800)', borderColor: 'var(--blue-900)' }} className="p-button-rounded p-button-circle" onClick={() => setExportToPDF(rowData)} />
                <span> </span>

            </div>
        );
    }
    const ChangedState = (changedStateProps: any) => {
        const { rowData } = changedStateProps
        //console.log("rowdata", rowData)
        return (
            <>
                <Button icon="pi pi-ellipsis-h" iconPos='right' label={rowData.estado!} className={`pedido estado-${String(rowData.estado).toLocaleLowerCase()}`}
                    onClick={() => {

                        let options: TOptions[] = [];
                        let selected: any = undefined;

                        switch (rowData.estado) {
                            case "Pendiente":
                                if (userActivo && rowData) {
                                    if (userActivo.id === rowData.usuarioid) {
                                        options = [
                                            { label: 'Enviar', value: 'Enviada' },
                                            { label: 'Eliminar', value: 'Eliminada' }
                                        ]
                                        selected = 'Enviada'
                                    }
                                    if (selected) {
                                        setOptions({
                                            id: rowData.id,
                                            current: rowData.estado,
                                            options: options,
                                            selected: selected
                                        })
                                        setViewCambiarEstado(true)
                                    } else {
                                        showMensaje(() => { }, `Solamente el usuario que crea la Órden [${rowData.usuario.apellido}, ${rowData.usuario.nombres}]
                                    puede cambiar el estado pendiente.`)
                                    }
                                }
                                break;

                            case "Enviada":
                                if (userActivo && rowData) {
                                    if (userActivo.id === rowData.usuarioid) {
                                        options = [
                                            { label: 'Pendiente', value: 'Pendiente' },
                                            { label: 'Eliminar', value: 'Eliminada' }
                                        ]
                                        if (userActivo.id === rowData.usuariodestino.id) {
                                            options.push({ label: 'Aceptar', value: 'Aceptada' })
                                        }
                                        selected = 'Pendiente'
                                    } else {
                                        if (userActivo.id === rowData.usuariodestino.id) {
                                            options = [
                                                { label: 'Aceptar', value: 'Aceptada' },
                                                { label: 'Rechazar', value: 'Rechazada' }
                                            ]
                                            selected = 'Aceptada'
                                        } else {
                                            showMensaje(() => { }, `Solamente el usuario [${rowData.usuariodestino.apellido}, ${rowData.usuariodestino.nombres}]
                                        puede Aceptar o Rechazar Órden.`)
                                        }
                                    }
                                    if (selected) {
                                        setOptions({
                                            id: rowData.id,
                                            current: rowData.estado,
                                            options: options,
                                            selected: selected
                                        })
                                        setViewCambiarEstado(true)
                                    }
                                }
                                break;

                            case "Aceptada":
                                if (userActivo && rowData) {
                                    if (userActivo.id === rowData.usuariodestino.id) {
                                        options = [
                                            { label: 'Finalizar', value: 'Finalizada' },
                                            { label: 'Cancelar', value: 'Cancelada' }
                                        ]
                                        selected = 'Finalizada'
                                    }
                                    if (selected) {
                                        setOptions({
                                            id: rowData.id,
                                            current: rowData.estado,
                                            options: options,
                                            selected: selected
                                        })
                                        setViewCambiarEstado(true)
                                    } else {
                                        showMensaje(() => { }, `Solamente el usuario responsable [${rowData.usuariodestino.apellido}, ${rowData.usuariodestino.nombres}]
                                    puede cambiar el estado Aceptada.`)
                                    }
                                }
                                break;

                            case "Cancelada":
                                if (userActivo && rowData) {
                                    if (userActivo.id === rowData.usuarioid) {
                                        options = [
                                            { label: 'Pendiente', value: 'Pendiente' },
                                            { label: 'Enviar', value: 'Enviada' },
                                            { label: 'Eliminar', value: 'Eliminada' }
                                        ]
                                        selected = 'Pendiente'
                                    }
                                    if (selected) {
                                        setOptions({
                                            id: rowData.id,
                                            current: rowData.estado,
                                            options: options,
                                            selected: selected
                                        })
                                        setViewCambiarEstado(true)
                                    } else {
                                        showMensaje(() => { }, `Solamente el usuario que crea la Órden [${rowData.usuario.apellido}, ${rowData.usuario.nombres}]
                                        puede cambiar el estado cancelada.`)
                                    }
                                }
                                break;


                            case "Rechazada":
                                if (userActivo && rowData) {
                                    if (userActivo.id === rowData.usuarioid) {
                                        options = [
                                            { label: 'Pendiente', value: 'Pendiente' },
                                            { label: 'Eliminar', value: 'Eliminada' }
                                        ]
                                        selected = 'Pendiente'
                                    }
                                    if (selected) {
                                        setOptions({
                                            id: rowData.id,
                                            current: rowData.estado,
                                            options: options,
                                            selected: selected
                                        })
                                        setViewCambiarEstado(true)
                                    } else {
                                        showMensaje(() => { }, `Solamente el usuario que crea la Órden [${rowData.usuario.apellido}, ${rowData.usuario.nombres}]
                                        puede cambiar el estado rechazada.`)
                                    }
                                }


                                break;
                            case "Finalizada":
                                break;

                            default:
                                break;
                        }

                    }}
                />

            </>

        )
    }


    const cambiarEstado = (value: any) => {

        switch (value) {
            case 'Pendiente':
            case 'Enviada':
            case 'Aceptada':
            case 'Rechazada':
            case 'Finalizada':
            case 'Cancelada':

                //alert(value)
                service.put({ url: urlOrdenDeTrabajoEstado, id: options?.id, creacion: { estado: value } }).then(data => {
                    if (data.success) {
                        setRefresh(true)
                        // let row = rows?.filter((x) => x.id === options?.id)
                        // console.log("modificado", row)
                        // if (row) {
                        //     row[0]['estado'] = value;
                        // }
                    }
                })
                break;
            case 'Eliminada':
                confirmDelete({ id: options?.id }, () => acceptDelete({ id: options?.id }))
                break;

            default:
                break;
        }
        setViewCambiarEstado(false)
    }

    return (
        <Autorizado resource='Órden de trabajo' autorizado={
            <>
                {!editOrden ?
                    <div className="grid">
                        <div className="col-12">
                            <div className="card" style={{ minHeight: '86vh' }}>

                                <h5>Órdenes de trabajo</h5>
                                <Toolbar left={toolbarLeftTemplate}></Toolbar>
                                <Toast ref={toast} />

                                <br />
                                <DataTable
                                    value={rows}
                                    resizableColumns
                                    columnResizeMode="fit"
                                    paginator={true}
                                    className="p-datatable-sm"
                                    rows={10}
                                    paginatorRight
                                    paginatorTemplate={template.dataTableTemplate}
                                    rowHover
                                    sortMode={'multiple'}
                                    stripedRows
                                    loadingIcon={'pi pi-spinner'}
                                    globalFilter={globalFilter}
                                    emptyMessage="No hay registros."
                                    loading={loading}
                                    header={TableHeader}
                                >
                                    {columns!.map(col =>
                                        !col.hide ?
                                            <Column className={col.align ? 'p-text-' + col.align : 'p-text-left'} field={col.showField ? col.showField : !col.fieldcamelCase ? col.field : col.fieldcamelCase} key={col.id} header={col.header} filterType={col.type}
                                                sortable={col.sortable} body={bodyTemplate}>
                                            </Column> : null)}
                                    {props.customAttibutes?.deleteDisable && props.customAttibutes?.editDisable ? null : <Column body={actionBodyTemplate} className="p-text-center" header="Acciones"></Column>}
                                </DataTable>
                                <MostrarErrores errores={errores} />
                            </div>
                        </div>
                        {viewCambiarEstado && <Opciones id={options?.id} selected={options?.selected} title={options?.title ? options?.title : 'Cambiar estado de la Órden de Trabajo'} message={`Estado actual [${options?.current}]\nSeleccione el nuevo estado de la Órden de Trabajo.`} options={options?.options} accept={(value) => cambiarEstado(value)} cancel={() => setViewCambiarEstado(false)} />}

                        {exportToPDF && <ViewDocumentPDF values={exportToPDF} viewWeb={true} onClose={() => setExportToPDF(undefined)} />}

                    </div>
                    : <OrdenDeTrabajo
                        onSubmit={() => {
                            setEditOrden(false);
                            setRefresh(true);
                            toast.current!.show({ severity: 'success', summary: "Órden de trabajo", detail: 'Registro creado', life: 2000 });
                        }}
                        value={editOrden}
                        cancel={() => setEditOrden(false)} />
                }
            </>
        } />
    )

}