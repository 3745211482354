import React from 'react'
import './styles.css'
import { formatearFechaDMY } from 'utils/formDataUtils'
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import * as func from 'utils/formDataUtils'
import { Divider } from 'primereact/divider';

const DocumentWeb = ({ props }) => {

  console.log(props)
  const orden = props.values;

  let condiciones = [{}];
  let lotes = [{}];
  let insumos = [{}];
  let insumosAgrupados = [];
  

  if (props.values.condiciones) {
    condiciones = JSON.parse(props.values.condiciones);
    //console.log(condiciones)
  }
  if (props.values.lotes) {
    lotes = props.values.lotes
    //console.log(lotes)
  }
  if (props.values.insumos) {
    
    insumos = props.values.insumos.sort((a,b)=>a.orden-b.orden)
    
    insumos.forEach((element) => {
        let row = insumosAgrupados.filter((x)=>x.id === element.insumo.id)
        if (row[0]) {
          row[0]['total'] = row[0]['total'] + element.total
          row[0]['depositos'].push({nombre: element.deposito.nombre, cantidad: element.total})
        } else {
          insumosAgrupados.push({
            id: element.insumo.id,
            nombre: element.insumo.nombre,
            dosis: element.dosis,
            presentacion: element.insumo.presentacion,
            total: element.total,
            unidad: element.insumo.unidad.nombre,
            depositos: [{nombre: element.deposito.nombre, cantidad: element.total}],
          })
      }
    });
    console.log("Agrupados", insumosAgrupados)
    
    // insumosAgrupados = insumos.map((element, index,insumosAgrupados ) => {
          
    //   return (
    //         {
    //           nombre: element.insumo.nombre,
    //           presentacion: element.insumo.presentacion,
    //           dosis: element.dosis,
    //           total: sumaDosis,
    //           unidad: element.insumo.unidad.nombre,
    //           deposito: element.deposito.nombre,
    //         }
    //       )
    // })
  }

  // const _exportPdf = () => {
  //   var doc = new jsPDF('portrait', 'pt', 'a4');
  //   doc.canvas(document.querySelector("#capture"), {
  //     callback: function (pdf) {
  //       pdf.save("document.pdf")
  //     }
  //   })

  // }
  function makePDF() {
    html2canvas(document.querySelector("#capture"), {
      useCORS: true,
      scale: 1,
    }).then(canvas => {
      //document.body.appendChild(canvas)
      var img = canvas.toDataURL("image/jpeg");
      var doc = new jsPDF('portrait', 'pt', 'a4');
      doc.addImage(img, 'JPEG', 30, 30);
      doc.save(`orden ${props.values.id}.pdf`);
      ;
    })
  }
  function print(){
    window.print();
  }

  const toolbarLeftTemplate = ()=>{
    return (
      <>
         <Button label={'Descargar'} icon="pi pi-file-pdf" iconPos="right"  className="p-button-help" onClick={makePDF} />
         {/* <Button label={'Imprimir'} icon="pi pi-print" iconPos="right"  className="p-button-secundary" onClick={print} /> */}
      </>
      )
  }


  // useEffect(() => {
  //   if (loading){
  //     setLoading(false)
  //     makePDF();
  //   }
    
  //   //_exportPdf();
  // })


  //console.log(orden.observaciones)

  // style={{ color: "#000000", fontSize: "14px", backgroundColor: "#9fc5e8", padding: "2px" }}
  return (
    <>
    <div>
      <Toolbar left={toolbarLeftTemplate}></Toolbar>
    </div>
    <div id="capture">
      <div className="grid-container" >
        <div className="grid-item i1-4 left resaltar"><h5>ORDEN DE TRABAJO <i className="pi pi-hashtag"></i> {orden.id}</h5></div>
        <div className="grid-item resaltar left item5-fin">
          <div>CAMPAÑA: {orden.campaña.nombre}</div>
          <br />
          <div> <i className="pi pi-calendar"></i> {formatearFechaDMY(orden.fecha)}</div>
        </div>
        <div className="enblanco"></div>
        <div className="grid-item i1-2 left">EMPRESA</div>
        <div className="grid-item item3-fin left bold">{orden.empresa.razonsocial}</div>
        <div className="grid-item i1-2 left">ESTABLECIMIENTO</div>
        <div className="grid-item item3-fin left">{orden.establecimiento.nombre}</div>
        <div className="grid-item i1-2 left">SOLICITA</div>
        <div className="grid-item item3-fin left">{orden.usuario.nombres}</div>
        <div className="grid-item i1-2 left">ENCARGADO</div>
        <div className="grid-item item3-fin left">{orden.usuariodestino.nombres}</div>
        <div className="enblanco"></div>
        <div className="line"></div>
        <div className="enblanco"></div>
        <div className="grid-item i1-2 left">ESPECIE</div>
        <div className="grid-item item3-fin left bold">{orden.especie.nombre}</div>
        <div className="grid-item i1-2 left">LABOR</div>
        <div className="grid-item item3-fin left bold">{orden.labor.nombre}</div>
        <div className="grid-item i1-2 left"></div>
        <div className="grid-item item3-fin left">{formatearFechaDMY(orden.fechaaplicaciondesde)} - {formatearFechaDMY(orden.fechaaplicacionhasta)}</div>
        <div className="grid-item i1-2 left">CONTRATISTA</div>
        <div className="grid-item item3-fin left bold">{orden.contacto.razonsocial}</div>
        <div className="grid-item i1-2 left"></div>
        <div className="grid-item item3-fin left">{orden.contacto.telefonos}</div>
        <div className="enblanco"></div>
        <div className="grid-item i1-2 left">CONDICIONES DE LA LABOR</div>
        <div className="grid-item item3-6 left bold">
          {condiciones.length !== 0 ?
            <div className="grid-container">
              {condiciones.map((element, index) => {
                return (
                  <>
                    <div className="grid-item i1-4 left normal">{element.key}</div>
                    <div className="grid-item item5-fin left bold">{element.value}</div>
                    {index < condiciones.length - 1 && <div className="line"></div>}
                  </>)
              })}
            </div> : "Sin establecer"}
        </div>

        <div className="enblanco"></div>
        <div className="line"></div>
        <div className="enblanco"></div>


        <div className="grid-item i1-2 left">LOTES <i className="pi pi-caret-right" ></i> {orden.hectareas} Has</div>
        <div className="grid-item i3-fin left"></div>
        <div class="grid-item icompleto">
          {lotes.length !== 0 ?
            <div className="grid-container-auto">
              <div className="grid-item left bold">Nombre</div>
              <div className="grid-item left bold">Ha</div>
              <div className="grid-item left bold">Condición</div>
              <div className="grid-item left bold">Estadío</div>
              <div className="line-auto-col-4"></div>
              {lotes.map((element, index) => {
                return (
                  <>
                    <div className="grid-item left normal">{element.lote.nombre}</div>
                    <div className="grid-item right bold">{element.hectareasatrabajar}</div>
                    <div className="grid-item left normal">{element.condicion}</div>
                    <div className="grid-item left normal">{element.estadio && element.estadio.nombre}</div>

                  </>)
              })}
            </div> : ""}
        </div>

        <div className="grid-item i1-2 left">INSUMOS</div>
        <div className="grid-item i3-fin left"></div>
        <div class="grid-item icompleto">
          {insumos.length !== 0 ?
            <div className="grid-container-auto-col-5">
              <div className="grid-item left bold">Insumo</div>
              <div className="grid-item left bold">Dosis</div>
              <div className="grid-item left bold">Total</div>
              <div className="grid-item left bold">Unidad</div>
              <div className="grid-item left bold">Depósito</div>
              <div className="line-auto-col-5"></div>

              {
               
               insumosAgrupados.map((element) => {
                  return (
                  <>
                    <div className="grid-item left normal">{element.nombre}
                    {element.presentacion ? 
                      <small style={{fontSize: '10px'}}><br/>{element.presentacion}</small>:null
                    }
                    {/* <small> {element.insumo.presentacion ? element.insumo.presentacion : null}</small> */}
                    </div>
                    <div className="grid-item right bold">{func.showNumber(Number(element.dosis))}</div>
                    <div className="grid-item right normal">{func.showNumber(Number(element.total))}</div>
                    <div className="grid-item left normal">{element.unidad}</div>
                    
                    <div className="grid-item left normal">
                      {element.depositos.map((dep) => {
                        return (
                          <>
                            <div style={{fontSize: '10px'}}>
                            {dep.nombre + ' ' + func.showNumber(Number(dep.cantidad)) }
                            <Divider style={{margin:'0rem', padding:'0.3rem'}} type='dashed' /> 
                            </div>
                          </>
                        )
                      })}
                    </div>

                  </>)
              })}

            </div> : ""}
        </div>

        <div className="enblanco"></div>
        <div className="line"></div>
        <div className="enblanco"></div>

        <div className="grid-item i1-2 left">OBSERVACIONES</div>
        <div className="grid-item i3-fin left"></div>
        <div class="grid-item icompleto left" dangerouslySetInnerHTML={{ __html: orden.observaciones }}></div>
        {/* dangerouslySetInnerHTML={{ __html: orden.observaciones }} */}
      </div>
      </div>
    </>

  );
};


export default DocumentWeb;
