import { CrudEspecies, CrudEmpresas, CrudInsumoAplicacion } from 'app/general/Entidades'
import InsumoAplicaciones from 'app/general/InsumoAplicaciones'
import EntitySetup from 'app/general/EntitySetup'
import LoadRecords from 'app/general/EntitySetupLoadRecords'
import Permisos from 'app/general/Permisos'
import { ITableColumn, TComponentName, TEntityName, IDefaultColumn } from 'utils/interfaces'
import SolicitarValores from 'app/general/SolicitarValores'

export default function Modal(props: modalProps) {

    //formularios emergentes para agregar registros de determinadas tablas
    //miestra se seleccionan valores desde otros formulario de alta.

    if (props.entidad) {
        switch (props.entidad) {
            case 'Especies':
                return (
                    <CrudEspecies modalMode close={props.close} save={props.save} />
                )
            case 'Empresas':
                return (
                    <CrudEmpresas modalMode close={props.close} save={props.save} />
                )
            default:
                return null;
        }
    }

    //lanza desde un evento la apertura del formulario EntitySetup

    if (props.component) {
        //console.log("modal default", props.component)
        switch (props.component) {
            case 'entitysetup':
                return (
                    <EntitySetup id={props.id} close={() => { props.close!(); }} save={props.save} />
                )

            case 'loadrecords':
                return (
                    <LoadRecords id={props.id} close={() => { props.close!(); }} />
                )
            case 'insumoaplicaciones':
                console.log('insumoaplicaciones', props.componentadjuntid)
                return (
                    <CrudInsumoAplicacion modalMode id={props.id} defaults={props.defaults} componentadjunt={props.componentadjunt} componentadjuntid={props.componentadjuntid} close={() => { props.close!(); }} />
                )
            case 'aplicacionesdelinsumo':
                return (
                    <InsumoAplicaciones id={props.id} />
                )
            case 'especieslote':
                return (
                    <SolicitarValores columns={props.columns!} values={props.values} close={props.close} save={props.save} />
                )
            case 'permisos':
                return (
                    <Permisos id={props.id} close={() => { props.close!(); }} save={props.save} />
                )

            default:
                return null;
        }
    }

    return null
}

interface modalProps {
    entidad?: TEntityName;
    component?: TComponentName
    id?: any;
    defaults?: IDefaultColumn[];
    columns?: ITableColumn[];
    componentadjunt?: TComponentName,
    componentadjuntid?: number,
    values?: any,
    close?: (mensaje?: string) => void;
    save?: (row: any) => void;
}

