import { useState } from 'react'
import { laborDTO, laborCreacionDTO } from 'app/dto/labores.model'
import { contactoDTO, contactoCreacionDTO } from 'app/dto/contactos.model'
import { perfilusuarioDTO, perfilusuarioCreacionDTO } from 'app/dto/usuarios.model'
import { ITableColumn, ICustomAttributes, IDefaultColumn, TComponentName, IButton } from 'utils/interfaces'
import { urlLabores, urlUnidadesActivos, urlContactos, urlUsuarios, urlUsuarioFromEMail, urlCuentas } from 'app/service/Endpoints'
import RD from './RD' //read and delete
import CU from './CU' //create and update
import axios from "axios";
import Swal from "sweetalert2";
import { confirmar } from 'utils/Confirmar'
import { solicitarOpciones } from 'utils/Opciones'
import { traducirRole } from 'utils/formDataUtils'

export function CrudLabores(props: crudInterface) {
    const empty: laborCreacionDTO = {
        nombre: '',
        unidadid: 0,
        precio: 0,
        condiciones: '',
    }
    const columnas: ITableColumn[] = [
        { id: 0, field: 'id', hide: true, isKey: true, disabled: true, header: 'Id', sortable: true, align: 'right' },
        { id: 1, field: 'nombre', required: true, colInput: 0, autoFocus: true, header: 'Labor', sortable: true },
        { id: 2, field: 'unidadid', required: true, colInput: 1, showField: 'unidad.nombre', type: 'dropdown', optionsFromEndPoint: urlUnidadesActivos, optionName: 'nombre', optionValue: 'id', header: 'Unidad', sortable: true, optionFilter: true },
        { id: 3, field: 'precio', type: 'number', colInput: 2, header: 'Precio', align: 'right', showMask: '#.##0,00', sortable: true },
        { id: 4, field: 'condiciones', hide: true, colInput: 3, type: 'keyvalue', header: 'Condiciones', sortable: false, printable: false },
    ]

    const custom: ICustomAttributes = {
        newLegend: "Nueva labor",
        updateLegend: "Editar labor",
        pluralEntity: "Labores",
    }

    return (
        props.modalMode
            ?
            <CU<laborDTO, laborCreacionDTO> dataKey="id" updateMode={props.updateMode} url={urlLabores} columns={columnas} nombreEntidad="Labores" id={props.id} close={props.close} emptyRow={empty} customAttibutes={custom} />
            :
            <RD<laborDTO, laborCreacionDTO> dataKey="id" url={urlLabores} columns={columnas} nombreEntidad="Labores" emptyRow={empty} customAttibutes={custom} />

    )
}


export function CrudContactos(props: crudInterface) {
    const empty: contactoCreacionDTO = {
        razonsocial: '',
        categoriacontribuyente: '',
        cuit: '',
        domicilio: '',
        provincia: '',
        departamento: '',
        localidad: '',
        telefonos: '',
        email: '',
        contacto: '',
        observaciones: '',
    }

    const categoriasIVA = [
        { label: 'Responsable Inscripto', value: 'Responsable Inscripto' },
        { label: 'Responsable Monotributo', value: 'Responsable Monotributo' },
        { label: 'Exento', value: 'Exento' },
    ];
    const columnas: ITableColumn[] = [
        { id: 0, field: 'id', isKey: true, hide: true, disabled: true, header: 'Id', sortable: true, align: 'right' },
        { id: 1, field: 'razonsocial', required: true, colInput: 0, autoFocus: true, header: 'Razón Social', sortable: true },
        { id: 2, field: 'categoriacontribuyente', hide: true, required: true, colInput: 1, type: 'dropdown', options: categoriasIVA, showOnFocus: true, header: 'Categoría', sortable: true, printable: false },
        { id: 3, field: 'cuit', hide: true, required: false, colInput: 2, isCUIT: true, type: 'mask', mask: '99-99999999-9', header: 'CUIT', sortable: true },
        { id: 4, field: 'domicilio', hide: true, colInput: 3, autoFocus: false, header: 'Domicilio', sortable: false, printable: false },
        { id: 5, field: 'provincia', hide: true, hideMap: true, colInput: 4, header: 'Provincia', type: 'location', printable: false },
        { id: 6, field: 'departamento', hide: true, colInput: 5, header: 'Departamento', type: 'location', printable: false },
        { id: 7, field: 'localidad', hide: true, colInput: 6, header: 'Localidad', type: 'location', printable: false },
        { id: 8, field: 'telefonos', colInput: 7, autoFocus: false, header: 'Teléfonos' },
        { id: 9, field: 'email', colInput: 8, autoFocus: false, header: 'eMail' },
        { id: 10, field: 'contacto', colInput: 9, autoFocus: false, header: 'Contacto' },
        { id: 11, field: 'observaciones', hide: true, colInput: 10, type: 'textarea', header: 'Observaciones', sortable: false, printable: false },
        { id: 12, field: 'activo', header: 'Activo', disabled: true, colInput: 11, type: 'switch', sortable: true, printable: false },
    ]

    const custom: ICustomAttributes = {
        newLegend: "Nuevo contacto",
        updateLegend: "Editar contacto",
        pluralEntity: "Contactos",
    }

    return (
        props.modalMode
            ?
            <CU<contactoDTO, contactoCreacionDTO> dataKey="id" updateMode={props.updateMode} url={urlContactos} columns={columnas} nombreEntidad="Contactos" id={props.id} close={props.close} emptyRow={empty} customAttibutes={custom} />
            :
            <RD<contactoDTO, contactoCreacionDTO> dataKey="id" url={urlContactos} columns={columnas} nombreEntidad="Contactos" emptyRow={empty} customAttibutes={custom} />

    )
}


export function CrudUsuarios(props: crudInterface) {

    const [refreshUser, setRefreshUser] = useState(false);

    const empty: perfilusuarioCreacionDTO = {
        aspnetuserid: '',
        email: '',
        nombres: '',
        apellido: '',
        domicilio: '',
        provincia: '',
        departamento: '',
        localidad: '',
        telefonos: '',
        activo: true,
    }

    const columnas: ITableColumn[] = [
        { id: 0, field: 'id', isKey: true, hide: true, disabled: true, header: 'Id', sortable: true, printable: false },
        { id: 1, field: 'aspnetuserid', hide: true, header: 'UserId', disabled: true, },
        { id: 2, field: 'email', header: 'Usuario', disabled: false, colInput: 1, type: 'string', disabledEdit: true },
        { id: 3, field: 'nombres', required: true, autoFocus: true, colInput: 2, header: 'Nombres', sortable: true },
        { id: 4, field: 'apellido', required: true, colInput: 3, header: 'Apellido', sortable: true },
        { id: 5, field: 'domicilio', required: true, hide: true, autoFocus: false, colInput: 3, header: 'Domicilio', sortable: false, printable: false },
        { id: 6, field: 'provincia', hide: true, hideMap: false, header: 'Provincia', type: 'location', printable: false },
        { id: 7, field: 'departamento', hide: true, header: 'Departamento', type: 'location', printable: false },
        { id: 8, field: 'localidad', hide: true, header: 'Localidad', type: 'location', printable: false },
        { id: 9, field: 'telefonos', header: 'Teléfonos', printable: false },
    ]

    function cambiar(row: any) {

        setRefreshUser(false)
        let isAdmin = false;
        let claims: any[] = [];
        let valor: any;

        claims = [...row.claims];
        if (claims) {
            claims.forEach(element => {
                if (element.type === 'role') {
                    if (element.value === 'admin') {
                        isAdmin = true;
                    }
                }
            });
        }

        valor = "admin"

        solicitarOpciones(
            () => {
                if (valor) {
                    confirmar(() => { establecerRol(row.aspnetuserid, valor) }, "Confirma asignar el rol " + traducirRole(valor), "Asignar", false, 'question')
                }
            },
            () => null,
            (value) => valor = value,
            [{ label: 'Usuario básico', value: 'user' }, { label: 'Administrador', value: 'admin' }, { label: 'Propietario', value: 'owner' }, { label: 'Completo', value: 'god' }],
            "Seleccione el rol deseado", valor

        )

        // if (isAdmin) {
        //     confirmar(() => { removerAdmin(row.aspnetuserid); setRefreshUser(true) }, "Confirma quitar el rol de administrador?", "Quitar", true, 'question')

        // } else {
        //     confirmar(() => { hacerAdmin(row.aspnetuserid); setRefreshUser(true) }, "Confirma asignar el rol de administrador?", "Asignar", false, 'question')
        // }

    }

    async function establecerRol(id: string, role: string) {
        if (role !== '' && role !== undefined) {
            await editarRole(`${urlCuentas}/establecerRol`, id, role);
        }
    }
    async function editarRole(url: string, usuarioId: string, role: string) {
        //console.log(JSON.stringify({ id, role }))
        const paramQuery = {
            usuarioId: usuarioId,
            role: role,
        }
        //console.log(paramQuery)
        await axios.post(url, paramQuery)
        Swal.fire({
            title: 'Exito',
            text: 'Operación realizada con éxito',
            icon: 'success'
        })
    }


    const addRecords: IButton = {
        icon: "pi pi-shield",
        className: "p-button-rounded p-button-susses p-button-circle",
        style: { height: 25, width: 25, marginRight: '.5em' },
        onClick: cambiar,
    }

    const setup: IButton = {
        icon: "pi pi-cog",
        className: "p-button-rounded p-button-help p-button-circle",
        style: { height: 25, width: 25, marginRight: '.5em' },
        loadComponent: 'permisos',
    }


    const custom: ICustomAttributes = {
        newLegend: "Perfil del usuario",
        updateLegend: "Perfil del usuario",
        pluralEntity: "Usuarios",
        editDisable: true,
        deleteDisable: true,
        addDisable: true,
        exportPDFDisable: true,
        buttons: [addRecords, setup],
    }

    return (
        props.modalMode
            ?
            <CU<perfilusuarioDTO, perfilusuarioCreacionDTO> dataKey="id" updateMode={props.updateMode} url={urlUsuarioFromEMail} columns={columnas} nombreEntidad="Usuario" id={props.id} params={props.param} save={props.save} close={props.close} emptyRow={empty} customAttibutes={custom} />
            : <RD<perfilusuarioDTO, perfilusuarioCreacionDTO> dataKey="id" url={urlUsuarios} columns={columnas} nombreEntidad="Usuarios" role='owner' emptyRow={empty} refreshComponent={refreshUser} customAttibutes={custom} />
    )
}



interface crudInterface {
    modalMode?: boolean;
    updateMode?: boolean;
    defaults?: IDefaultColumn[];
    componentadjunt?: TComponentName,
    componentadjuntid?: number,
    close?: (mensaje?: string) => void;
    save?: (row: any) => void;
    id?: any;
    param?: any;
}