import { useState, useRef, useEffect, useContext } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { ITableColumn, ICargaInsumo, ILote, TRole } from 'utils/interfaces';
import { urlCampañasActivos, urlCampañasEmpresas, urlCampañasLotes, urlLotesEspecies, urlLabores, urlContactosActivos, urlInsumosActivos, urlDepositosEmpresa, urlInsumoEspecieAplicacion, urlOrdenDeTrabajo, urlUsuarios } from 'app/service/Endpoints'
import AGDropdown from 'app/components/AGDropdown'
import 'primeflex/primeflex.css';
import { ordendetrabajoCreacionDTO, ordendetrabajoEnvioCreacionDTO } from 'app/dto/ordendetrabajo.model'
import AsociarEstablecimiento from 'app/general/AsociarEstablecimiento'
import OrdenDeTrabajoLotes from './OrdenDeTrabajoLotes';
import OrdenDeTrabajoInsumos from './OrdenDeTrabajoInsumos';
import KeysValues from 'app/components/KeysValues'
import { InputNumber } from 'primereact/inputnumber';
import showMensaje from 'utils/Mensaje'
import classNames from 'classnames';
import ReactQuill from "react-quill"
import { Toolbar } from 'primereact/toolbar';
import { GenericService } from '../service/GenericService';
import { Toast } from 'primereact/toast';
import * as func from 'utils/formDataUtils'
import { confirmClear } from 'utils/Confirmar';
import DetalleInsumo from 'app/stock/DetalleInsumo'
import { Divider } from 'primereact/divider';
import './Estado.css';
import EntitiesContext from 'context/EntitiesContext'
import Autorizado from 'auth/Autorizado';
import * as userControl from 'auth/UserDataControl';
import NoAutorizado from 'NoAutorizado';

export default function OrdenDeTrabajo(props: ordenDeTrabajoProps) {

    const toast = useRef<Toast>(null);

    const service = new GenericService();
    const entitiesContext = useContext(EntitiesContext);

    let dfecha = new Date(Date.now());
    let hfecha = new Date(Date.now());
    hfecha.setDate(hfecha.getDate() + 7)

    const emptyRow: ordendetrabajoCreacionDTO = {
        campaña: undefined,
        empresa: undefined,
        empresalastquery: undefined,
        establecimiento: undefined,
        usuariodestino: undefined,
        especie: undefined,
        labor: undefined,
        contratista: undefined,
        fechadesde: dfecha,
        fechahasta: hfecha,
        condiciones: undefined,
        costo: undefined,
        cotizacion: undefined,
        insumoseleccionado: undefined,
        insumoseleccionadoaplicacion: undefined,
        dosis: undefined,
        cantidad: undefined,
        hectareas: 0,
        deposito: undefined,
        observacion: undefined,
        lotes: undefined,
        insumos: undefined,
        ayudas: { dosis: '', cantidad: '' },
    }

    const paramLot = {
        campañaid: undefined,
        empresaid: undefined,
    }

    const paramEsp = {
        campañaid: undefined,
        empresaid: undefined,
        establecimientoid: undefined,
    }

    const [title, setTitle] = useState('Nueva órden de trabajo')
    const [loading, setLoading] = useState(true);
    const [activeIndex, setActiveIndex] = useState(0);
    const [record, setRecord] = useState<ordendetrabajoCreacionDTO>(emptyRow);
    const [asociarestablecimiento, setAsociarEstablecimiento] = useState(false);
    const [disableLotes, setDisableLotes] = useState(true)
    const [disableLabor, setDisableLabor] = useState(true)
    const [disableInsumos, setDisableInsumos] = useState(true)
    const [observacion, setObservacion] = useState()
    const [users, setUsers] = useState<any[]>()


    const [disableSave, setDisableSave] = useState(true)


    const [addsubmitted, setAddSubmitted] = useState(false)

    const [paramEspecie, setParamEspecie] = useState(paramEsp)
    const [paramLotes, setParamLotes] = useState(paramLot)

    const [loadedEstablecimiento, setLoadedEstablecimiento] = useState(false)


    let toolbarOptions = {
        toolbar: [
            [{ 'font': [] }],
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block'],
            // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            // [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
            [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
            // [{ 'direction': 'rtl' }],                         // text direction
            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            [{ 'align': [] }],
            ['clean']                                         // remove formatting button
        ]
    }



    // const [content, setContent] = useState("")
    // const [files, setFiles] = useState([])

    // const onEditorChange = (value: any) => {
    //     setContent(value)
    //     console.log(content)
    // }

    // const onFilesChange = (files: any) => {
    //     setFiles(files)
    // }
    useEffect(() => {
        if (loading && props.value) {
            setLoading(false)
            //console.log(props.value)
            setTitle("Editando Órden Nº " + props.value.id)
            record.campaña = props.value.campaña.id
            paramLot.campañaid = props.value.campaña.id
            paramLot.empresaid = props.value.empresa.id
            //console.log("parámetros para determinar establecimientos", paramLot)
            setParamLotes(paramLot)
        }
    })

    useEffect(() => {
        //console.log("loadedEstablecimiento loadedEspecie", loadedEstablecimiento, loadedEspecie)
        if (loadedEstablecimiento && props.value && !record.establecimiento) {
            //console.log("actual", record)
            paramEsp.campañaid = props.value.campaña.id
            paramEsp.empresaid = props.value.empresa.id
            paramEsp.establecimientoid = props.value.establecimiento.id
            //console.log("especie", paramEsp)
            setParamEspecie(paramEsp)
            load()
            //changed('establecimiento', props.value.establecimiento, editRow)
        }
    }, [loadedEstablecimiento])

    useEffect(() => {
        let user: any;
        //console.log("users", users)
        if (users && props.value) {
            user = users.filter(x => x.id === props.value.usuariodestino.id)
            if (user) {
                //console.log("user", user[0])
                changed("usuariodestino", user[0])
                //record.usuariodestino = user
            }
        }
    }, [users])


    async function load() {
        let _lotes: ILote[];
        let _insumos: ICargaInsumo[];
        let _hectareas: number = 0;
        // await service.getId({ url: urlEstadios, id: lote.estadioid }).then((response) => response.data)
        if (props.value.lotes) {
            _lotes = props.value.lotes!.map(function (lote: any) {
                _hectareas = _hectareas + lote.hectareasatrabajar;
                return {
                    id: lote.loteid,
                    nombre: lote.lote.nombre,
                    hectareas: lote.lote.hectareas,
                    hectareasatrabajar: lote.hectareasatrabajar,
                    condicion: lote.condicion,
                    estadio: lote.estadio
                }
            })
            //console.log(_lotes)
        }

        _insumos = props.value.insumos

        let editRow: ordendetrabajoCreacionDTO = {
            campaña: props.value.campaña,
            empresa: props.value.empresa,
            usuariodestino: undefined,
            establecimiento: props.value.establecimiento,
            especie: props.value.especie,
            labor: props.value.labor,
            contratista: props.value.contacto,
            fechadesde: props.value.fechaaplicaciondesde,
            fechahasta: props.value.fechaaplicacionhasta,
            condiciones: JSON.parse(props.value.condiciones),
            costo: props.value.costo,
            cotizacion: props.value.cotizacion,
            hectareas: _hectareas!,
            lotes: _lotes!,
            insumos: _insumos!,
            ayudas: { dosis: '', cantidad: '' },
        };

        setObservacion(props.value.observaciones)
        //console.log("load", editRow)
        changed('cambioestablecimiento', editRow.establecimiento, editRow)
        loadAplicacion(editRow)
        setDisableLabor(false)
        setDisableInsumos(false)

    }

    //lee los datos de aplicación para el insumo para validar el ingreso de las dosis
    const loadAplicacion = (editRow: any) => {
        let _insumos: any[] = [...editRow['insumos']]
        _insumos.forEach((element, index) => {
            if ('aplicacion' in element === false) {
                const paramQuery = {
                    insumoid: element.insumoid,
                    especieid: editRow.especie.id,
                }
                service.getId({ url: urlInsumoEspecieAplicacion, params: paramQuery }).then(response => {
                    if (response.success) {
                        var key = 'aplicacion',
                            obj = {
                                [key]: response.data
                            }
                        editRow['insumos'][index] = { ...editRow['insumos'][index], ...obj }
                        setRecord(editRow)
                        //console.log("editRow", editRow)
                    } else {

                    }
                }).catch(error => console.log());
            }
        });
    }


    const columnas: ITableColumn[] = [
        { id: 0, field: 'id', isKey: true, hide: true, disabled: true, header: 'Id' },
        { id: 1, field: 'campaña', hide: false, required: true, colInput: 0, autoFocus: true, type: 'dropdown', optionsFromEndPoint: urlCampañasActivos, optionName: 'nombre', header: 'Campaña' },
        { id: 2, field: 'empresa', hide: false, optionsFromEndPoint: urlCampañasEmpresas, optionModeQuery: 'id', optionName: 'razonsocial', header: 'Empresa', disabled: false, colInput: 1, type: 'dropdown', sortable: false },
        { id: 3, field: 'establecimiento', hide: false, required: true, colInput: 2, autoFocus: false, type: 'dropdown', optionsFromEndPoint: urlCampañasLotes, optionModeQuery: 'param', optionsFromEndPointDataFromObjectName: 'establecimientos', optionName: 'nombre', header: 'Establecimiento', sortable: true, optionFilter: true },
        { id: 4, field: 'especie', hide: false, required: true, colInput: 3, autoFocus: false, type: 'dropdown', optionsFromEndPoint: urlLotesEspecies, optionModeQuery: 'param', optionName: 'nombre', header: 'Especie', optionFilter: true },
        { id: 5, field: 'labor', hide: false, required: true, colInput: 4, autoFocus: true, type: 'dropdown', optionsFromEndPoint: urlLabores, optionName: 'nombre', header: 'Labor', sortable: true, optionFilter: false, disabledUpdate: true },
        { id: 6, field: 'contratista', hide: false, required: true, colInput: 5, autoFocus: false, type: 'dropdown', optionsFromEndPoint: urlContactosActivos, optionName: 'razonsocial', header: 'Contratista', optionFilter: true },
        { id: 7, field: 'fechadesde', required: true, colInput: 7, header: 'Desde', type: 'date' },
        { id: 9, field: 'fechahasta', required: true, colInput: 8, header: 'Hasta', type: 'date' },
        { id: 10, field: 'costo', required: false, colInput: 9, header: 'Costo', type: 'number' },
        { id: 11, field: 'cotizacion', required: false, colInput: 10, header: 'Cotización', type: 'number' },
        { id: 12, field: 'condiciones', required: true, colInput: 11, header: 'Condiciones', type: 'keyvalue', sortable: false },
        { id: 13, field: 'insumoseleccionado', required: true, colInput: 12, header: 'Insumo', type: 'dropdown', optionsFromEndPoint: urlInsumosActivos, optionName: 'nombre', optionFilter: true },
        { id: 14, field: 'dosis', required: false, colInput: 13, header: 'Dosis', type: 'number', numberStep: 0.1, showButtons: true },
        { id: 15, field: 'cantidad', required: false, colInput: 14, header: 'Cantidad', type: 'number', numberStep: 1, showButtons: true },
        { id: 16, field: 'deposito', required: true, colInput: 15, header: 'Depósito', type: 'dropdown', optionsFromEndPoint: urlDepositosEmpresa, optionModeQuery: 'id', optionName: 'nombre', },
        { id: 17, field: 'apellidonombres', hide: false, required: false, colInput: 16, autoFocus: false, type: 'dropdown', optionsFromEndPoint: urlUsuarios, optionName: 'apellidoNombres', header: 'Usuario encargado', optionFilter: true },


    ]

    function changed(field: string, value: any, source?: any) {

        //console.log("value", value)
        //console.log("field record", field, record)
        //console.log("field value ", field, value)
        // if (field === 'lotes') {
        //     console.log("lotes", value)
        // }
        let cambioespecie = false;
        let cambioestablecimiento = false;
        let hectareas: number = -1;

        if (value === null) {
            value = undefined
        }

        switch (field) {
            case 'especie':
                if (record.especie !== value) {
                    cambioespecie = true
                }
                break;
            case 'establecimiento':
                if (record.establecimiento !== value) {
                    cambioestablecimiento = true
                }
                break;
            case 'lotes':
                hectareas = getUpdateHectareas(value, false);
                break;

            default:
                break;
        }


        let _record: ordendetrabajoCreacionDTO;
        var key = field,
            obj = {
                [key]: value
            };

        //debugger

        if (source) {
            _record = { ...source, ...obj }
        } else {
            _record = { ...record, ...obj }
        }

        //establece el número de hectareas cuando cambia el lote
        if (field === 'lotes' && hectareas !== -1) {
            _record.hectareas = hectareas;
        }

        //console.log("field new record", field, _record)

        if (_record.campaña && _record.empresa) {
            paramLot.campañaid = _record.campaña.id
            paramLot.empresaid = _record.empresa.id
            //console.log("parámetros para determinar establecimientos", paramLot)
            setParamLotes(paramLot)
            if (_record.establecimiento) {
                paramEsp.campañaid = _record.campaña.id
                paramEsp.empresaid = _record.empresa.id
                paramEsp.establecimientoid = _record.establecimiento.id
                setParamEspecie(paramEsp)
                setDisableLotes(false)
            }
        } else {
            setParamLotes({ campañaid: undefined, empresaid: undefined })
            setDisableLotes(true)
        }

        if (cambioespecie) {
            _record.lotes = undefined;
        }
        if (cambioestablecimiento) {
            _record.especie = undefined;
            _record.lotes = undefined;
            if (!_record.establecimiento)
                setDisableLotes(true)
        }

        if (field === 'insumoseleccionado' || field === 'dosis' || field === 'insumos') {
            _record.ayudas!['cantidad'] = ''
            //console.log("xx", _record.hectareas, _record.dosis)
            if (_record.hectareas && _record.dosis && _record.insumoseleccionado) {

                let dosisutilizada: number = 0
                let pendiente: number = 0
                dosisutilizada = getDosisUtilizada(_record.insumoseleccionado.id, _record)
                //alert(_record.hectareas)
                pendiente = func.formatNumber((Number(_record.hectareas!) * Number(_record.dosis!)) - dosisutilizada, 2)
                _record.ayudas!['cantidad'] = "Pendiente: " + func.showNumber(pendiente) + ' ' + _record.insumoseleccionado.unidad.nombre
                _record.cantidad = pendiente
            }
        }

        setRecord(x => x = _record)
        //console.log("field, valor: ", field, _record)


        if (field === 'labor') {
            //debugger
            //console.log("condiciones", value.condiciones)
            //console.log(value)

            if (value) {
                //console.log(value)
                if (value.condiciones) {
                    //console.log("CU getValue value keyvalue", JSON.parse(value.condiciones))
                    changed('condiciones', JSON.parse(value.condiciones), _record)
                } else {
                    changed('condiciones', undefined, _record)
                }

            } else {
                changed('condiciones', undefined, _record)
            }
            //changed('condiciones', value.condiciones)
        }
        if (field === 'condiciones') {
            if (_record.labor)
                changed('costo', _record.labor.precio, _record)
            else
                changed('costo', undefined, _record)
        }

        if (field === 'insumoseleccionado') {
            _record.ayudas!['dosis'] = ''
            _record.ayudas!['cantidad'] = ''
            _record.cantidad = undefined
            if (_record.insumoseleccionado) {
                const paramQuery = {
                    insumoid: _record.insumoseleccionado.id,
                    especieid: _record.especie.id,
                }
                service.getId({ url: urlInsumoEspecieAplicacion, params: paramQuery }).then(response => {
                    if (response.success) {
                        changed('insumoseleccionadoaplicacion', response.data, _record)
                    } else {
                        _record.insumoseleccionadoaplicacion = undefined
                        //changed('insumoseleccionadoaplicacion', undefined, _record)
                        changed('dosis', undefined, _record)
                    }
                }).catch(error => console.log());
            } else {
                changed('dosis', undefined, _record)
            }
        }
        if (field === 'insumoseleccionadoaplicacion') {

            if (_record.insumoseleccionadoaplicacion) {
                if (_record.insumoseleccionadoaplicacion.dosissugerida > 0) {
                    if (_record.insumoseleccionadoaplicacion.dosisdesde && _record.insumoseleccionadoaplicacion.dosishasta) {
                        _record.ayudas!['dosis'] = "(Mín. " + _record.insumoseleccionadoaplicacion.dosisdesde + " Máx. " + _record.insumoseleccionadoaplicacion.dosishasta + ')'
                        changed('dosis', _record.insumoseleccionadoaplicacion.dosissugerida, _record)
                    }
                }
                else
                    changed('dosis', undefined, _record)
            }
        }




        if (!disableLotes) {
            if (_record.lotes) {
                setDisableLabor(false)
            } else {
                setDisableLabor(true)
            }
        }

        if (!disableLabor) {
            if (_record.labor && _record.contratista) {
                setDisableInsumos(false)
            } else {
                setDisableInsumos(true)
            }
        }

        if (_record.lotes && _record.lotes && _record.contratista && _record.fechadesde && _record.fechahasta && _record.usuariodestino) {
            setDisableSave(false)
        } else {
            setDisableSave(true)
        }



    }

    const property = (fieldName: string) => {
        return columnas.find((x) => x.field === fieldName)
    }

    const toolbarLeftTemplate = () => {
        //console.log("xx", props.value)
        return (
            <>
                {props.value ?
                    <>
                        <Button label={'Cancelar'} className="p-button-danger" icon="pi pi-file" onClick={() => newOrden(true)} style={{ marginRight: '.5em' }} />

                        {(props.value.estado === 'Pendiente' && props.value.usuarioid === entitiesContext.user?.id) || (props.value.estado === 'Enviada' && props.value.usuarioid === entitiesContext.user?.id) ?
                            <Button label="Actualizar" disabled={disableSave} className="p-button-primary" icon="pi pi-save" onClick={() => saveOrden(true)} style={{ marginRight: '.5em' }} tooltipOptions={{ position: 'bottom' }} tooltip={'Actualiza la órden de trabajo.'} /> : null}

                        <Button label="Copiar" disabled={disableSave} className="p-button-help" icon="pi pi-copy
" onClick={() => saveOrden()} style={{ marginRight: '.5em' }} tooltipOptions={{ position: 'bottom' }} tooltip={'Genera una nueva órden de trabajo con los datos actuales.'} />
                    </>
                    : <>
                        <Button label={'Limpiar'} className="p-button-danger" icon="pi pi-file" onClick={() => confirmClear(undefined, () => newOrden(true))} style={{ marginRight: '.5em' }} />

                        <Button label="Guardar" disabled={disableSave} className="p-button-primary" icon="pi pi-save" onClick={() => saveOrden()} style={{ marginRight: '.5em' }} />
                    </>
                }
            </>
        )
    };

    const newOrden = (cancel?: boolean) => {
        if (props.value && cancel) {
            if (props.cancel)
                props.cancel!();
        } else {
            setRecord(emptyRow)
            setParamEspecie(paramEsp)
            setParamLotes(paramLot)
            setDisableLotes(true)
            setDisableLabor(true)
            setDisableInsumos(true)
            setObservacion(undefined)
            setActiveIndex(0)
            setDisableSave(true)
        }
    }

    const transformarLote = (lote: ILote) => {
        return {
            ordendetrabajoid: 0,
            loteid: lote.id,
            estadioid: lote.estadio ? lote.estadio.id : undefined,
            hectareasatrabajar: lote.hectareasatrabajar,
            condicion: lote.condicion,
        }
    }

    const transformarInsumos = (insumo: ICargaInsumo) => {
        return {
            ordendetrabajoid: 0,
            insumoid: insumo.insumo.id,
            depositoid: insumo.deposito.id,
            dosis: insumo.dosis,
            orden: insumo.orden,
            total: insumo.total,
            costo: insumo.costo,
            // total: insumo.dosis! * record.hectareas!,
            // costo: insumo.dosis! * record.hectareas! * insumo.insumo.costo!
        }
    }




    const saveOrden = (update?: boolean) => {
        //let lotes = record.lotes.map((lote) => new Object.create({ xx: lote.id }))
        //debugger
        setDisableSave(true)

        console.log("Guardando")

        setActiveIndex(0)
        let lotes: any[] = record.lotes ? record.lotes!.map(transformarLote) : []
        let insumos: any[] = record.insumos ? record.insumos!.map(transformarInsumos) : []
        //console.log(lotes)
        //debugger
        const newOrdenDeTrabajo: ordendetrabajoEnvioCreacionDTO = {
            usuarioid: entitiesContext.user ? Number(entitiesContext.user.id) : 1,
            campañaid: record.campaña.id,
            empresaid: record.empresa.id,
            usuariodestinoid: record.usuariodestino && record.usuariodestino.id,
            establecimientoid: record.establecimiento.id,
            fecha: new Date(Date.now()),
            estado: 'Pendiente',
            especieid: record.especie.id,
            laborid: record.labor.id,
            contactoid: record.contratista.id,
            fechaaplicaciondesde: record.fechadesde!,
            fechaaplicacionhasta: record.fechahasta!,
            costo: record.costo!,
            cotizacion: record.cotizacion!,
            condiciones: JSON.stringify(record.condiciones),
            observaciones: observacion,
            lotes: lotes,
            insumos: insumos,
        }
        //console.log("newOrdenDeTrabajo", newOrdenDeTrabajo)

        if (update) {
            service.put({ url: urlOrdenDeTrabajo, id: props.value.id, creacion: newOrdenDeTrabajo }).then((response) => {
                console.log("saveOrden update ", response.success, response.data)
                if (props.value!) {
                    if (props.onSubmit) {
                        props.onSubmit!();
                    } else {
                        if (props.cancel) {
                            props.cancel!();
                        }
                    }
                } else {
                    newOrden();
                }

                //console.log(response.error.data);
            })
        } else {
            service.post({ url: urlOrdenDeTrabajo, creacion: newOrdenDeTrabajo }).then((response) => {
                console.log("saveOrden new ", response.success, response.data)
                if (props.value!) {
                    if (props.onSubmit) {
                        props.onSubmit!();
                    } else {
                        if (props.cancel) {
                            props.cancel!();
                        }
                    }
                } else {
                    newOrden();
                }
                //console.log(response.error.data);
            })
        }
    }

    const isValid = (data: ordendetrabajoCreacionDTO) => {
        //console.log("orden de trabajo insumos: insumo aplicación: ", props.insumoseleccionadoaplicacion)
        //let dosisutilizada: number = getDosisUtilizada(data.insumoseleccionado.id)

        if (Number(data.cantidad) < 0) {
            showMensaje(() => { }, `De indicar cantidad, la misma debe ser mayor a 0 (cero)`)
            return false
        }

        if (data.insumoseleccionadoaplicacion) {
            if (data.dosis! >= data.insumoseleccionadoaplicacion.dosisdesde && (data.insumoseleccionadoaplicacion.dosishasta === 0 || data.dosis! <= data.insumoseleccionadoaplicacion.dosishasta)) {
                return true
            } else {
                showMensaje(() => { }, `Debe indicar una dosis entre ${data.insumoseleccionadoaplicacion.dosisdesde} y ${data.insumoseleccionadoaplicacion.dosishasta} por hectarea`)
            }
        } else {
            return true
        }
    }

    const getUpdateHectareas = (lotes: ILote[], updateInsumos?: boolean) => {
        let hectareas: number = 0;
        lotes?.map(l => hectareas = hectareas + l.hectareasatrabajar)
        return hectareas
    }

    const getDosisUtilizada = (insumoid: number, source?: ordendetrabajoCreacionDTO) => {
        let sumaDosis: number = 0;
        let _record = { ...record };
        if (source) {
            _record = { ...source }
        }
        _record.insumos?.forEach(element => {
            if (element.insumo.id === insumoid)
                sumaDosis = sumaDosis + element.total
        });
        return sumaDosis
    }




    const add = () => {
        setAddSubmitted(true)
        //console.log("add", record)
        if (record.insumoseleccionado && record.dosis && record.deposito && isValid(record)) {

            setAddSubmitted(false)

            let _insumos: ICargaInsumo[];
            let exist = false;

            let hectareas = getUpdateHectareas(record.lotes!, true);
            let cantidad: number = Number(record.cantidad);
            let retiro: number = 0


            if (cantidad === 0) {
                retiro = record.dosis * hectareas
            } else {
                retiro = cantidad
            }

            record.insumos?.forEach(element => {
                if (element.insumo.id === record.insumoseleccionado.id && element.deposito.id === record.deposito.id) {
                    showMensaje(() => { }, `El insumo ${record.insumoseleccionado.nombre} ya se encuentra en la lista.`)
                    exist = true;
                }
            });

            if (!exist) {
                // console.log(rounded)

                const newInsumo: ICargaInsumo = {
                    insumo: record.insumoseleccionado,
                    dosis: record.dosis,
                    deposito: record.deposito,
                    costo: Number((Number(record.insumoseleccionado.costo) * record.dosis).toFixed(2)),
                    total: func.formatNumber(retiro, 2),
                    orden: record.insumos ? record.insumos?.length! + 1 : 1,

                    unidad: record.insumoseleccionado.unidad,
                    aplicacion: record.insumoseleccionadoaplicacion,
                }

                if (!record.insumos) {
                    _insumos = [newInsumo]
                } else {
                    _insumos = [...record.insumos, newInsumo]
                }
                //console.log("_insumos", _insumos)
                changed('insumos', _insumos)


            }
        }
    }

    const handleChange = (html: any, delta?: any, source?: any) => {
        //console.log('html', html)
        // https://youtu.be/BbR-QCoKngE
        // https://www.youtube.com/embed/ZwKhufmMxko
        // https://tv.naver.com/v/9176888
        // renderToStaticMarkup(ReactHtmlParser(html, options));

        //console.log("cambia observacion", delta)
        if (source) {
            if (source !== 'api')
                setObservacion(html)
        }
        //record.observacion = html
        //changed('observacion', html)

        // this.setState({
        //     editorHtml: html
        // }, () => {
        //     this.props.onEditorChange(this.state.editorHtml);
        // });
    };

    if (userControl.ProcesoHabilitado({ user: entitiesContext.user, resource: 'Órden de trabajo', process: 'agregar', showMessage: false })) {
        return (
            <>
                <div className="card" style={{ minHeight: '86vh' }}>
                    <h5>{title}</h5>
                    <div >
                        <Toast ref={toast} />
                        <Toolbar left={toolbarLeftTemplate}></Toolbar>

                        <TabView className="tabview-custom" activeIndex={activeIndex} onTabChange={(e) => {

                            switch (e.index) {
                                case 0:
                                    //console.log("lastEmpresa", lastEmpresa)
                                    break;
                                case 1:
                                    //console.log(record.especie)
                                    if (props.value && !record.especie && (record.establecimiento.id === props.value.establecimiento.id)) {
                                        //alert("cosa")
                                        changed('especie', props.value.especie)
                                    }
                                    break;
                                case 2:
                                    // console.log(record.labor)
                                    // if (props.value && !record.labor) {
                                    //     changed('especie', props.value.labor)
                                    // }
                                    break;

                                default:
                                    break;
                            }
                            setActiveIndex(e.index)
                        }
                        } >
                            <TabPanel header=" Datos generales" leftIcon="pi pi-calendar"  >
                                <div className="p-fluid p-formgrid p-grid">
                                    <div className="p-field p-col">
                                        <AGDropdown col={columnas.find((x) => x.field === 'campaña')!} value={record.campaña!} onChanged={(x) => changed('campaña', x)} />
                                    </div>
                                    <div className="p-field p-col">
                                        <AGDropdown col={columnas.find((x) => x.field === 'empresa')!} value={record.empresa!} onChanged={
                                            (x) => changed('empresa', x)} id={record?.campaña?.id} />
                                    </div>
                                    <div className="p-field p-col">
                                        <AGDropdown col={columnas.find((x) => x.field === 'establecimiento')!} value={record.establecimiento} onChanged={(x) => changed('establecimiento', x)} params={paramLotes} onLoaded={() => setLoadedEstablecimiento(true)} />
                                        <Button style={{ fontSize: '10px', padding: '5px', marginTop: '3px', marginBottom: '5px', textAlign: 'left', width: 'auto', height: '20px', background: 'none', border: 'none', color: 'green' }} icon="pi pi-plus
    " onClick={() => setAsociarEstablecimiento(true)} label={'ASOCIAR LOTE/S'}
                                            className="p-button-secondary" />
                                    </div>
                                    <div className="p-field p-col">
                                        <AGDropdown col={columnas.find((x) => x.field === 'apellidonombres')!} value={record.usuariodestino} onChanged={(x) => changed('usuariodestino', x)} onLoaded={(values) => setUsers(values)} />
                                    </div>

                                </div>
                            </TabPanel>
                            <TabPanel header=" Lotes" disabled={disableLotes} leftIcon="pi pi-map-marker">
                                <div className="p-fluid p-formgrid p-grid">
                                    <div className="p-field p-col">
                                        <AGDropdown col={columnas.find((x) => x.field === 'especie')!} params={paramEspecie} value={record.especie} onChanged={(x) => changed('especie', x)} onLoaded={() => null} />
                                    </div>

                                </div>
                                <div>
                                    <OrdenDeTrabajoLotes campaña={record.campaña} empresa={record.empresa} establecimiento={record.establecimiento} values={record.lotes} especie={record.especie} onChanged={(x) => changed('lotes', x)} />
                                </div>

                            </TabPanel>
                            <TabPanel header=" Labor" disabled={disableLabor} leftIcon="pi pi-briefcase">
                                <div className="p-fluid p-formgrid p-grid">

                                    <div className="p-field p-col-12 p-md-6">
                                        <AGDropdown col={columnas.find((x) => x.field === 'labor')!} value={record.labor} onChanged={(x) => changed('labor', x)} />
                                    </div>
                                    <div className="p-field p-col-12 p-md-6">
                                        <AGDropdown col={columnas.find((x) => x.field === 'contratista')!} value={record.contratista} onChanged={(x) => changed('contratista', x)} />
                                    </div>
                                    <div className="p-field p-col-12 p-md-3">
                                        <label htmlFor={columnas.find((x) => x.field === 'fechadesde')?.field}>{columnas.find((x) => x.field === 'fechadesde')?.header}</label>
                                        <Calendar locale="es" style={{ marginTop: "0.3rem" }} showIcon id={columnas.find((x) => x.field === 'fechadesde')?.field} value={new Date(record.fechadesde!)} onChange={(e) => changed('fechadesde', e.value)} dateFormat="dd/mm/yy" required={columnas.find((x) => x.field === 'fechadesde')?.required} />
                                    </div>
                                    <div className="p-field p-col-12 p-md-3">
                                        <label htmlFor={columnas.find((x) => x.field === 'fechahasta')?.field}>{columnas.find((x) => x.field === 'fechahasta')?.header}</label>
                                        <Calendar locale="es" style={{ marginTop: "0.3rem" }} showIcon id={columnas.find((x) => x.field === 'fechahasta')?.field} value={new Date(record.fechahasta!)} onChange={(e) => changed('fechahasta', e.value)} dateFormat="dd/mm/yy" required={columnas.find((x) => x.field === 'fechahasta')?.required} />
                                    </div>
                                    <div className="p-field p-col-12 p-md-3">
                                        <label htmlFor={property('costo')?.field}>{property('costo')?.header} {record.labor?.unidad?.abreviatura!}</label>
                                        <InputNumber locale="es" style={{ marginTop: "0.3rem" }} id={property('costo')?.field} value={record.costo} mode='decimal' maxFractionDigits={3} onValueChange={(e) => changed('costo', e.value)} required={property('costo')?.required} />
                                    </div>
                                    <div className="p-field p-col-12 p-md-3">
                                        <label htmlFor={property('cotizacion')?.field}>{property('cotizacion')?.header}</label>
                                        <InputNumber locale="es" style={{ marginTop: "0.3rem" }} id={property('cotizacion')?.field} value={record.cotizacion} mode="decimal" maxFractionDigits={4} onValueChange={(e) => changed('cotizacion', e.value)} required={property('cotizacion')?.required} />
                                    </div>
                                    <div>
                                        <KeysValues title={columnas.find((x) => x.field === 'condiciones')?.header} value={record.condiciones} rowsChanged={(rows) => {
                                            changed('condiciones', rows)
                                        }} />
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel header=" Insumos" disabled={disableInsumos} leftIcon="pi pi-shopping-cart">
                                <div>
                                    Hectareas a trabajar: {func.showNumber(record.hectareas!)}
                                    <Divider />
                                </div>
                                <div className="p-fluid p-formgrid p-grid">
                                    <div className="p-field p-col-12 p-md-3">
                                        <AGDropdown col={columnas.find((x) => x.field === 'insumoseleccionado')!} value={record.insumoseleccionado} onChanged={(x) => changed('insumoseleccionado', x)} submitted={addsubmitted} />
                                        {record.insumoseleccionado ? <DetalleInsumo insumo={record.insumoseleccionado} empresa={record.empresa} deposito={record.deposito} payload={(value) => null} /> : null}
                                    </div>
                                    <div className="p-field p-col-12 p-md-2">
                                        {/* {record.insumoseleccionado ? record.insumoseleccionado.insumo.unidad.nombre : null} */}
                                        <label htmlFor={property('dosis')?.field}>{property('dosis')?.header} {record.insumoseleccionado?.unidad?.abreviatura!} / ha</label>
                                        <InputNumber locale="es" style={{ marginTop: "0.3rem" }} id={property('dosis')?.field} value={record.dosis!} mode='decimal' showButtons={property('dosis')?.showButtons} inputId="minmax-buttons" step={property('dosis')?.numberStep} maxFractionDigits={3} onValueChange={(e) => changed('dosis', e.value)} required={property('dosis')?.required} className={classNames({ 'p-invalid': addsubmitted && !record.dosis })} />
                                        {record.ayudas!['dosis'] !== '' ? <><i className="pi pi-question-circle mr-2" style={{ 'fontSize': '0.8em', color: 'green' }} ></i><small>{record.ayudas!['dosis']}</small></> : null}
                                        {addsubmitted && !record.dosis && <small className="p-invalid">El valor de Dosis es requerido</small>}
                                    </div>
                                    <div className="p-field p-col-12 p-md-2">
                                        {/* {record.insumoseleccionado ? record.insumoseleccionado.insumo.unidad.nombre : null} */}
                                        <label htmlFor={property('cantidad')?.field}>{property('cantidad')?.header}</label>
                                        <InputNumber locale="es" style={{ marginTop: "0.3rem" }} id={property('cantidad')?.field} value={record.cantidad} mode='decimal' showButtons={property('cantidad')?.showButtons} inputId="minmax-buttons" step={property('cantidad')?.numberStep} maxFractionDigits={3} onValueChange={(e) => changed('cantidad', e.value)} required={property('cantidad')?.required} className={classNames({ 'p-invalid': addsubmitted && !record.cantidad && property('cantidad')?.required })} />
                                        {record.ayudas!['cantidad'] !== '' ? <><i className="pi pi-question-circle mr-2" style={{ 'fontSize': '0.8em', color: 'green' }} ></i><small>{record.ayudas!['cantidad']}</small></> : null}
                                        {addsubmitted && !record.cantidad && property('cantidad')?.required && <small className="p-invalid">El valor de Cantidad es requerido</small>}
                                    </div>
                                    <div className="p-field p-col-12 p-md-3">
                                        <AGDropdown col={columnas.find((x) => x.field === 'deposito')!} value={record.deposito} id={record.empresa?.id} onChanged={(x) => changed('deposito', x)} submitted={addsubmitted} />
                                        {/* lastQueryExpression={lastDeposito} onUpdateQueryExpression={(e) => setLastDeposito(e)} */}
                                    </div>
                                    <div className="p-field p-col-12 p-md-2 p-mt-5">
                                        <Button label="Agregar" icon="pi pi-plus" onClick={() => add()} />
                                    </div>
                                    <div className="p-field p-col-12">
                                        <OrdenDeTrabajoInsumos values={record.insumos} hectareas={record.hectareas} onChanged={(e) => changed('insumos', e)} insumoseleccionadoaplicacion={record.insumoseleccionadoaplicacion!} especieid={record.especie?.id} />
                                    </div>

                                </div>
                            </TabPanel>
                            <TabPanel header=" Observaciones" leftIcon="pi pi-comment">
                                {/* <Editor className="p-editor-toolbar" style={{ height: '320px' }} value={record.observacion} onTextChange={(e) => changed('observacion', e)} /> */}
                                <ReactQuill
                                    id='editor'
                                    modules={toolbarOptions}
                                    theme='snow'
                                    value={observacion ? observacion : ''}
                                    onChange={handleChange}
                                />
                                <div style={{ marginBottom: 10, marginTop: 10 }}>
                                    Notas finales:
                                    <Divider />
                                    {props.value && props.value.observacionesfinales}
                                </div>
                            </TabPanel>



                        </TabView>

                    </div>
                </div>
                {asociarestablecimiento ? <AsociarEstablecimiento campaña={record?.campaña} empresa={record?.empresa} onSubmit={() => setAsociarEstablecimiento(false)} title={'Asociar lote/s del establecimiento'} /> : null}
            </>
        )
    } else {
        return (
            <NoAutorizado title='Nueva órden de trabajo' nombres={userControl.GetUserName()} />
        )
    }


}
interface ordenDeTrabajoProps {
    cancel?: () => void;
    value?: any;
    onSubmit: () => void;
    role?: TRole;
}