import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber'
import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column'
import { useState, useEffect } from 'react';
import { Calendar } from 'primereact/calendar';
import classNames from 'classnames';
// import { Dialog } from 'primereact/dialog';
import { TRecordField } from 'utils/interfaces'
import { formatearFechaDMY } from 'utils/formDataUtils'
// import { genericentityrowDTO } from 'app/dto/genericentityrow.model'
import { GenericService } from 'app/service/GenericService';
import { genericentityrowDTO } from 'app/dto/genericentityrow.model';
import { genericentityrowCreacionDTO } from 'app/dto/genericentityrow.model'
import { urlGenericEntitiesRows } from "app/service/Endpoints"

export default function EntitySetupLoadRecordsCRUD(props: entitySetupLoadRecordsCRUDProps) {

    const service = new GenericService();

    type valueField = {
        fieldName: string,
        fieldValue: any,
        fieldType: string,
    }

    const [values, setValues] = useState<valueField[]>()
    const [loading, setLoading] = useState(true)
    const [submitted, setSubmitted] = useState(false)
    const [element, setElement] = useState('')
    // const [elementFocus, setElementFocus] = useState('')
    // const [error, setError] = useState('')
    const [records, setRecords] = useState<any[]>()

    useEffect(() => {
        if (props.columns && loading) {
            setLoading(false)
            if (props.id) {
                service.getId({ url: urlGenericEntitiesRows, id: props.id }).then(response => {
                    if (response.success) {
                        load(response.data)
                    } else {
                        //console.log("get load rows error ", response.error.Message)
                    }
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.columns]);

    // useEffect(() => {
    //     //console.log(records)
    // }, [records]);

    const load = (data: genericentityrowDTO[]) => {
        if (data) {
            let _records: any[];
            data.forEach(element => {
                let [cols] = [Object.values(element)]
                let fields: [{ name: string, value: any, id: number }] = (JSON.parse(cols[2]))
                let _values = { id: element.id, genericEntityFieldSetupId: element.genericEntityId };
                fields.forEach(e => {
                    var key = e.name,
                        obj = {
                            [key]: e.value
                        }
                    _values = { ..._values, ...obj }
                });

                if (_records) {
                    _records = [..._records, _values]
                } else {
                    _records = [_values]
                }
            });
            setRecords(_records!);
        }
    }


    useEffect(() => {
        if (element !== '') {
            //console.log(element)
            document.getElementById(element)?.focus()
            setElement('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [element]);


    const isValid = () => {
        let _isValid = true;
        props.columns!.forEach(element => {
            //console.log(element)
            let value = values?.find((p) => p.fieldName === element.fieldName)
            if (value) {
                //console.log(value)
                if (value.fieldValue === null || value.fieldValue === undefined || value.fieldValue === '')
                    _isValid = false;
            } else {
                _isValid = false;
            }
        });
        return _isValid
    }


    const save = () => {
        setSubmitted(true)

        if (!isValid()) return

        //prepara el POST

        let newKeysValuesObject: any[];
        let valor: any;

        values?.forEach((e, index) => {
            //console.log(index, e.fieldValue)
            switch (props.columns![index].fieldType) {
                case 'string':
                    valor = String(e.fieldValue).toString()
                    break;
                case 'number':
                    valor = e.fieldValue
                    break;
                default:
                    valor = e.fieldValue
                    break;
            }

            let obj = {
                id: index,
                name: e.fieldName,
                value: valor
            }

            if (!newKeysValuesObject)
                newKeysValuesObject = [obj]
            else
                newKeysValuesObject.push(obj)
            // service.post({ url: urlGenericEntitiesRow, creacion: newPost! })
        })

        if (props.id && newKeysValuesObject!.length !== 0) {
            let newRow: genericentityrowCreacionDTO = {
                GenericEntityId: props.id!,
                KeysValues: JSON.stringify(newKeysValuesObject!)
            }
            //console.log(newRow!)
            service.post({ url: urlGenericEntitiesRows, creacion: newRow! }).then(response => {
                //console.log(response)
                let _values = { id: response.data.id, genericEntityFieldSetupId: props.id };
                values?.forEach(e => {
                    var key = e.fieldName,
                        obj = {
                            [key]: e.fieldValue
                        }
                    _values = { ..._values, ...obj }
                });
                let newRecord = _values
                if (records) {
                    let _records = [...records, newRecord]
                    setRecords(_records)
                } else {
                    let _records = newRecord
                    setRecords([_records])
                }
                clear()
            })

        }
        setSubmitted((x) => !x)
    }

    const clear = () => {
        setValues((x) => x = []);
    }

    const remove = (rowData: any) => {
        //console.log(rowData)
        //console.log(records)
        service.delete({ url: urlGenericEntitiesRows, id: rowData.id, creacion: rowData }).then((response) => {
            let _records = records?.filter((x) => x !== rowData)
            //let _record = records?.find((r) => r.fieldName !== rowData.fieldName)
            setRecords(_records)
            // props.rowsChanged!(_records!)
        })

    }

    const actionBodyTemplate = (rowData: any) => {
        return (
            <div>
                <Button icon="pi pi-times" className="p-button-rounded p-button-danger p-button-text" onClick={() => remove(rowData)} />
            </div>
        );
    }

    const bodyTemplate = (data: any, props: any) => {
        //console.log(props.filterType, data)
        //console.log("fields", props.field)
        //console.log("value", data[props.field])
        switch (props.filterType) {
            case 'string' || null:
                return (
                    <>
                        {data[props.field]}
                    </>
                );
            case 'number':
                return (
                    <>
                        {data[props.field]}
                    </>);

            case 'date':
                return (
                    <>
                        {formatearFechaDMY(data[props.field])}
                    </>);

        }
    }

    // const bodyTemplateType = (data: any, props: any) => {
    //     //console.log(data)
    //     return (
    //         <>
    //             {data ? options.filter((e) => e.value === data[props.field])[0].label : null}
    //         </>
    //     );
    // }

    // const inputTextEditor = (productKey: string, props: any, field: string) => {
    //     return <InputText type="text" value={props.rowData[field]} onChange={(e) => onEditorValueChange(productKey, props, e.target.value)} />;
    // }

    // const onEditorValueChange = (productKey: string, props: any, value: any) => {

    //     let updatedRecords = [...props.value];
    //     //console.log(value)
    //     updatedRecords[props.rowIndex][props.field] = value;
    //     //console.log(updatedRecords)
    //     //setRecords(updatedRecords)
    //     // dataTableFuncMap[`${productKey}`](updatedProducts);
    // }


    const getValue = (field: string, type?: string) => {
        //console.log(field)
        //return null
        // if (props.updateMode)
        //     return Object.values(row)[idIndexByName(field)]
        // else
        //console.log(values)

        if (values) {
            //alert(field)
            //console.log("values", values)
            //console.log("field", field)
            let declaration = values.filter((v) => v.fieldName === field)
            if (declaration.length !== 0) {
                //return value.fieldValue!
                //console.log("field" + field + " valor: " + declaration)
                return declaration[0].fieldValue
            } else {
                switch (type!) {
                    case 'string':
                        return ''
                    case 'date':
                        return new Date()
                    default:
                        return null
                }
            }

        } else {
            switch (type!) {
                case 'string':
                    return ''
                case 'date':
                    return new Date()
                default:
                    return null
            }
        }


        //return Object.values(row)[idColByName(field)]

    }

    const setValue = (field: string, value: any, type: string) => {
        //console.log("setValue: values " + JSON.stringify(values) + " field: " + field + " value: " + value)
        // if (value === null)
        //     value = ''
        if (values) {
            let _values = [...values]
            //debugger
            //console.log(_values)
            //busca si ya existe el campo
            let found = _values.find((v) => {
                if (v.fieldName === field) {
                    return v;
                }
                return undefined;
            })
            //si no existe lo crea
            if (!found) {
                let declaration: valueField = { fieldName: field, fieldValue: value, fieldType: type };
                //console.log("nuevo valor", declaration)
                let _values = [...values]
                _values.push(declaration)
                setValues(_values)
            } else {
                found.fieldValue = value;
                //let _values = [...values]
                setValues(_values)

            }
        } else {
            // var key = field,
            // obj = {
            //     [key]: value
            // };
            let declaration = { fieldName: field, fieldValue: value, fieldType: type };
            //console.log("nuevo valor", declaration)
            setValues([declaration])
        }
        //console.log("despues", values)
        // var key = field,
        //     obj = {
        //         [key]: value
        //     };
        // //console.log(obj)
        // let _entidad = { ...row, ...obj }
        // //console.log(_entidad)
        // setRow(_entidad);

    }

    return (
        <>
            {/* <Dialog modal header="Atención" visible={error !== ''} style={{ width: '20vw' }} footer={renderFooter} onHide={() => { setError(''); setElement(elementFocus) }}>
                <p>{error}</p>
            </Dialog> */}
            <div className='p-grid' >

                <div className="col-12 ml-2">
                    <h6>{props.title!.toLocaleUpperCase()}</h6>
                </div>

                <div className="p-inputgroup col-12">
                    {props.columns ?
                        props.columns!.map((col, index) =>
                            col.fieldType === 'string' ?
                                <div className="col-3">
                                    <span className="p-float-label" key={col.fieldPosition}>
                                        <InputText id={col.fieldName} autoFocus={index === 0} style={{ marginTop: "0.3rem" }} value={getValue(col.fieldName, col.fieldType)} onChange={(e) => setValue(e.target.id, e.target.value, col.fieldType)} required={true} className={classNames({ 'p-invalid': submitted && !getValue(col.fieldName) && true })} />
                                        <label htmlFor={col.fieldName}>{col.fieldName}</label>
                                    </span>
                                    {submitted && !getValue(col.fieldName) && true && <small className="p-invalid">El valor de {col.fieldName} es requerido</small>}
                                </div>
                                : col.fieldType === 'date' ?
                                    <div className="col-2">
                                        <span className="p-float-label" key={col.fieldPosition}>
                                            <Calendar locale="es" style={{ marginTop: "0.3rem" }} showIcon id={col.fieldName} value={new Date(getValue(col.fieldName, col.fieldType))} onChange={(e) => setValue(e.target.id, e.target.value, col.fieldType)} dateFormat="dd/mm/yy" required={true} className={classNames({ 'p-invalid': submitted && !getValue(col.fieldName) && true })} />
                                            <label htmlFor={col.fieldName}>{col.fieldName}</label>
                                        </span>
                                        {submitted && !getValue(col.fieldName) && true && <small className="p-invalid">El valor de {col.fieldName}</small>}
                                    </div>

                                    : col.fieldType === 'boolean' || col.fieldType === 'switch' ?
                                        // <div className="p-field-checkbox" style={{ marginTop: "0.7rem" }} key={col.id}>
                                        //     <Checkbox id={col.field} ariaLabelledBy={col.field} style={{ marginRight: "0.5rem" }} checked={getValue(col.field)} onChange={(e) => onInputChange(e)} />
                                        //     <label htmlFor={col.field} onClick={() => onInputChange({ target: { type: 'checkbox', id: col.field, checked: !getValue(col.field) } })}> Activo</label>
                                        // </div>
                                        null
                                        : col.fieldType === 'number' ?
                                            <div className="col-2">
                                                <span className="p-float-label" key={col.fieldPosition}>
                                                    <InputNumber id={col.fieldName} autoFocus={index === 0} style={{ marginTop: "0.3rem" }} value={getValue(col.fieldName, col.fieldType)} mode="decimal" onValueChange={(e) => setValue(e.target.id, e.target.value, col.fieldType)} required={true} className={classNames({ 'p-invalid': submitted && !getValue(col.fieldName) && true })} />
                                                    <label htmlFor={col.fieldName}>{col.fieldName}</label>
                                                </span>
                                                {submitted && !getValue(col.fieldName) && true && <small className="p-invalid">El valor de {col.fieldName} es requerido</small>}
                                            </div> : null

                        ) : null}


                    <div className="col-2 mt-1">
                        <Button label="Agregar" icon="pi pi-plus" onClick={() => save()} />
                    </div>
                </div>
                {/* p-button-rounded p-button-danger p-button-text */}

                <div className="col-12 ml-2">
                    <DataTable value={records!} resizableColumns editMode="cell" columnResizeMode="fit" className="editable-cells-table" rowHover emptyMessage={'No hay ' + props.title!}>
                        {props.columns ?
                            props.columns!.map(col =>
                                <Column field={col.fieldName} sortField={col.fieldName} key={col.fieldName} header={col.fieldName} filterType={col.fieldType} sortable={true} body={bodyTemplate}>
                                </Column>) : null}

                        <Column body={actionBodyTemplate} header="Acciones" />

                    </DataTable>
                </div>
            </div>
        </>
    )
}

interface entitySetupLoadRecordsCRUDProps {
    id?: number,
    title?: string, //nombre de la entidad que esta configurando
    columns?: TRecordField[];
    //envía al padre los datos de campos y filas cada vez que se agrega/modifica/elimina
    //rowsChanged?: (records: TRecordField[]) => void,
}

EntitySetupLoadRecordsCRUD.defaultProps = {
    title: "Campos",
}