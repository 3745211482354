import { claim, respuestaAutenticacion } from "./auth.model";
import { perfilusuarioDTO } from "../app/dto/usuarios.model"

const llaveToken = "token";
const llaveExpiracion = "token-expiracion"
const usuario = "user-data"


export function guardarTokenLocalStorage(autenticacion: respuestaAutenticacion) {
    localStorage.setItem(llaveToken, autenticacion.token);
    localStorage.setItem(llaveExpiracion, autenticacion.expiracion.toString());
    localStorage.setItem(usuario, JSON.stringify(autenticacion.usuario));
}

export function obtenerClaims(): claim[] {
    const token = localStorage.getItem(llaveToken);

    if (!token) {
        return [];
    }

    const expiracion = localStorage.getItem(llaveExpiracion)!;
    const expiracionFecha = new Date(expiracion);
    const perfilusuario = localStorage.getItem(usuario)!;

    if (expiracionFecha <= new Date()) {
        logout();
        return [];
    }

    //debugger
    //console.log("token", token)

    const dataToken = JSON.parse(atob(token.split(".")[1]));
    //console.log("dataToken", dataToken)
    const respuesta: claim[] = [];
    for (const propiedad in dataToken) {
        //alert(propiedad + " " + dataToken[propiedad])
        respuesta.push({ nombre: propiedad, valor: dataToken[propiedad] });
    }

    if (perfilusuario) {
        let value: perfilusuarioDTO = JSON.parse(perfilusuario)
        respuesta.push({ nombre: 'user', valor: value })
    }

    return respuesta;
}

export function logout() {
    localStorage.removeItem(llaveToken);
    localStorage.removeItem(llaveExpiracion);
    localStorage.removeItem(usuario);
}

export function obtenerToken() {
    return localStorage.getItem(llaveToken);
}