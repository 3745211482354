import { useRef, useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { GenericService } from 'app/service/GenericService';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import classNames from 'classnames';
import { Calendar } from 'primereact/calendar';
import { Toast } from 'primereact/toast';
import { Checkbox } from 'primereact/checkbox'
import { Divider } from 'primereact/divider'
import { ITableColumn, ICustomAttributes, IDefaultColumn, IValidateAddNew, TComponentName } from 'utils/interfaces';
import { isValidCUITCUIL } from 'utils/formDataUtils';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown'
import { MultiSelect } from 'primereact/multiselect';
import { InputMask } from 'primereact/inputmask';
import Location from 'app/components/Location'
import MostrarErrores from "utils/MostrarErrores";
import Modal from './Modal'
import { genericentityrowDTO } from 'app/dto/genericentityrow.model'
import KeysValues from 'app/components/KeysValues'

export default function CU<TEntidad, TEntidadCreacion>(props: cuInterfaceProps<TEntidad, TEntidadCreacion>) {

    const [row, setRow] = useState<TEntidadCreacion>(props.emptyRow);

    const [dialog, setDialog] = useState(false);

    const [loading, setLoading] = useState(true);
    const [loadingDepende, setLoadingDepende] = useState(true);
    const [loadingDefault, setLoadingDefault] = useState(false);
    const [element, setElement] = useState('');

    const toast = useRef<Toast>(null);

    const [submitted, setSubmitted] = useState(false);

    const [errores, setErrores] = useState<string[]>([]);

    const [modalOpen, setModalOpen] = useState('');

    type optionsEntity = {
        name: string,
        loaded: boolean,
        values: []
    }

    const [optionsEntityId] = useState<optionsEntity[]>([]);

    const [refresh, setRefresh] = useState(true);

    const service = new GenericService();

    type validateState = 'pending' | 'validating' | 'valid' | 'invalid'

    let validatestate: validateState = 'pending'

    // const [keyvalue, setKeyValue] = useState<TRecord[]>()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        //console.log(props)
        //alert(props.id)
        /* 
           lee los datos de la api cuando siempre y cuando no contenga los datos 
           en dataRow e id no este vacío.
        */
        if (loading && props.updateMode)
            //console.log("updatemode id", props.id)
            if (props.id || props.params) {
                setLoading(false)
                service.getId({ url: props.url, creacion: row, id: props.id, params: props.params! }).then(response => {
                    if (response.success) {
                        setErrores([]);
                        setRow(response.data);
                        setDialog(true);
                    }
                    else {
                        //console.log(response)
                        props.close!(response.error![0]);
                    }
                }).catch(error => {
                    alert("catch")
                    console.log(error);
                    if (props.updateMode) {
                        props.close!();
                    }
                })
            } else if (props.dataRow) {
                //console.log("updatemode row", props.dataRow)
                setErrores([]);
                setRow(props.dataRow);
                setLoading(false)
                setDialog(true);
            };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    });

    useEffect(() => {
        if (props.columns && refresh) {
            props.columns!.forEach(element => {
                if (element.optionsFromEndPoint !== undefined) {
                    //console.log("useEffect para dropdown ", element.optionsFromEndPoint + ' id ' + element.optionsEntityId)
                    if (!loaded(element.field)) {
                        if (element.optionsEntityId === undefined) {
                            if (element.optionIdFromColumnField === undefined) {
                                service.get({ url: element.optionsFromEndPoint }).then(response => { setOptions(response.data, element.field, 0, true) }).catch(error => console.log(error));
                            }
                        } else {
                            service.getId({ url: element.optionsFromEndPoint, id: element.optionsEntityId }).then(response => { setOptions(response, element.field, element.optionsEntityId!, true) }).catch(error => console.log(error));
                        }
                    }
                } else {
                    //lista que se encuentra cargada hardcodeado
                    if (element.type === 'dropdown' && element.options) {
                        setOptions(element.options, element.field, 0, true)
                    }
                }
            })
            setRefresh(false)
            setLoadingDefault(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refresh])

    useEffect(() => {
        if (loadingDefault && props.defaults) {
            //console.log("loadingDefault", props.defaults)
            props.defaults!.forEach(element => {
                setValue(element.fieldtarget, element.value)
                if (element.disabledtarget === true) {
                    let col = props.columns?.find(c => c.field === element.fieldtarget)
                    if ('disabledEdit' in col!) {
                        col.disabledEdit = true
                        col.autoFocus = false
                        if ('fieldfocus' in element) {
                            setElement('idinput' + element.fieldfocus!)
                            //console.log('idinput' + element.fieldfocus!)
                            // let col = props.columns?.find(c => c.field === element.fieldfocus)
                            // //console.log(col)
                            // if (col) {
                            //     setElement(element.fieldfocus!)
                            //     col.autoFocus = true;
                            // }
                            // console.log(element, col)
                        }
                    }
                }
            });
            setLoadingDefault(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingDefault])

    useEffect(() => {
        if (element !== '') {
            //console.log(document)
            //document.getElementById(element)?.focus()
            //console.log(document.getElementById(element)?.)
            //alert(element)
            setElement('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [element]);

    const setOptions = (response: any, fieldName: string, entityid: number, refresh?: boolean) => {
        if (response) {
            //console.log("setOptions fields", fieldName)
            //console.log("setOptions ", response)

            //carga y formatea los datos que se leen de entidades genéricas.
            if (entityid !== 0) {
                response = load(response.data, fieldName)
                //console.log("load: " + fieldName, entityid, response)
            }

            optionsEntityId.forEach(element => {
                //console.log(element.name, element.loaded)
                if (element.name === fieldName) {
                    element.loaded = true;
                    element.values = response
                }

            });

            if (refresh) {
                //refreshColum(fieldName, -1)
                setRefresh(true)
            }

        }
    }


    const load = (data: genericentityrowDTO[], fieldName: string) => {

        //buscar información de los campos a presentar el que contiene el id y la columna descripción/nombre
        //se busca por el nombre del campo
        let label: string;
        let value: string;

        if (data) {

            for (let index = 0; index < props.columns!.length; index++) {
                //console.log(getDataFromUrl.fieldName)
                const element = props.columns![index];
                if (element.field === fieldName) {
                    label = element.optionName!;
                    value = element.optionValue!;
                }
            }

            let _records: any[];
            data.forEach(element => {
                let [cols] = [Object.values(element)]
                let fields: [{ name: string, value: any, id: number }] = (JSON.parse(cols[2]))
                //console.log(fields)
                let _values = {};
                fields.forEach(e => {
                    //console.log(e.name)
                    if (e.name === label || e.name === value) {
                        var key = e.name,
                            obj = {
                                [key]: e.value
                            }
                        _values = { ..._values, ...obj }
                    }
                });
                if (_records) {
                    _records = [..._records, _values]
                } else {
                    _records = [_values]
                }
            });

            return _records!
        }
    }

    const getOptions = (fieldName: string) => {

        let optionIndex = -1;
        if (fieldName !== '') {

            for (let index = 0; index < optionsEntityId.length; index++) {
                const element = optionsEntityId[index];
                if (element.name === fieldName) {
                    if (element.loaded) {
                        //debugger
                        return element.values!
                    }
                    else
                        optionIndex = index
                }
            }

            if (optionIndex === -1) {
                //console.log("getOptions", fieldName + " / " + optionIndex)
                optionsEntityId.push({ name: fieldName, loaded: false, values: [] })
                optionIndex = optionsEntityId.length - 1
                return optionsEntityId[optionIndex].values!
                //console.log(fieldName, optionIndex)
            } else {
                return []
            }
        }
    }


    if (!dialog && !props.updateMode) {
        setErrores([]);
        setRow(props.emptyRow);
        setSubmitted(false);
        setDialog(true);
    }


    const hideDialog = () => {
        setDialog(false);
        props.close!();
    }

    const onInputChange = (e: any) => {
        setErrores([])
        //console.log(e)
        //console.log(e.target.value)
        let val: any;

        if (e.target) {
            //alert("target " + e.target.id)
            //const val = (e.target && e.target.value) || '';
            if (e.target.type === 'checkbox') {
                //alert("check")
                //console.log(e.target.checked)
                val = e.target.checked;
            } else {
                //val = (e.target.value) || '';
                val = (e.target.value);
            }
            var key = e.target.id,
                obj = {
                    [key]: val
                };
            //console.log("onInputChange", obj)
            let _entidad = { ...row, ...obj }

            //console.log(row)
            //setRow(_entidad);
            //console.log("entidad", _entidad)
            refreshColum(key, val, _entidad)
        }
    }

    const refreshColum = (fieldName: string, val: number, _entidad: any) => {
        //console.log("refreshColum", fieldName, val)
        if (val === -1) {
            props.columns!.forEach(element => {
                if (element.optionIdFromColumnField === fieldName) {
                    let data = Object.entries(row)
                    //console.log("data", data)
                    let value = data.find((k) => k[0] === fieldName)
                    //console.log(value)
                    if (value) {
                        val = value[1]
                        //console.log(value[1])
                    }
                }
            });
        }

        if (val !== -1 && val !== 0) {
            //console.log("leyendo", val)
            props.columns!.forEach(element => {
                if (element.optionIdFromColumnField === fieldName) {
                    //let values = Object.values(row)
                    //console.log("Refresh", element.field, val)
                    //console.log("getid", element.optionsFromEndPoint, val)
                    if (_entidad) {
                        if (element.field in _entidad) {
                            _entidad[element.field] = undefined
                        }
                    }
                    service.getId({ url: element.optionsFromEndPoint!, id: val }).then(response => {
                        if (response.data.length !== 0) {
                            //console.log(response.data)
                            setOptions(response.data, element.field, 0, true)
                        } else {
                            //console.log(element.field)
                            //debugger
                            //setValue(element.field, 0)
                            setOptions([], element.field, 0, true)
                        }
                    }).catch(error => console.log(error));
                }
            });
        }

        if (_entidad) {
            //console.log(_entidad)
            setRow(_entidad);
        }

        setLoadingDepende(false)
    }

    const onSelectLotacion = (campo: string, valor: any) => {
        //alert(campo + ' ' + valor)
        var key = campo,
            obj = {
                [key]: valor
            };
        let _entidad = { ...row, ...obj }
        //console.log(_entidad)
        setRow(_entidad);
    }

    const getValue = (field: string, type: string) => {
        //console.log(field)
        //console.log(row)
        if (props.updateMode) {

            //let value = Object.values(row)[idIndexByName(field)];
            let index = Object.keys(row).findIndex(x => x === field)
            let value = Object.values(row)[index];
            //console.log("index, valor", index, value)

            if (type === 'multiSelect') {
                if (value && getOptions(field)?.length) {
                    //console.log("value", value)
                    return value;
                } else
                    return [];

                //console.log("row", row)
                //console.log("valor de field", field, value)
            }

            if (!loading && loadingDepende) {
                refreshColum(field, value, undefined)
            }
            if (row && type === 'keyvalue') {
                //console.log("CU row", row)
                let value = Object.values(row)[idIndexByName(field)];
                if (value) {
                    //console.log("CU getValue value keyvalue", JSON.parse(value))
                    return JSON.parse(value);
                }
            }
            return value;
        }
        else {
            return Object.values(row)[idColByName(field)]
        }

    }

    const setValue = (field: string, value: any) => {
        var key = field,
            obj = {
                [key]: value
            };
        //console.log(obj)
        let _entidad = { ...row, ...obj }
        //console.log(_entidad)
        setRow(_entidad);
    }

    // indice de la columna del campo key para enviar la actualizacion 
    const idIndex = (): number => {
        let col = props.columns!.filter((col) => col.isKey === true)
        return col[0].id
    }

    //posicion de la columna al editar
    const idIndexByName = (name: string): number => {
        //console.log(name.toLocaleLowerCase())
        let col = props.columns!.filter((col) => col.field === name)
        //console.log("idIndexByName", name + ' id ' + col[0].id + ' editando ' + editando)
        return col[0].id
    }

    //posicion de la columna al agregar
    const idColByName = (name: string): number => {
        let colu = props.columns!.filter((col) => col.field === name)
        //console.log("idColByName", name + ' col ' + col[0].colInput + ' editando ' + editando)
        //console.log(colu[0])

        return colu[0].colInput!
    }

    const isValid = (): boolean | undefined => {
        let _isValid = true;

        props.columns!.forEach((col) => {
            if (col.required) {
                //console.log(getValue(col.field, col.type!))
                if (!getValue(col.field, col.type!)) {
                    _isValid = false
                }
            }
            if (col.isCUIT) {
                let cuit: string = getValue(col.field, col.type!);
                if (cuit && !isValidCUITCUIL(cuit)) {
                    setErrores(['Debe ingresar un número de CUIT válido'])
                    //toast.current!.show({ severity: 'error', summary: 'Debe ingresar un CUIT válido', life: 3000 });
                    _isValid = false
                }
            }
            // alert('campo: ' + col.field + " requerido: " + col.required + " valor:'" + values[col.id] + "' modelo válido: " + _isValid)
        })


        //debugger
        //console.log("es válido", _isValid, validating, exist)

        if (_isValid) {
            if (props.validateAddNew) {
                if (validatestate === 'pending') {
                    let params = {}
                    props.validateAddNew!.fields.forEach(element => {
                        //console.log(element.name)
                        element.value = getValue(element.name, '')
                        var key = element.name,
                            obj = {
                                [key]: element.value
                            };
                        params = { ...params, ...obj }

                    });
                    validatestate = 'validating'
                    Exist(params)
                    return false
                } else {
                    //alert(exist)
                    if (validatestate === 'validating') {
                        return false
                    } else {
                        if (validatestate === 'valid') {
                            return true
                        } else {
                            return false
                        }
                    }
                }
            } else {
                //console.log("return", _isValid)
                return true
            }

        } else {
            return false
        }

    }

    async function Exist(params: any) {
        console.log(params)
        await service.getExist({ url: props.validateAddNew!.url, params: params }).then((response) => {
            if (response.success) {
                //debugger
                if (response.data.exist) {
                    validatestate = 'invalid'
                    setErrores([response.data.message])
                } else {
                    validatestate = 'valid'
                    //alert(validatestate)
                    save()
                }
            }
        }).catch((error) => {
            console.log(error)
        })
        //validatestate = 'invalid'
        //setErrores(['test'])
    }

    const save = () => {
        //alert("save")
        //console.log(row)
        setErrores([]);
        setSubmitted(true);
        if (isValid()) {
            //alert("is valid")
            //console.log("antes ", rows)
            let _row = { ...row };
            //console.log(_row)
            let values = Object.values(row) //valores de la fila que se modifica
            let idKey = idIndex() //indice del campo primario
            //console.log("editando", editando)

            if (props.updateMode) {
                //console.log("editando", row)
                service.put({ url: props.url, id: values[idKey], creacion: _row }).then(response => {
                    //console.log(res)
                    if (response.success) {
                        if (response.data.length === 1) {
                            response.data = response.data[0]
                        }
                        if (props.save) {
                            props.save(response.data);
                        }
                    } else {
                        setErrores(response.error)
                    }
                })
            } else {
                //console.log("llama al post", row)
                //debugger
                service.post({ url: props.url, creacion: row }).then(response => {
                    if (response.success) {
                        if (response.data.length === 1) {
                            response.data = response.data[0]
                        }
                        props.save!(response.data);
                    } else {
                        setErrores(response.error);
                    }
                })
            }
        }
    }



    const closeModal = (props: any) => {
        //console.log(props)
        setModalOpen('')
    }

    const saveModal = (row: any, field: string, entidad: any) => {
        //console.log("saveModal", row)
        //console.log(field)
        setValue(field, row.id)
        try {
            toast.current!.show({ severity: 'success', summary: entidad, detail: 'Registro creado', life: 2000 });
        } catch (error) {

        }
        reload(field)
        setRefresh(true)
        setModalOpen('')
    }

    function AddLink(col: ITableColumn) {
        //console.log("field", col)
        if (col.addNew) {
            return (
                <>
                    <Button style={{ padding: '5px', marginTop: '3px', marginBottom: '5px', textAlign: 'left', width: 'auto', height: '20px', background: 'none', border: 'none', color: 'green' }} icon="pi pi-plus
" onClick={() => setModalOpen(col.field)} label={col.addNew.title}
                        className="p-button-secondary" />
                    {modalOpen === col.field ? <Modal entidad={col.addNew.entidad} save={(row) => saveModal(row, col.field, col.addNew!.entidad)} close={closeModal} /> : null}
                </>
            )
        } else {
            return null
        }
    }

    const HeaderTemplate = () => {
        return (

            <div >
                {props.updateMode ?
                    props.customAttibutes?.updateLegend ? props.customAttibutes?.updateLegend : " Editar " + props.nombreEntidad
                    : props.customAttibutes?.newLegend ? props.customAttibutes?.newLegend : " Nueva/o " + props.nombreEntidad}
            </div>

        )
    }
    const DialogFooter = () => {
        return (
            <>
                {props.componentadjunt ?
                    <>
                        <Divider />
                        <div className="flex">
                            <MostrarErrores errores={errores} />
                            <Button label="Cerrar" icon="pi pi-times" className="p-button-text  p-button-danger" onClick={hideDialog} />
                            <Button label="Agregar" icon="pi pi-plus" className="p-button-text p-button-primary" onClick={save} />
                        </div>
                        <Divider />
                        <Modal component={props.componentadjunt} id={props.componentadjuntid} />
                    </>
                    :
                    <>
                        <Divider />
                        <div className="p-d-flex p-jc-between">
                            <MostrarErrores errores={errores} />
                            <div>
                                <Button label="Cancelar" icon="pi pi-times" className="p-button-text  p-button-danger" onClick={hideDialog} />
                                <Button label="Guardar" icon="pi pi-save" className="p-button-text p-button-primary" onClick={save} />
                            </div>
                        </div>

                        <Divider />
                    </>
                }
                {/* </div> */}
            </>
        )
    };

    const loaded = (fieldName: string) => {
        //console.log(optionsEntityId)
        let loaded = false;
        optionsEntityId.forEach(element => {
            //console.log(element.name, element.loaded)
            if (element.name === fieldName) {
                if (element.loaded)
                    loaded = true;
            }
        });
        return loaded
    }

    const reload = (fieldName: string) => {
        console.log("reload", fieldName)
        optionsEntityId.forEach(element => {
            //console.log(element.name, element.loaded)
            if (element.name === fieldName) {
                if (element.loaded)
                    element.loaded = false;
            }
        });
    }




    return (
        <>
            <Toast ref={toast} />


            <Dialog className='crud-dialog' closable={true} closeOnEscape={true} visible={dialog} blockScroll={true} position={'right'} header={HeaderTemplate} footer={DialogFooter} onHide={hideDialog} draggable={false} modal={true} >

                <div className="p-fluid" >
                    {props.columns!.map(col =>
                        !col.disabled ?
                            col.type === 'string' || col.type === undefined ?
                                <div className="p-field" key={col.id}>
                                    <label htmlFor={col.field}>{col.header}</label>
                                    <InputText id={col.field} style={{ marginTop: "0.3rem" }} value={getValue(col.field, col.type!)} onChange={(e) => onInputChange(e)} required={col.required} disabled={col.disabledEdit || (col.disabledUpdate && props.updateMode)} autoFocus={col.autoFocus} className={classNames({ 'p-invalid': submitted && !getValue(col.field, col.type!) && col.required })} />
                                    {submitted && !getValue(col.field, col.type!) && col.required && <small className="p-invalid">El valor de {col.header} es requerido</small>}
                                </div>
                                : col.type === 'textarea' ?
                                    <div className="p-field" key={col.id}>
                                        <label htmlFor={col.field}>{col.header}</label>
                                        <InputTextarea id={col.field} style={{ marginTop: "0.3rem" }} value={getValue(col.field, col.type)} onChange={(e) => onInputChange(e)} required={col.required} autoResize autoFocus={col.autoFocus} className={classNames({ 'p-invalid': submitted && !getValue(col.field, col.type) && col.required })} />
                                        {submitted && !getValue(col.field, col.type) && col.required && <small className="p-invalid">El valor de {col.header} es requerido</small>}
                                    </div>
                                    : col.type === 'date' ?
                                        <div className="p-field" key={col.id}>
                                            <label htmlFor={col.field}>{col.header}</label>
                                            <Calendar locale="es" style={{ marginTop: "0.3rem" }} showIcon id={col.field} value={getValue(col.field, col.type) ? new Date(getValue(col.field, col.type)) : undefined} onChange={(e) => onInputChange(e)} dateFormat="dd/mm/yy" mask="99/99/9999" showButtonBar yearNavigator yearRange={'2000:2050'} monthNavigator required={col.required} disabled={col.disabledEdit || (col.disabledUpdate && props.updateMode)} className={classNames({ 'p-invalid': submitted && !getValue(col.field, col.type) && col.required })} />
                                            {submitted && !getValue(col.field, col.type) && col.required && <small className="p-invalid">El valor de {col.header}</small>}
                                        </div>
                                        : col.type === 'boolean' || col.type === 'switch' ?
                                            <div className="p-field-checkbox" style={{ marginTop: "0.7rem" }} key={col.id}>
                                                <Checkbox id={col.field} ariaLabelledBy={col.field} style={{ marginRight: "0.5rem" }} checked={getValue(col.field, col.type)} onChange={(e) => onInputChange(e)} />
                                                <label htmlFor={col.field} onClick={() => onInputChange({ target: { type: 'checkbox', id: col.field, checked: !getValue(col.field, col.type!) } })}> Activo</label>
                                            </div>
                                            : col.type === 'number' ?
                                                <div className="p-field" key={col.id}>
                                                    <label htmlFor={col.field}>{col.header}</label>
                                                    <InputNumber id={col.field} style={{ marginTop: "0.3rem" }} value={getValue(col.field, col.type)} minFractionDigits={2} mode="decimal" onKeyDown={(k) => {
                                                        if (k.code === 'NumpadDecimal') {
                                                            k.code = 'coma'
                                                        }
                                                    }} onValueChange={(e) => onInputChange(e)} required={col.required} disabled={col.disabledEdit || (col.disabledUpdate && props.updateMode)} autoFocus={col.autoFocus} className={classNames({ 'p-invalid': submitted && !getValue(col.field, col.type) && col.required })} />
                                                    {submitted && !getValue(col.field, col.type) && col.required && <small className="p-invalid">El valor de {col.header} es requerido</small>}
                                                </div>
                                                : col.type === 'location' && col.field === 'provincia' ?
                                                    <div className="p-field" key={col.id}>
                                                        <Location style={{ marginTop: "1rem" }}
                                                            provincia={props.updateMode ? Object.values(row)[idIndexByName(col.field)] : col.default} hidemap={col.hideMap}
                                                            onSelect={onSelectLotacion} provinciaRequerido={col.required}
                                                            departamento={props.updateMode ? Object.values(row)[idIndexByName('departamento')] : Object.values(row)[idColByName(col.field)]} departamentoRequerido={col.required}
                                                            localidad={props.updateMode ? Object.values(row)[idIndexByName('localidad')] : Object.values(row)[idColByName(col.field)]}
                                                            submitted={submitted} localidadRequerido={col.required}
                                                        />
                                                    </div>
                                                    // options={getOptions({ ...col })}
                                                    // onFocus={() => loadOptions(col.optionsFromEndPoint!, col.optionsEntityId!, col.field, col.options)}
                                                    : col.type === 'dropdown' ?
                                                        <div className="p-field" key={col.id}>
                                                            <label htmlFor={col.field}>{col.header}</label>
                                                            <Dropdown id={col.field} disabled={col.disabledEdit || (col.disabledUpdate && props.updateMode)} placeholder={'---seleccione---'} style={{ marginTop: "0.3rem" }} value={getValue(col.field, col.type)} onChange={(e) => onInputChange(e)} options={getOptions(col.field)} optionLabel={col.optionName!} filter={col.optionFilter} emptyMessage={'No hay registros'} emptyFilterMessage={'No hay resultados'} showClear showFilterClear ariaLabel={'Lista ' + col.field} optionValue={col.optionValue!} showOnFocus={col.showOnFocus} required={col.required} autoFocus={col.autoFocus} className={classNames({ 'p-invalid': submitted && !getValue(col.field, col.type) && col.required })} />
                                                            {submitted && !getValue(col.field, col.type) && col.required && <small className="p-invalid">El valor de {col.header} es requerido</small>}
                                                            <div><AddLink {...col} /></div>
                                                        </div>
                                                        : col.type === 'multiSelect' ?
                                                            <div className="p-field" key={col.id}>
                                                                <label htmlFor={col.field}>{col.header}</label>
                                                                <MultiSelect name={col.field} id={col.field} disabled={col.disabledEdit || (col.disabledUpdate && props.updateMode)} style={{ marginTop: "0.3rem" }} value={getValue(col.field, col.type)} options={getOptions(col.field)} optionLabel={col.optionName!} optionValue={col.optionValue!} display={'chip'} filter={col.optionFilter} placeholder={'---seleccione---'} onChange={(e) => onInputChange(e)} emptyFilterMessage={'No hay resultados'} showSelectAll={false} showClear className={classNames({ 'p-invalid': submitted && !getValue(col.field, col.type!) && col.required })} />
                                                                {submitted && !getValue(col.field, col.type!) && col.required && <small className="p-invalid">El valor de {col.header} es requerido</small>}
                                                            </div>
                                                            : col.type === 'mask' ?
                                                                <div className="p-field" key={col.id}>
                                                                    <label htmlFor={col.field}>{col.header}</label>
                                                                    <InputMask id={col.field} style={{ marginTop: "0.3rem" }} value={getValue(col.field, col.type)} mask={col.mask} onChange={(e) => onInputChange(e)} required={col.required} className={classNames({ 'p-invalid': submitted && !getValue(col.field, col.type!) && col.required })} />
                                                                    {submitted && !getValue(col.field, col.type!) && col.required && <small className="p-invalid">El valor de {col.header} es requerido</small>}
                                                                </div>
                                                                : col.type === 'keyvalue' ?
                                                                    <div className="p-field" key={col.id}>
                                                                        <KeysValues title={col.header} value={getValue(col.field, col.type)} rowsChanged={(rows) => {
                                                                            var key = col.field,
                                                                                obj = {
                                                                                    [key]: JSON.stringify(rows)
                                                                                };
                                                                            //console.log("onInputChange", obj)
                                                                            let _entidad = { ...row, ...obj }
                                                                            //console.log("row", _entidad)
                                                                            refreshColum(key, 0, _entidad)
                                                                            //setKeyValue(rows)
                                                                        }} />
                                                                    </div>
                                                                    : null : null
                    )}

                </div>
                {/* <DialogFooter /> */}


                {/* <InsumoAplicaciones id={7} /> */}
            </Dialog>
        </>

    )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface cuInterfaceProps<TEntidad, TEntidadCreacion> {
    url: string;
    id?: any;
    params?: any;
    updateMode?: boolean;
    nombreEntidad: string;
    customAttibutes?: ICustomAttributes;
    validateAddNew?: IValidateAddNew;
    dataKey: string;
    dataRow?: TEntidadCreacion;
    defaults?: IDefaultColumn[];
    componentadjunt?: TComponentName,
    componentadjuntid?: number,
    emptyRow: TEntidadCreacion;
    columns?: ITableColumn[];
    modal?: boolean;
    close?: (mensaje?: string) => void;
    save?: (row?: any) => void;
}

