import { campañaDTO, campañaCreacionDTO } from 'app/dto/campañas.model'
import { establecimientoDTO, establecimientoCreacionDTO } from 'app/dto/establicimiento.model'
import { especieDTO, especieCreacionDTO } from 'app/dto/especies.modes'
import { unidadDTO, unidadCreacionDTO } from 'app/dto/unidades.model'
import { empresaDTO, empresaCreacionDTO } from 'app/dto/empresas.model'
import { estadioDTO, estadioCreacionDTO } from 'app/dto/estadios.model'
import { depositoDTO, depositoCreacionDTO } from 'app/dto/depositos.model'
import { insumosDTO, insumosCreacionDTO } from 'app/dto/insumos.model'
import { loteDTO, loteCreacionDTO, loteAsociarDTO, loteAsociarCreacionDTO } from 'app/dto/lotes.model'
import { genericentityDTO, genericentityCreacionDTO } from 'app/dto/genericentities.model'
import { insumoaplicacionDTO, insumoaplicacionCreacionDTO } from 'app/dto/insumosaplicacion.mode'
import { useState } from 'react';
import Modal from './Modal'
import { GenericService } from 'app/service/GenericService';
import { urlLotesAsociar } from 'app/service/Endpoints'

import {
    urlCampañas,
    urlEstablecimientos,
    urlEspecies,
    urlEmpresas,
    urlUnidades,
    urlEstadios,
    urlDepositos,
    urlGenericEntitiesRows,
    urlEspeciesActivos,
    urlEmpresasActivos,
    urlGenericEntities,
    urlInsumos,
    urlUnidadesActivos,
    urlInsumosActivos,
    urlEstadiosByEspecie,
    urlInsumosAplicacion,
    urlEstablecimientosActivos,
    urlLotes, urlInsumosAplicacionExist,
    urlLotesAsociados,
    urlEstadiosInsumoExist,
    urlCampañasActivos,
    urlLotesEstablecimiento,
} from 'app/service/Endpoints'


import { ITableColumn, ICustomAttributes, IButton, IDefaultColumn, IValidateAddNew, TComponentName } from 'utils/interfaces'
import RD from './RD' //read and delete
import CU from './CU' //create and update


export function CrudCampaña(props: crudInterface) {
    const empty: campañaCreacionDTO = {
        nombre: '',
        fechaapertura: new Date(),
        fechacierre: new Date(),
        activo: true,
        empresas: [],
    }
    const columnas: ITableColumn[] = [
        { id: 0, field: 'id', hide: false, isKey: true, disabled: true, header: 'Código', sortable: false },
        { id: 1, field: 'nombre', autoFocus: true, colInput: 0, required: true, header: 'Campaña', sortable: true },
        { id: 2, field: 'fechaapertura', required: true, colInput: 1, header: 'Inicia', type: 'date', sortable: true },
        { id: 3, field: 'fechacierre', required: true, colInput: 2, header: 'Finaliza', type: 'date', sortable: true },
        { id: 4, field: 'activo', header: 'Activo', disabled: true, colInput: 3, type: 'switch', hideLabel: false, sortable: true, printable: false },
        { id: 5, field: 'empresas', hide: true, optionsFromEndPoint: urlEmpresasActivos, optionName: 'razonsocial', header: 'Empresas habilitadas', disabled: false, colInput: 4, type: 'multiSelect', sortable: false, printable: false },
    ]

    const custom: ICustomAttributes = {
        newLegend: "Nueva campaña",
        updateLegend: "Editar campaña",
        pluralEntity: "Campañas",
        deleteDisable: false,
        editDisable: false,
    }

    return (
        props.modalMode
            ?
            <CU<campañaDTO, campañaCreacionDTO> dataKey="id" updateMode={props.updateMode} url={urlCampañas} columns={columnas} nombreEntidad="Campaña" id={props.id} close={props.close} emptyRow={empty} customAttibutes={custom} />
            :
            <RD<campañaDTO, campañaCreacionDTO> dataKey="id" url={urlCampañas} columns={columnas} nombreEntidad="Campaña" emptyRow={empty} customAttibutes={custom} />
    )
}

export function CrudEspecies(props: crudInterface) {
    const empty: especieCreacionDTO = {
        nombre: '',
        activo: true
    }
    const columnas: ITableColumn[] = [
        { id: 0, field: 'id', isKey: true, hide: false, disabled: true, header: 'Id', sortable: true, align: 'right' },
        { id: 1, field: 'nombre', required: true, colInput: 0, autoFocus: true, header: 'Especie', sortable: true },
        { id: 2, field: 'activo', header: 'Activo', disabled: true, colInput: 1, type: 'switch', sortable: true, printable: false },
    ]

    const custom: ICustomAttributes = {
        newLegend: "Nueva especie",
        updateLegend: "Editar especie",
        pluralEntity: "Especies",
    }

    return (
        props.modalMode
            ?
            <CU<especieDTO, especieCreacionDTO> dataKey="id" updateMode={props.updateMode} url={urlEspecies} columns={columnas} nombreEntidad="Especie" id={props.id} close={props.close} save={props.save} emptyRow={empty} customAttibutes={custom} />
            :
            <RD<especieDTO, especieCreacionDTO> dataKey="id" url={urlEspecies} columns={columnas} nombreEntidad="Especie" emptyRow={empty} customAttibutes={custom} />

        // <CRUD<especieDTO, especieCreacionDTO> dataKey="id" url={urlEspecies} columns={columnas} nombreEntidad="Especie" emptyRow={empty} />
    )
}

export function CrudUnidades(props: crudInterface) {
    const empty: unidadCreacionDTO = {
        nombre: '',
        abreviatura: '',
    }
    const columnas: ITableColumn[] = [
        { id: 0, field: 'id', isKey: true, hide: true, disabled: true, header: 'Id', sortable: true, align: 'right' },
        { id: 1, field: 'nombre', required: true, colInput: 0, autoFocus: true, header: 'Unidad', sortable: true },
        { id: 2, field: 'abreviatura', required: true, colInput: 1, header: 'Abreviatura', sortable: true },

    ]

    const custom: ICustomAttributes = {
        newLegend: "Nuevo unidad",
        updateLegend: "Editar unidad",
        pluralEntity: "Unidades",
    }

    return (
        props.modalMode
            ?
            <CU<unidadDTO, unidadCreacionDTO> dataKey="id" updateMode={props.updateMode} url={urlUnidades} columns={columnas} nombreEntidad="Unidad" id={props.id} close={props.close} emptyRow={empty} customAttibutes={custom} />
            :
            <RD<unidadDTO, unidadCreacionDTO> dataKey="id" url={urlUnidades} columns={columnas} nombreEntidad="Unidad" emptyRow={empty} customAttibutes={custom} />

        // <CRUD<unidadDTO, unidadCreacionDTO> dataKey="id" url={urlUnidades} columns={columnas} nombreEntidad="Unidad" emptyRow={empty} />
    )
}

export function CrudEstadios(props: crudInterface) {

    const empty: estadioCreacionDTO = {
        especieid: 0,
        nombre: '',
        orden: 0,
        activo: true,
    }

    const columnas: ITableColumn[] = [
        { id: 0, field: 'id', isKey: true, hide: true, disabled: true, header: 'Id', sortable: true, align: 'right' },
        { id: 1, field: 'especieid', hide: false, showField: 'nombreespecie', required: true, colInput: 0, autoFocus: true, type: 'dropdown', optionsFromEndPoint: urlEspeciesActivos, optionName: 'nombre', optionValue: 'id', header: 'Especie', sortable: true, optionFilter: true, addNew: { title: 'Nueva especie', entidad: 'Especies' } },
        // { id: 2, field: 'nombre', required: true, colInput: 1, header: 'Estadio', sortable: true },
        { id: 2, field: 'nombre', hide: false, required: true, colInput: 1, type: 'dropdown', optionsFromEndPoint: urlGenericEntitiesRows, optionsEntityId: 13, optionName: 'Estadio', optionValue: 'Estadio', optionFilter: true, header: 'Estadio', sortable: true },
        { id: 3, field: 'orden', required: true, colInput: 2, type: 'number', header: 'Orden', sortable: true, printable: false },
        { id: 4, field: 'activo', header: 'Activo', colInput: 3, type: 'switch', sortable: true, printable: false },
    ]

    const custom: ICustomAttributes = {
        newLegend: "Nuevo estadio",
        updateLegend: "Editar estadio",
        pluralEntity: "Estadios",
    }

    const validateAddNew: IValidateAddNew = {
        id: 0,
        url: urlEstadiosInsumoExist,
        fields: [{ name: 'id', value: undefined }, { name: 'especieid', value: undefined }, { name: 'nombre', value: undefined }]
    }

    return (
        // loading ? null :
        props.modalMode
            ?
            <CU<estadioDTO, estadioCreacionDTO> dataKey="id" updateMode={props.updateMode} url={urlEstadios} columns={columnas} nombreEntidad="Estadio" id={props.id} validateAddNew={validateAddNew} close={props.close} emptyRow={empty} customAttibutes={custom} />
            :
            <RD<estadioDTO, estadioCreacionDTO> dataKey="id" url={urlEstadios} validateAddNew={validateAddNew} columns={columnas} nombreEntidad="Estadio" emptyRow={empty} customAttibutes={custom} />
    )
}

export function CrudEmpresas(props: crudInterface) {
    const categoriasIVA = [
        { label: 'Responsable Inscripto', value: 'Responsable Inscripto' },
        { label: 'Responsable Monotributo', value: 'Responsable Monotributo' },
        { label: 'Exento', value: 'Exento' },
    ];

    const empty: empresaCreacionDTO = {
        razonsocial: '',
        categoriacontribuyente: '',
        cuit: '',
        activo: true
    }
    const columnas: ITableColumn[] = [
        { id: 0, field: 'id', isKey: true, hide: true, disabled: true, header: 'Id', sortable: true, printable: false },
        { id: 1, field: 'razonsocial', required: true, colInput: 0, autoFocus: true, header: 'Razón Social', sortable: true },
        { id: 2, field: 'categoriacontribuyente', required: true, colInput: 1, type: 'dropdown', options: categoriasIVA, showOnFocus: true, header: 'Categoría', sortable: true },
        { id: 3, field: 'cuit', required: false, colInput: 2, isCUIT: true, type: 'mask', mask: '99-99999999-9', header: 'CUIT', sortable: true },
        { id: 4, field: 'activo', header: 'Activo', colInput: 3, type: 'switch', sortable: true, printable: false },
    ]

    const custom: ICustomAttributes = {
        newLegend: "Nueva empresa",
        updateLegend: "Editar empresa",
        pluralEntity: "Empresas",
    }

    return (
        props.modalMode
            ?
            <CU<empresaDTO, empresaCreacionDTO> dataKey="id" updateMode={props.updateMode} url={urlEmpresas} columns={columnas} nombreEntidad="Empresa" id={props.id} close={props.close} emptyRow={empty} customAttibutes={custom} />
            :
            <RD<empresaDTO, empresaCreacionDTO> dataKey="id" url={urlEmpresas} columns={columnas} nombreEntidad="Empresa" emptyRow={empty} customAttibutes={custom} />
    )
}


export function CrudEstablecimientos(props: crudInterface) {
    const empty: establecimientoCreacionDTO = {
        nombre: '',
        hectareas: 0,
        provincia: '',
        departamento: '',
        localidad: '',
        activo: true
    }
    const columnas: ITableColumn[] = [
        { id: 0, field: 'id', hide: true, isKey: true, disabled: true, header: 'Id', sortable: true },
        { id: 1, field: 'nombre', required: true, colInput: 0, autoFocus: true, header: 'Establecimiento', sortable: true },
        { id: 2, field: 'hectareas', required: true, colInput: 1, type: 'number', align: 'center', header: 'Hectareas', sortable: true },
        { id: 3, field: 'provincia', required: true, colInput: 2, header: 'Provincia', type: 'location', sortable: true },
        { id: 4, field: 'departamento', required: true, colInput: 3, header: 'Departamento', type: 'location', sortable: true },
        { id: 5, field: 'localidad', required: true, colInput: 4, header: 'Localidad', type: 'location', sortable: true },
        { id: 6, field: 'activo', header: 'Activo', colInput: 5, type: 'switch', sortable: true, printable: false },
    ]

    const custom: ICustomAttributes = {
        newLegend: "Nuevo establecimiento",
        updateLegend: "Editar establecimiento",
        pluralEntity: "Establecimientos",
    }

    return (
        props.modalMode
            ?
            <CU<establecimientoDTO, establecimientoCreacionDTO> dataKey="id" updateMode={props.updateMode} url={urlEstablecimientos} columns={columnas} nombreEntidad="Establecimiento" id={props.id} close={props.close} emptyRow={empty} customAttibutes={custom} />
            :
            <RD<establecimientoDTO, establecimientoCreacionDTO> dataKey="id" url={urlEstablecimientos} columns={columnas} nombreEntidad="Establecimiento" emptyRow={empty} customAttibutes={custom} />

    )
}


export function CrudInsumoAplicacion(props: crudInterface) {
    const empty: insumoaplicacionCreacionDTO = {
        insumoid: 0,
        especieid: 0,
        estadiodesdeid: 0,
        estadiohastaid: 0,
        dosisdesde: 0,
        dosissugerida: 0,
        dosishasta: 0,
    }

    const columnas: ITableColumn[] = [
        { id: 0, field: 'id', hide: true, isKey: true, disabled: true, header: 'Id', sortable: true, align: 'right' },
        { id: 1, field: 'insumoid', hide: false, showField: 'insumonombre', disabledEdit: false, required: true, colInput: 0, autoFocus: true, type: 'dropdown', optionsFromEndPoint: urlInsumosActivos, optionName: 'nombre', optionValue: 'id', header: 'Insumo', sortable: true, optionFilter: true },
        {
            id: 2, field: 'especieid', hide: false, showField: 'especienombre', required: true, colInput: 1, type: 'dropdown', optionsFromEndPoint:
                urlEspeciesActivos, optionName: 'nombre', optionValue: 'id', header: 'Especie', sortable: true, optionFilter: true
        },
        {
            id: 3, field: 'estadiodesdeid', hide: false, showField: 'estadiodesdenombre', required: true, colInput: 2, type: 'dropdown', optionsFromEndPoint:
                urlEstadiosByEspecie, optionIdFromColumnField: 'especieid', optionName: 'nombre', optionValue: 'id', header: 'Desde Estadio', sortable: true, optionFilter: true
        },
        {
            id: 4, field: 'estadiohastaid', hide: false, showField: 'estadiohastanombre', required: true, colInput: 3, type: 'dropdown', optionsFromEndPoint:
                urlEstadiosByEspecie, optionIdFromColumnField: 'especieid', optionName: 'nombre', optionValue: 'id', header: 'Hasta Estadio', sortable: true, optionFilter: true
        },
        { id: 5, field: 'dosisdesde', hide: true, required: false, colInput: 4, type: 'number', align: 'right', showMask: '#.##0,000', header: 'Dosis desde', sortable: false, printable: false },
        { id: 6, field: 'dosissugerida', hide: false, required: false, colInput: 5, type: 'number', align: 'center', showMask: '#.##0,000', header: 'Dosis sugerida', sortable: false },
        { id: 7, field: 'dosishasta', hide: true, required: false, colInput: 6, type: 'number', align: 'right', showMask: '#.##0,000', header: 'Dosis hasta', sortable: false, printable: false },
    ]


    const custom: ICustomAttributes = {
        newLegend: "Nueva aplicación",
        updateLegend: "Editar aplicación",
        pluralEntity: "Insumo aplicación",
    }

    const validateAddNew: IValidateAddNew = {
        id: 0,
        url: urlInsumosAplicacionExist,
        fields: [{ name: 'id', value: undefined }, { name: 'insumoid', value: undefined }, { name: 'especieid', value: undefined }]
    }

    return (
        props.modalMode
            ?
            <CU<insumoaplicacionDTO, insumoaplicacionCreacionDTO> dataKey="id" updateMode={props.updateMode} url={urlInsumosAplicacion} validateAddNew={validateAddNew} componentadjunt={props.componentadjunt} componentadjuntid={props.componentadjuntid} columns={columnas} defaults={props.defaults} nombreEntidad="Insumo aplicación" id={props.id} close={props.close} emptyRow={empty} customAttibutes={custom} />
            :
            <RD<insumoaplicacionDTO, insumoaplicacionCreacionDTO> validateAddNew={validateAddNew} dataKey="id" url={urlInsumosAplicacion} columns={columnas} nombreEntidad="Insumo aplicación" emptyRow={empty} customAttibutes={custom} />

    )
}


export function CrudDepositos(props: crudInterface) {
    const empty: depositoCreacionDTO = {
        empresaid: 0,
        nombre: '',
        provincia: '',
        departamento: '',
        localidad: '',
        domicilio: '',
        encargado: '',
        email: '',
        telefonos: '',
        activo: true
    }
    const columnas: ITableColumn[] = [
        { id: 0, field: 'id', isKey: true, hide: true, disabled: true, header: 'Id', sortable: true, printable: false },
        { id: 1, field: 'empresaid', fieldcamelCase: 'empresaId', showField: 'empresa.razonsocial', hide: false, required: true, colInput: 0, autoFocus: true, type: 'dropdown', optionsFromEndPoint: urlEmpresasActivos, optionName: 'razonsocial', optionValue: 'id', header: 'Empresa', sortable: true, optionFilter: true, addNew: { title: 'Nueva empresa', entidad: 'Empresas' } },
        { id: 2, field: 'nombre', required: true, colInput: 1, header: 'Deposito', sortable: true },
        { id: 3, field: 'provincia', hide: true, colInput: 2, header: 'Provincia', type: 'location', sortable: true, printable: false },
        { id: 4, field: 'departamento', hide: true, colInput: 3, header: 'Departamento', type: 'location', sortable: true, printable: false },
        { id: 5, field: 'localidad', hide: true, colInput: 4, header: 'Localidad', type: 'location', sortable: true },
        { id: 6, field: 'domicilio', colInput: 5, header: 'Domicilio', sortable: true },
        { id: 7, field: 'encargado', colInput: 6, header: 'Encargado', sortable: true },
        { id: 8, field: 'email', hide: true, colInput: 7, header: 'Email', sortable: true, printable: false },
        { id: 9, field: 'telefonos', colInput: 8, header: 'Telefono/s', sortable: true },
        { id: 10, field: 'activo', header: 'Activo', colInput: 9, type: 'switch', sortable: true, printable: false },
    ]

    const custom: ICustomAttributes = {
        newLegend: "Nuevo depósito",
        updateLegend: "Editar depósito",
        pluralEntity: "Depósitos"
    }
    return (
        props.modalMode
            ?
            <CU<depositoDTO, depositoCreacionDTO> dataKey="id" updateMode={props.updateMode} url={urlDepositos} columns={columnas} nombreEntidad="Depósito" id={props.id} close={props.close} emptyRow={empty} customAttibutes={custom} />
            :
            <RD<depositoDTO, depositoCreacionDTO> dataKey="id" url={urlDepositos} columns={columnas} nombreEntidad="Depósito" emptyRow={empty} customAttibutes={custom} refreshComponent={false} />
    )
}

export function CrudLotes(props: crudInterface) {
    const empty: loteCreacionDTO = {
        establecimientoid: 0,
        nombre: '',
        ambiente: '',
        hectareas: 0,
    }

    const ambiente = [
        { label: 'Regular', value: 'Regular' },
        { label: 'Bueno', value: 'Bueno' },
        { label: 'Muy bueno', value: 'Muy bueno' },
    ];

    const columnas: ITableColumn[] = [
        { id: 0, field: 'id', isKey: true, hide: true, disabled: true, header: 'Id', sortable: true, align: 'right' },
        { id: 1, field: 'establecimientoid', hide: false, required: true, colInput: 0, autoFocus: true, type: 'dropdown', optionsFromEndPoint: urlEstablecimientosActivos, optionName: 'nombre', optionValue: 'id', header: 'Establecimiento', sortable: true, showField: 'establecimientonombre', optionFilter: true, disabledUpdate: true },
        { id: 2, field: 'nombre', required: true, colInput: 1, header: 'Nombre', sortable: true },
        { id: 3, field: 'ambiente', hide: false, required: true, colInput: 2, header: 'Ambiente', type: 'dropdown', options: ambiente, optionName: 'label', optionValue: 'value', sortable: true },
        { id: 4, field: 'hectareas', colInput: 3, required: true, header: 'Hectáreas', type: 'number', align: 'center', sortable: true },
    ]

    const custom: ICustomAttributes = {
        newLegend: "Nuevo lote",
        updateLegend: "Editar lote",
        pluralEntity: "Lotes"
    }
    return (
        props.modalMode
            ?
            <CU<loteDTO, loteCreacionDTO> dataKey="id" updateMode={props.updateMode} url={urlLotes} columns={columnas} nombreEntidad="Lotes" id={props.id} close={props.close} emptyRow={empty} customAttibutes={custom} />
            :
            <RD<loteDTO, loteCreacionDTO> dataKey="id" url={urlLotes} columns={columnas} nombreEntidad="Lotes" emptyRow={empty} customAttibutes={custom} />
    )
}

export function CrudLotesAsociados(props: crudInterface) {

    const [dialogEspecies, setDialogEspecies] = useState(false);
    const [data, setData] = useState<any[]>([]);
    const [row, setRow] = useState<any>();

    const service = new GenericService();

    const empty: loteAsociarCreacionDTO = {
        campañaid: 0,
        empresaid: 0,
        establecimientoid: 0,
        loteid: 0,
        especieantecesorid: 0,
        especieprimeraid: 0,
        especiesegundaid: 0
    }


    const columnas: ITableColumn[] = [
        { id: 0, field: 'campaña.nombre', hide: false, required: false, disabled: true, type: 'dropdown', optionsFromEndPoint: urlCampañasActivos, optionName: 'nombre', optionValue: 'id', header: 'Campaña', sortable: true, optionFilter: true },
        { id: 1, field: 'Campaña', hide: true, required: true, type: 'dropdown', optionsFromEndPoint: urlCampañasActivos, optionName: 'nombre', header: 'Campaña', sortable: true, colInput: 0, disabledEdit: false, optionFilter: false, disabledUpdate: false },
        { id: 2, field: 'empresa.razonsocial', hide: false, required: true, type: 'dropdown', optionsFromEndPoint: urlEmpresasActivos, optionName: 'razonsocial', optionValue: 'id', header: 'Empresa', sortable: true, optionFilter: true, disabledUpdate: true },
        { id: 3, field: 'establecimiento.nombre', hide: false, required: true, type: 'dropdown', optionsFromEndPoint: urlEstablecimientosActivos, optionName: 'nombre', optionValue: 'id', header: 'Establecimiento', sortable: true, optionFilter: true, disabledUpdate: true },
        { id: 4, field: 'lote.nombre', hide: false, required: true, type: 'dropdown', optionsFromEndPoint: urlLotesEstablecimiento, optionName: 'nombre', optionValue: 'id', header: 'Lote', sortable: true, optionFilter: true, disabledUpdate: true },
        { id: 5, field: 'especieAntecesor.nombre', hide: false, required: true, type: 'dropdown', optionsFromEndPoint: urlEspeciesActivos, optionName: 'nombre', optionValue: 'id', header: 'Antecesor', sortable: true, optionFilter: true, disabledUpdate: true },
        { id: 6, field: 'especiePrimera.nombre', hide: false, required: true, type: 'dropdown', optionsFromEndPoint: urlEspeciesActivos, optionName: 'nombre', optionValue: 'id', header: 'Primera', sortable: true, optionFilter: true, disabledUpdate: true },
        { id: 7, field: 'especieSegunda.nombre', hide: false, required: true, type: 'dropdown', optionsFromEndPoint: urlEspeciesActivos, optionName: 'nombre', optionValue: 'id', header: 'Segunda', sortable: true, optionFilter: true, disabledUpdate: true },

    ]

    const especies: ITableColumn[] = [
        { id: 0, field: 'especieantecesor', hide: false, required: false, colInput: 0, autoFocus: true, type: 'dropdown', optionsFromEndPoint: urlEspeciesActivos, optionName: 'nombre', header: 'Especie antecesor' },
        { id: 1, field: 'especieprimera', hide: false, required: true, colInput: 1, type: 'dropdown', optionsFromEndPoint: urlEspeciesActivos, optionName: 'nombre', header: 'Especie primera' },
        { id: 2, field: 'especiesegunda', hide: false, required: false, colInput: 2, type: 'dropdown', optionsFromEndPoint: urlEspeciesActivos, optionName: 'nombre', header: 'Especie segunda' },
    ]

    const EditRow = (row: any) => {
        //console.log("Edit row ", row)
        setRow(row)
        let _row = { ...row }
        if (_row.especieAntecesor) {
            data![0] = { id: _row.especieAntecesor.id, nombre: _row.especieAntecesor.nombre, activo: _row.especieAntecesor.activo }
        } else {
            data![0] = {}
        }
        if (_row.especiePrimera) {
            data![1] = { id: _row.especiePrimera.id, nombre: _row.especiePrimera.nombre, activo: _row.especiePrimera.activo }
        } else {
            data![1] = {}
        }
        if (_row.especieSegunda) {
            data![2] = { id: _row.especieSegunda.id, nombre: _row.especieSegunda.nombre, activo: _row.especieSegunda.activo }
        } else {
            data![2] = {}
        }
        setData(data)
        setDialogEspecies(true)
    }

    const saveRow = (values: any) => {
        //console.log(values)
        //console.log(row)
        const { especieantecesor, especieprimera, especiesegunda } = values;

        console.log(especieantecesor, especieprimera, especiesegunda)
        let param = {
            campañaid: row.campañaid,
            empresaid: row.empresaid,
            establecimientoid: row.establecimientoid,
            loteid: row.loteid,
            especieantecesorid: especieantecesor ? especieantecesor!.id : 0,
            especieprimeraid: especieprimera!.id,
            especiesegundaid: especiesegunda ? especiesegunda!.id : 0,
        }
        //console.log("param ", param)
        service.put({ url: urlLotesAsociar, id: undefined, params: param, creacion: undefined }).then(response => {
            setDialogEspecies(false)
            //console.log("param ", param)
            //console.log(response)
            // if (response.success) {
            //     if (response.data.length === 1) {
            //         response.data = response.data[0]
            //     }
            //     if (props.save) {
            //         props.save(response.data);
            //     }
            // } else {
            //     //setErrores(response.error)
            // }
        })


    }

    const custom: ICustomAttributes = {
        newLegend: "Nueva asociación",
        updateLegend: "Editar asociaciones",
        pluralEntity: "Lotes asociados",
        onEdit: (row) => EditRow(row)
    }
    return (
        props.modalMode
            ?
            <CU<loteAsociarDTO, loteAsociarCreacionDTO> dataKey="id" updateMode={props.updateMode} url={urlLotes} columns={columnas} nombreEntidad="Lotes asociados" id={props.id} close={props.close} emptyRow={empty} customAttibutes={custom} />
            : dialogEspecies ? <Modal component='especieslote' values={data} columns={especies} close={() => setDialogEspecies(false)} save={(values) => saveRow(values)} /> :
                <RD<loteAsociarDTO, loteAsociarCreacionDTO> dataKey="id" url={urlLotesAsociados} columns={columnas} nombreEntidad="Lotes asociados" emptyRow={empty} customAttibutes={custom} />

    )
}


export function CrudInsumos(props: crudInterface) {
    const empty: insumosCreacionDTO = {
        tipoid: 0,
        nombre: '',
        presentacion: '',
        datostecnicos: '',
        marcaid: 0,
        unidadid: 0,
        costo: 0,
        observaciones: '',
        formulacionagroquimicoid: 0,
        formulacionfertilizanteid: 0,
        clasificaciontoxicologicaid: 0,
        tecnologiaid: 0,
        eiq: 0,
        activo: true
    }

    // addNew: { title: 'Nueva empresa', entidad: 'Empresas' }


    const columnas: ITableColumn[] = [
        { id: 0, field: 'id', isKey: true, hide: true, disabled: true, header: 'Id', sortable: true, align: 'right' },
        { id: 1, field: 'tipoid', hide: false, required: true, colInput: 0, autoFocus: true, type: 'dropdown', optionsFromEndPoint: urlGenericEntitiesRows, optionsEntityId: 10, optionsShowValue: true, optionName: 'Nombre', optionValue: 'Id', header: 'Tipo', sortable: true, printable: false },
        { id: 2, field: 'nombre', required: true, colInput: 1, header: 'Nombre', sortable: true },
        { id: 3, field: 'presentacion', hide: true, colInput: 2, header: 'Presentación', sortable: true },
        { id: 4, field: 'datostecnicos', hide: true, colInput: 3, header: 'Datos técnicos', sortable: true, printable: false },
        { id: 5, field: 'marcaid', colInput: 4, type: 'dropdown', optionsFromEndPoint: urlGenericEntitiesRows, optionsEntityId: 12, optionName: 'Nombre', optionValue: 'Id', optionsShowValue: true, header: 'Marca', sortable: true, optionFilter: true, printable: false },
        { id: 6, field: 'unidadid', colInput: 5, hide: false, showField: 'unidad.nombre', type: 'dropdown', optionsFromEndPoint: urlUnidadesActivos, optionName: 'nombre', optionValue: 'id', header: 'Unidad', sortable: true, optionFilter: true },
        { id: 7, field: 'costo', colInput: 6, hide: true, type: 'number', header: 'Costo unitario', sortable: true, align: 'right', showMask: '#.##0,00', printable: true },
        { id: 8, field: 'observaciones', hide: true, colInput: 7, type: 'textarea', header: 'Observaciones', sortable: false, printable: false },
        { id: 9, field: 'formulacionagroquimicoid', hide: true, colInput: 8, type: 'dropdown', optionsFromEndPoint: urlGenericEntitiesRows, optionsEntityId: 2, optionName: 'Descripción', optionValue: 'Código', optionsShowValue: true, header: 'Formulación', sortable: true, optionFilter: true, printable: false },
        { id: 10, field: 'formulacionfertilizanteid', hide: true, colInput: 9, type: 'dropdown', optionsFromEndPoint: urlGenericEntitiesRows, optionsEntityId: 3, optionName: 'Formulación', optionValue: 'Id', header: 'Fórmula fertilizante', sortable: true, optionFilter: true, printable: false },
        { id: 11, field: 'clasificaciontoxicologicaid', hide: true, colInput: 10, type: 'dropdown', optionsFromEndPoint: urlGenericEntitiesRows, optionsEntityId: 9, optionName: 'Clasificación', optionValue: 'Id', header: 'Clasif. toxicológica', sortable: true, optionFilter: true, printable: false },
        { id: 12, field: 'tecnologiaid', hide: true, colInput: 11, type: 'dropdown', optionsFromEndPoint: urlGenericEntitiesRows, optionsEntityId: 11, optionName: 'Tecnología', optionValue: 'Id', header: 'Tecnología', sortable: true, optionFilter: true, printable: false },
        { id: 13, field: 'eiq', hide: true, colInput: 12, required: false, type: 'number', header: 'EIQ', sortable: false, printable: false },
        { id: 14, field: 'activo', header: 'Activo', colInput: 13, type: 'switch', sortable: false, printable: false },

    ]

    const addRecords: IButton = {
        icon: "pi pi-plus-circle",
        className: "p-button-rounded p-button-susses p-button-circle",
        style: { height: 25, width: 25, marginRight: '.5em' },
        loadComponent: 'insumoaplicaciones',
        defaults: [{ fieldsource: 'id', fieldtarget: 'insumoid', value: '', disabledtarget: true, fieldfocus: 'especieid', componentadjunt: 'aplicacionesdelinsumo' }],
    }

    const custom: ICustomAttributes = {
        newLegend: "Nuevo insumo",
        updateLegend: "Editar insumo",
        pluralEntity: "Insumos",
        buttons: [addRecords]
    }
    return (
        props.modalMode
            ?
            <CU<insumosDTO, insumosCreacionDTO> dataKey="id" updateMode={props.updateMode} url={urlInsumos} columns={columnas} nombreEntidad="Insumos" id={props.id} close={props.close} emptyRow={empty} customAttibutes={custom} />
            :
            <RD<insumosDTO, insumosCreacionDTO> dataKey="id" url={urlInsumos} columns={columnas} nombreEntidad="Insumos" emptyRow={empty} customAttibutes={custom} />
    )
}


export function CrudGenericEntities(props: crudInterface) {
    const empty: genericentityCreacionDTO = {
        name: '',
        setupfieldkeyname: '',
        setupfieldfirstvaluename: '',
        setupfieldsortname: '',
    }
    const columnas: ITableColumn[] = [
        { id: 0, field: 'id', hide: false, isKey: true, disabled: true, header: 'Id', sortable: true },
        { id: 1, field: 'name', autoFocus: true, colInput: 0, required: true, header: 'Nombre', sortable: true },
        { id: 2, field: 'setupfieldkeyname', fieldcamelCase: 'setupFieldKeyName', required: true, colInput: 1, header: 'Nombre campo clave', sortable: true },
        { id: 3, field: 'setupfieldfirstvaluename', fieldcamelCase: 'setupFieldFirstValueName', required: true, colInput: 2, header: 'Nombre del campo a devolver', sortable: true },
        { id: 4, field: 'setupfieldsortname', hide: true, fieldcamelCase: 'setupFieldSortName', required: true, colInput: 3, header: 'Nombre del campo para ordenar', sortable: true },
    ]

    const setup: IButton = {
        icon: "pi pi-cog",
        className: "p-button-rounded p-button-help p-button-circle",
        style: { height: 25, width: 25, marginRight: '.5em' },
        loadComponent: 'entitysetup',
        validateAccess: true,
    }

    const addRecords: IButton = {
        icon: "pi pi-plus-circle",
        className: "p-button-rounded p-button-susses p-button-circle",
        style: { height: 25, width: 25, marginRight: '.5em' },
        loadComponent: 'loadrecords',
    }

    const custom: ICustomAttributes = {
        newLegend: "Nueva entidad",
        updateLegend: "Editar entidad",
        pluralEntity: "Entidades genéricas",
        buttons: [setup, addRecords],
    }

    return (
        props.modalMode
            ?
            <CU<genericentityDTO, genericentityCreacionDTO> dataKey="id" updateMode={props.updateMode} url={urlGenericEntities} columns={columnas} nombreEntidad="Entidades genéricas" id={props.id} close={props.close} emptyRow={empty} customAttibutes={custom} />
            :
            <RD<genericentityDTO, genericentityCreacionDTO> dataKey="id" url={urlGenericEntities} columns={columnas} nombreEntidad="Entidades genéricas" emptyRow={empty} customAttibutes={custom} />
    )
}

interface crudInterface {
    modalMode?: boolean;
    updateMode?: boolean;
    defaults?: IDefaultColumn[];
    componentadjunt?: TComponentName,
    componentadjuntid?: number,
    close?: (mensaje?: string) => void;
    save?: (row: any) => void;
    id?: any;
}

