
import axios from 'axios'


export class jsonService {
    async GetProvincias() {
        const json = await axios.get('assets/data/provincias.json')
        //console.log(json.data.provincias)
        return json.data.provincias;

        //return provincias;
    }

    async GetDepartamentos(props) {
        const json = await axios.get('assets/data/departamentos.json')
        return json.data.departamentos
    }

    async GetLocalidades(props) {
        let json = await axios.get('assets/data/localidades.json')
        //console.log(json)
        //JSON.parse((x => json.data.localidades.nombre))
        //console.log(localidades)

        //return json.data.localidades
        //console.log(JSON.stringify(json.data.localidades, ['nombre', 'provincia', 'departamento']))
        //let localidades = JSON.stringify(json.data.localidades, ['nombre', 'provincia', 'departamento'])

        return json.data.localidades
        //return localidades

    }

}
