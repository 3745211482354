import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { TOptions } from 'utils/interfaces'
import { ListBox } from 'primereact/listbox';
import { Divider } from 'primereact/divider'
import { confirmDialog } from 'primereact/confirmdialog';

export function solicitarOpciones(
    onAccept?: () => void,
    onCancel?: () => void,
    onSelect?: (value: any) => void,
    options?: TOptions[],
    title?: string,
    selected?: string,
) {

    confirmDialog({
        message: <Opciones modal={false} options={options} selected={selected} title={title} onSelect={(value) => onSelect && onSelect!(value)} />,
        header: title,
        //icon: 'pi pi-question-circle',
        rejectLabel: "Cancelar",
        rejectIcon: 'pi pi-times',
        acceptClassName: 'p-button-rounded p-button-success p-button-text',
        rejectClassName: 'p-button-rounded p-button-danger p-button-text',
        acceptIcon: 'pi pi-check',
        acceptLabel: "Aceptar",
        // resizable: false,
        reject: () => onCancel!(),
        accept: () => onAccept!(),
    });
}

export default function Opciones(props: opcionesProps) {

    const [value, setValue] = useState(props.selected!)
    const [dialog, setDialog] = useState(true)
    const [submitted, setSubmitted] = useState(false)


    //console.log(value)
    useEffect(() => {

    })

    const dialogFooterTemplate = () => {
        return (
            <div>
                <Divider />
                <Button label="Cancelar" icon="pi pi-times" className="p-button-text  p-button-danger" autoFocus onClick={() => {
                    props.cancel && props.cancel!();
                    setDialog(false)
                }} />
                <Button label="Aceptar" icon="pi pi-check" className="p-button-text p-button-primary" onClick={() => {
                    setSubmitted(true)
                    if (value) {
                        props.accept && props.accept!(value);
                        setDialog(false)
                    }
                }} />
            </div>
        )
    }

    return (
        props.modal ?
            <>
                <Dialog closable={true} header={() => props.title + ' Id ' + props.id} visible={dialog} style={{ width: '35vw' }}
                    contentStyle={{ height: '300px' }} focusOnShow onHide={() => {
                        props.cancel && props.cancel!();
                        setDialog(false)
                    }} footer={dialogFooterTemplate}>
                    <div>
                        <h6>{props.message?.split("\n").map((i, key) => {
                            return <div key={key}>{i}</div>;
                        })}</h6>
                    </div>
                    <ListBox className='p-listbox p-mr-25' value={value} options={props.options} onChange={(e) => { setValue(e.value); setSubmitted(false); props.onSelect && props.onSelect!(e.value) }} />
                    {submitted && !value && <small className="p-invalid">Debe seleccionar una opción.</small>}
                </Dialog>
            </> :
            <>
                <div>
                    <h6>{props.message?.split("\n").map((i, key) => {
                        return <div key={key}>{i}</div>;
                    })}</h6>
                </div>
                <ListBox className='p-listbox p-mr-25' style={{ width: '35vw' }} value={value} options={props.options} onChange={(e) => { setValue(e.value); setSubmitted(false); props.onSelect && props.onSelect!(e.value) }} />
            </>

    )
}

interface opcionesProps {
    id?: number;
    options?: TOptions[];
    selected?: string;
    title?: string;
    message?: string;
    modal?: boolean;
    accept?: (value: any) => void;
    cancel?: () => void;
    onSelect?: (value: any) => void;
}

Opciones.defaultProps = {
    title: 'Seleccione la opción deseada',
    options: [{ label: 'Opción 1', value: 'Opción 1' }, { label: 'Opción 2', value: 'Opción 2' }],
    modal: true,
}