import { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import * as func from 'utils/formDataUtils'
import { ITableColumn } from 'utils/interfaces'
import { GenericService } from 'app/service/GenericService';
import { urlStockMovimientos } from 'app/service/Endpoints'
import { IMovimientoStock } from 'app/dto/stock.mode'
import { InputText } from 'primereact/inputtext';
import * as template from 'app/general/DataTableTemplate'

export default function Movimientos(props: movimientosProps) {

    const service = new GenericService();
    const [refresh, setRefresh] = useState(true);
    const [rows, setRows] = useState<IMovimientoStock[]>();
    const [globalFilter, setGlobalFilter] = useState('');

    const lista: ITableColumn[] = [
        { id: 0, field: 'id', hide: true, sortable: false, disabledEdit: true, header: 'Id' },
        { id: 1, field: 'fecha', hide: false, sortable: true, disabledEdit: true, type: 'date', header: 'Fecha', align: 'center', filter: true },
        { id: 2, field: 'movimientotipo', hide: false, sortable: true, disabledEdit: true, header: 'Tipo' },
        { id: 3, field: 'comprobantetipo', hide: false, sortable: true, disabledEdit: true, header: 'Comprobante' },
        { id: 4, field: 'comprobantenumero', hide: false, sortable: true, disabledEdit: true, header: 'Número' },
        { id: 5, field: 'cantidad', hide: false, sortable: true, disabledEdit: true, type: 'number', align: 'right', showMask: '#.##0,000', header: 'Cantidad' },
        { id: 6, field: 'saldo', hide: false, sortable: true, disabledEdit: true, type: 'number', align: 'right', showMask: '#.##0,000', header: 'Acumulado' },

    ]

    useEffect(() => {
        if (refresh) {
            //toast.current!.show({ severity: 'success', detail: 'Leyendo datos...', life: 1000 });
            setRefresh(false)
            let param = {
                empresaid: props.empresa ? props.empresa.id : undefined,
                depositoid: props?.deposito ? props?.deposito.id : undefined,
                insumoid: props.insumo.producto.id,
            }
            console.log("param: ", param)
            service.get({ url: urlStockMovimientos, creacion: undefined, params: param }).then(response => {
                if (response.success) {
                    console.log("data", response.data)
                    //console.log(JSON.parse(data[3]['condiciones']))
                    setRows(response.data);
                }
            })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refresh]);

    const bodyTemplate = (data: any, colProps: any) => {
        return func.bodyTemplate(data, colProps, { columns: lista })
    }

    const TableHeader = (
        <div className="table-header">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText style={{ width: '300px' }} className="" value={globalFilter} onChange={(e) => { setGlobalFilter(e.target.value); }} placeholder="Buscar" />
            </span>
        </div>
    );


    return (
        <>
            <span>{props.empresa && 'EMPRESA: ' + props.empresa.razonsocial + ' '}</span>
            <span>{props.deposito && 'DEPOSITO: ' + props.deposito.nombre}</span>
            <DataTable value={rows} sortField={'orden'} sortMode={'single'} dataKey="producto.id" emptyMessage="No hay registros." loadingIcon={'pi pi-spinner'} rowHover onRowReorder={undefined} rows={10} paginatorRight paginatorTemplate={template.dataTableTemplate} sortOrder={1} className="p-datatable-sm" paginator globalFilter={globalFilter} header={TableHeader}  >
                {lista!.map(col =>
                    !col.hide ?
                        <Column field={col.showField ? col.showField : !col.fieldcamelCase ? col.field : col.fieldcamelCase} key={col.id} className={col.align ? 'p-text-' + col.align : 'p-text-left'} header={col.header} filterType={col.type} sortable={col.sortable} body={bodyTemplate} filter={col.filter} >
                        </Column> : null)}
            </DataTable>
        </>
    )
}

interface movimientosProps {
    empresa?: any,
    deposito?: any,
    insumo?: any,
}
