export function exportPDF(props) {
    
    import('jspdf').then(jsPDF => {
        import('jspdf-autotable').then(() => {
            const doc = new jsPDF.default(0, 0);
            var totalPagesExp = '{total_pages_count_string}'
            
            //var totalPagesExp = doc.internal.getNumberOfPages();
            
            //console.log("props", props)

            if (props.title !== ""){
                doc.setFontSize(18);
                doc.text(13, 15, props.title !== undefined ? props.title : props.name );
            } else{
                doc.setFontSize(18);
                doc.text(13, 15, props.name);
            }
            if (props.subtitle !== undefined){
                doc.setFontSize(14);
                doc.text(13, 22, props.subtitle);
            }

            //striped, grid, plain

            const estilo  = {
                theme: 'striped',
                startY: 26,
                showFoot: 'everyPage',
                pageBreak: 'auto', 
                styles: {
                    cellPadding: 1,
                    fontSize: 12
                }}
            
            //estilo.columnStyles = {0: { halign: 'right' }, 3: { halign: 'right' }}
            estilo.columnStyles = getColumsStyle(props);

            estilo.didDrawPage = function (data) {
                // doc.setFontSize(20);
                // doc.setTextColor(40);
                // //doc.setFontStyle('normal');
                // doc.text("Equipment Out On Jobs", 14, 22);
                
                // doc.setFontSize(20);
                // doc.setTextColor(40);
                // //doc.setFontStyle('normal');
                // doc.text("Job Numbers: 104319419,104319136", 14, 30);
                
                // Footer
                var str = 'Página ' + doc.getCurrentPageInfo().pageNumber;
                // Total page number plugin only available in jspdf v1.0+
                if (typeof doc.putTotalPages === 'function') {
                    str = str + " de " + totalPagesExp;
                }
                
                doc.setFontSize(10);
                doc.setTextColor(40);
                //doc.setFontStyle('normal');
                
                var pageSize = doc.internal.pageSize;
                var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
                doc.text(str, 13, pageHeight - 7);
            }

            //console.log(estilo)

            doc.autoTable(props.exportColumns, props.rows, 
                estilo );


            if (typeof doc.putTotalPages === 'function') {
                doc.putTotalPages(totalPagesExp)
            }
                
            doc.save(props.name + '.pdf');
            
        })
        
    })
    
}

function getColumsStyle(props) {
    var o = {}
    if (props.columns) {
        //console.log(props.columns)
        props.columns.forEach(element => {
            if (element.printable !== false && element.align !== undefined){
                //console.log(element.align, element.id)
                let id = element.id
                //console.log(element.header)
                let headers = Object.entries(props.exportColumns)
                headers.forEach(col => {
                    //console.log(col[1].title, col[0])
                    if (col[1].title === element.header){
                        id = col[0]
                    }
                });
                //id = Object.values(props.exportColumns).find(x=>x===element.Header)
                if (element.align === 'right'){
                    o[id] = {halign: 'right'}
                }
                if (element.align === 'center'){
                    o[id] = {halign: 'center'}
                }

            }
        });
    }
    
    return o
}