import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext'

import { InputNumber } from 'primereact/inputnumber';
import { ITableColumn } from 'utils/interfaces'
import * as func from 'utils/formDataUtils'

export default function Insumos<TLista>(props: insumosProps<TLista>) {

    const [insumos, setInsumos] = useState<TLista[]>();
    // const [insumoaplicacion, setInsumoAplicacion] = useState();
    const [previousvalue, setPreviousValue] = useState(null);

    let originalRows: any = {};

    useEffect(() => {
        //console.log("orden", props)
        if (props.values) {
            let values: TLista[] = [...props.values]
            //console.log(values)
            // if (props.hectareas) {
            //     values.map((element) => element.total = element.dosis! * props.hectareas!)
            // }
            setInsumos(values)
        } else {
            setInsumos([])
        }
        // setInsumoAplicacion(props.insumoseleccionadoaplicacion)
    }, [props.values])

    const actionBodyTemplate = (rowData: any) => {
        return (
            <div>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-text" onClick={() => remove(rowData)} />
            </div>
        );
    }


    const remove = (rowData: any) => {
        //console.log(rowData)
        let _insumos: any[] = [];
        _insumos = [...insumos!]
        //console.log(rowData.insumo.id)
        //console.log(_insumos)
        //console.log(_insumos[0].insumo.id)

        _insumos = _insumos?.filter((r) => r.insumo.id !== rowData.insumo.id)


        //console.log(_insumos)
        setInsumos(_insumos)
        props.onChanged(_insumos!)
    }

    const onEditorValueChange = (productKey: any, colProps: any, value: any, options?: any[]) => {
        //console.log(props.value)
        //console.log("change", value)
        //console.log(options)
        let _insumos = [...colProps.value];
        _insumos[colProps.rowIndex][colProps.field] = value;

        //console.log(_lotes)
        setInsumos(_insumos)
    }

    // const onRowReorder = (e: any) => {
    //     sort(e.value)
    //     //setInsumos(e.value);
    // }

    const onRowEditInit = (event: any) => {
        if (previousvalue)
            originalRows = previousvalue
        originalRows[event.index] = { ...insumos![event.index] };
        setPreviousValue(originalRows)
    }
    const onRowEditCancel = (event: any) => {
        originalRows = previousvalue
        if (insumos) {
            let _insumos = [...insumos];
            if (originalRows[event.index]) {
                _insumos[event.index] = originalRows[event.index];
                delete originalRows[event.index];
            }
            setPreviousValue(originalRows)
            setInsumos(_insumos);
        }
    }

    const onRowEditSave = (event: any) => {
        //console.log(event)
        if (!isValid(event.data)) {
            onRowEditCancel(event)
        } else {
            props.onChanged!(insumos)
        }
    }

    const isValid = (data: any) => {
        return true
    }


    const numberEdit = (fieldName: any, colProps: any, disabledEdit: boolean) => {
        return <InputNumber showButtons inputId="minmax-buttons" step={0.1} disabled={disabledEdit} value={colProps.rowData[fieldName]} mode="decimal" maxFractionDigits={3} onValueChange={(e) => onEditorValueChange(fieldName, colProps, e.value)} />
    }

    const inputText = (col: ITableColumn, colProps: any) => {
        return <InputText value={getValueFromObject(col.field, colProps.rowData)} disabled={col.disabledEdit} />
    }

    const getValueFromObject = (fieldName: string, data: any) => {
        let keys = fieldName.split('.')
        if (keys.length > 1) {
            switch (keys.length) {
                case 2:
                    return data[keys[0]][keys[1]];
                case 3:
                    return data[keys[0]][keys[1]][keys[2]];
                case 4:
                    return data[keys[0]][keys[1]][keys[2]][keys[3]]
                default:
                    return '';
            }
        } else {
            return data[keys[0]];
        }
    }

    const edit = (col: ITableColumn, colProps: any) => {
        //console.log(colProps)
        if (!col.disabledEdit) {
            switch (col.type) {
                case 'number':
                    return numberEdit(col.field, colProps, false)
                default:
                    return inputText(col, colProps)
            }
        } else {
            return inputText(col, colProps)
        }
    }
    const bodyTemplate = (data: any, colProps: any) => {
        let row = props.columns?.filter((f) => f.field === colProps.field)
        let mask = undefined;
        let type = 'string';
        //console.log(data, colProps)

        if (row?.length) {
            if (row[0].showMask) {
                mask = row[0].showMask;
            }
            if (row[0].type) {
                type = row[0].type;
            }
        }

        switch (type) {
            case 'string' || null:
                return (
                    <>
                        <span className="p-column-title">{colProps.header}</span>
                        {getValueFromObject(colProps.field, data)}
                    </>
                );

            case 'date':
                return (
                    <>
                        <span className="p-column-title">{colProps.header}</span>
                        {func.formatearFechaDMY(data[colProps.field])}
                    </>
                )
            case 'number':
                return (
                    <>
                        <span className="p-column-title">{colProps.header}</span>
                        {mask ? func.showNumber(Number(data[colProps.field]), mask) : data[colProps.field]}
                    </>
                );
            default:
                return (
                    <>
                        <span className="p-column-title">{colProps.header}</span>
                        {data[colProps.field]}
                    </>
                );
        }
    }

    return (
        <>
            <div className="card">
                <h6>Insumos</h6>
                {/* {console.log("", props.insumoseleccionadoaplicacion)} */}
                {/* onRowEditInit={onRowEditInit} onRowEditSave={onRowEditSave} onRowEditCancel={onRowEditCancel} */}
                {/* editor={(props) => edit(col, props)} body={bodyTemplate} */}
                <DataTable value={insumos} sortField={'orden'} sortMode={'single'} editMode="row" dataKey="insumo" emptyMessage="No hay registros." rowHover onRowReorder={undefined} sortOrder={1} className="p-datatable-sm" onRowEditInit={onRowEditInit} onRowEditSave={onRowEditSave} onRowEditCancel={onRowEditCancel}  >
                    {props.columns!.map(col =>
                        !col.hide ?
                            <Column field={col.showField ? col.showField : !col.fieldcamelCase ? col.field : col.fieldcamelCase} key={col.id} className={col.align ? 'p-text-' + col.align : 'p-text-left'} header={col.header} filterType={col.type} editor={(colProps) => edit(col, colProps)}
                                sortable={col.sortable} body={bodyTemplate} >
                            </Column> : null)}
                    {!props.disableEdit && <Column rowEditor header={'Editar'} headerStyle={{ width: '7rem' }} bodyStyle={{ textAlign: 'left' }} />}
                    <Column body={actionBodyTemplate} header="Eliminar" />
                </DataTable>
            </div>

        </>
    )
}

interface insumosProps<TLista> {
    columns?: ITableColumn[];
    disableEdit?: boolean;
    insumoseleccionadoaplicacion?: any;
    values?: TLista[];
    onChanged: (value: any) => void;
}