import React, { useState, useEffect } from 'react';
import { ITableColumn } from 'utils/interfaces';
import AGDropdown from 'app/components/AGDropdown'
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider'
import { Button } from 'primereact/button';

export default function SolicitarValores(props: solicitarValoresProps) {

    const [record, setRecord] = useState([]);
    const [dialog, setDialog] = useState(true);
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (loading) {
            if (props.values) {
                let _record = { ...record }
                props.columns!.forEach((element, index) => {
                    var key = element.field,
                        obj = {
                            [key]: props.values[index]
                        };
                    _record = { ..._record!, ...obj }
                });
                setRecord(_record!)
            }
            setLoading(x => !x)
        }
    })

    function changed(field: string, value: any) {
        //console.log("field record", field, record)
        var key = field,
            obj = {
                [key]: value
            };
        let _record = { ...record, ...obj }
        //console.log("solicitar valor new record", _record)
        setRecord(_record)
    }

    const hideDialog = () => {
        setDialog(false);
        props.close!();
    }

    const DialogFooter = () => {
        return (
            <>
                <>
                    <Divider />
                    <Button label="Cancelar" icon="pi pi-times" className="p-button-text  p-button-danger" onClick={hideDialog} />
                    <Button label="Aceptar" icon="pi pi-check" className="p-button-text p-button-primary" onClick={accept} />
                    <Divider />
                </>

            </>
        )
    };

    const isValid = (): boolean => {
        let _isValid = true;
        props.columns!.forEach((col) => {
            if (col.required) {
                if (!Object.values(record)[Object.keys(record).findIndex(x => x === col.field)]) {
                    _isValid = false
                }
            }
        })
        return _isValid
    }

    const accept = () => {
        setSubmitted(true);
        if (isValid()) {
            setDialog(false);
            //console.log(record);
            props.save!(record!);
        }
    }

    return (
        <>
            {/* header={HeaderTemplate} footer={DialogFooter} */}
            <Dialog className='crud-dialog' closable={true} modal closeOnEscape={true} visible={dialog} blockScroll={true} position={'right'} footer={DialogFooter} onHide={hideDialog}>
                <div className="p-fluid" >
                    {props.columns!.map((col, index) =>
                        !col.disabled ?
                            col.type === 'dropdown' ?
                                <div className="p-field" key={col.id}>
                                    <AGDropdown col={col} submitted={submitted} value={Object.values(record)[index]} onChanged={(value) => changed(col.field, value)} />
                                </div> : null : null)
                    }
                </div>
            </Dialog>
        </>
    )
}

interface solicitarValoresProps {
    columns?: ITableColumn[];
    values?: any;
    close?: (mensaje?: string) => void;
    save?: (values: any) => void;
}