import { actorCreacionDTO } from "actores/actores.model";
import { peliculaCreacionDTO } from "peliculas/peliculas.model";

// import { perfilusuarioDTO } from 'app/dto/usuarios.model'


// export function GetUser(): perfilusuarioDTO {
//     const entitiesContext = useContext(EntitiesContext);
//     return entitiesContext.user
// }

export function convertirActorAFormData(actor: actorCreacionDTO): FormData {
    const formData = new FormData();

    formData.append('nombre', actor.nombre);
    if (actor.biografia) {
        formData.append('biografia', actor.biografia);
    }

    if (actor.fechaNacimiento) {
        formData.append("fechaNacimiento", formatearFecha(actor.fechaNacimiento));
    }

    if (actor.foto) {
        formData.append("foto", actor.foto);
    }

    return formData;
}

export function convertirPeliculaAFormData(pelicula: peliculaCreacionDTO): FormData {
    const formData = new FormData();

    formData.append('titulo', pelicula.titulo);

    if (pelicula.resumen) {
        formData.append('resumen', pelicula.resumen);
    }

    formData.append('trailer', pelicula.trailer);
    formData.append('enCines', String(pelicula.enCines));
    if (pelicula.fechaLanzamiento) {
        formData.append("fechaLanzamiento", formatearFecha(pelicula.fechaLanzamiento));
    }

    if (pelicula.poster) {
        formData.append('poster', pelicula.poster);
    }

    formData.append("generosIds", JSON.stringify(pelicula.generosIds));
    formData.append("cinesIds", JSON.stringify(pelicula.cinesIds));
    formData.append("actores", JSON.stringify(pelicula.actores));

    return formData;
}

export function formatearFecha(date: Date) {
    date = new Date(date);
    const formato = new Intl.DateTimeFormat("en", {
        year: 'numeric',
        month: '2-digit',
        day: "2-digit"
    });

    const [
        { value: month }, ,
        { value: day }, ,
        { value: year }
    ] = formato.formatToParts(date);

    return `${year}-${month}-${day}`;
}
export function formatearFechaDMY(date: Date) {
    date = new Date(date);
    const formato = new Intl.DateTimeFormat("es", {
        day: "2-digit",
        month: '2-digit',
        year: 'numeric'
    });

    const [
        { value: day }, ,
        { value: month }, ,
        { value: year }
    ] = formato.formatToParts(date);

    return `${day}/${month}/${year}`;
}

export function isValidCUITCUIL(cuit: String): Boolean {
    console.log("CUIT", cuit)
    if (cuit.length !== 13 && cuit.length !== 11) return false;

    let rv = false;
    let resultado = 0;
    let cuit_nro = cuit.replace("-", "");
    const codes = "6789456789";
    let verificador = parseInt(cuit_nro[cuit_nro.length - 1]);
    let x = 0;

    while (x < 10) {
        let digitoValidador = parseInt(codes.substring(x, x + 1));
        if (isNaN(digitoValidador)) digitoValidador = 0;
        let digito = parseInt(cuit_nro.substring(x, x + 1));
        if (isNaN(digito)) digito = 0;
        let digitoValidacion = digitoValidador * digito;
        resultado += digitoValidacion;
        x++;
    }
    resultado = resultado % 11;
    rv = (resultado === verificador);
    return rv;


}

export function showNumber(n: number, mask?: string, format?: boolean): string {
    if (format === undefined) format = false;
    if (!mask) {
        mask = "###.###.###.##0,00"
    } else {
        switch (mask) {
            case '#.##0,00':
                mask = "###.###.###.###.##0,00"
                break;
            case '#.##0,000':
                mask = "###.###.###.###.##0,000"
                break;
            case '#.##0':
                mask = "###.###.###.###.##0"
                break;
            default:
                break;
        }
    };

    if (format || n !== 0) {
        let dec: number = 0;
        let point: number = 0;
        let x = mask.indexOf(",") + 1;
        if (x) { dec = mask.length - x; }

        let ns: string;

        if (dec !== 0) {
            ns = n.toString();
            x = ns.indexOf(".") + 1;
            if (x !== 0) { point = ns.length - x; ns = ns.replace(".", ","); } else { ns += ","; }
        } else {
            ns = n.toString();
        }
        //alert(dec + ' ' + ns)
        for (let x: number = point; x < dec; x++) {
            ns += "0";
        }
        //alert(ns)
        let XMASK: string;

        x = ns.length;
        let y: number = mask.length;
        XMASK = "";
        while (x || y) {
            if (x) {
                while (y && "#0,".indexOf(mask.charAt(y - 1)) === -1) {
                    if (ns.charAt(x - 1) !== "-")
                        XMASK = mask.charAt(y - 1) + XMASK;
                    y--;
                }
                XMASK = ns.charAt(x - 1) + XMASK
                x--
            } else if (y && "$0".indexOf(mask.charAt(y - 1)) + 1) {
                XMASK = mask.charAt(y - 1) + XMASK;
            }
            if (y) { y-- }
        }
        return XMASK;
    } else {
        return n.toString();
    }
    // if (form) {
    //     form.value = XMASK;
    //     if (NUM(n) < 0) {
    //         form.style.color = "#FF0000";
    //     } else {
    //         form.style.color = "#000000";
    //     }
    // }

}

export function formatNumber(x: number, posiciones = 0) {
    var s = x.toString()
    var l = s.length
    var decimalLength = s.indexOf('.') + 1

    if (l - decimalLength <= posiciones) {
        return x
    }
    // Parte decimal del número
    var isNeg = x < 0
    var decimal = x % 1
    var entera = isNeg ? Math.ceil(x) : Math.floor(x)
    // Parte decimal como número entero
    // Ejemplo: parte decimal = 0.77
    // decimalFormated = 0.77 * (10^posiciones)
    // si posiciones es 2 ==> 0.77 * 100
    // si posiciones es 3 ==> 0.77 * 1000
    var decimalFormated = Math.floor(
        Math.abs(decimal) * Math.pow(10, posiciones)
    )
    // Sustraemos del número original la parte decimal
    // y le sumamos la parte decimal que hemos formateado
    var finalNum = entera +
        ((decimalFormated / Math.pow(10, posiciones)) * (isNeg ? -1 : 1))

    return finalNum
}

const getValueFromObject = (fieldName: string, data: any) => {
    let keys = fieldName.split('.')
    if (keys.length > 1) {
        switch (keys.length) {
            case 2:
                return data[keys[0]][keys[1]];
            case 3:
                return data[keys[0]][keys[1]][keys[2]];
            case 4:
                return data[keys[0]][keys[1]][keys[2]][keys[3]]
            default:
                return '';
        }
    } else {
        return data[keys[0]];
    }
}

export function bodyTemplate(data: any, colProps: any, props: any) {

    let cols = props.columns?.filter((f: any) => f.field === colProps.field)
    let mask = undefined;
    let type = 'string';
    //console.log("formDataUtils bodyTemplate data ", data)
    //console.log("formDataUtils bodyTemplate colProps ", colProps)
    //console.log("formDataUtils bodyTemplate props ", props)
    //console.log("formDataUtils bodyTemplate row", row)

    if (cols?.length) {
        if (cols[0].showMask) {
            mask = cols[0].showMask;
        }
        if (cols[0].type) {
            type = cols[0].type;
        }
    }

    //console.log("formDataUtils bodyTemplate type", type)

    switch (type) {
        case 'string' || null:
            return getValueFromObject(colProps.field, data)
        case 'estado':
            return getValueFromObject(colProps.field, data)
        case 'date':
            return formatearFechaDMY(data[colProps.field])
        case 'number':
            return mask ? showNumber(Number(data[colProps.field]), mask) : data[colProps.field]
        case 'boolean':
            return getValueFromObject(colProps.field, data)

        default:
            return data[colProps.field]
    }
}

export function traducirRole(role: string) {
    switch (role) {
        case "admin":
            return "Administrador"
        case "owner":
            return "Propietario"
        case "user":
            return "Usuario básico"
        case "god":
            return "Completo"
        default:
            return role;
    }
}



