import { MapContainer, Marker, Popup, TileLayer, useMapEvent, useMap } from "react-leaflet"
import L from 'leaflet'
import icon from 'leaflet/dist/images/marker-icon.png'
import iconShadow from 'leaflet/dist/images/marker-shadow.png'
import 'leaflet/dist/leaflet.css';
import { coordenadaDTO } from './coordenadas.model'
import { useState } from "react";

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconAnchor: [16, 37]
});

L.Marker.prototype.options.icon = DefaultIcon;



export default function Mapa(props: mapaProps) {

    const [coordenadas, setCoordenadas] = useState<coordenadaDTO[]>(props.coordenadas!)

    function MyComponente(props: any) {
        const mapa = useMap()
        mapa.setView([props.lat, props.lng], props.zoom!)
        return null
    }

    //console.log("MM", Mapa.prototype)

    return (
        <MapContainer
            zoom={props.zoom}
            attributionControl={true}
            center={[props.coordenada!.lat, props.coordenada!.lng]}
            style={{ height: props.height }}
        >   <MyComponente lat={props.coordenada!.lat} lng={props.coordenada!.lng} zoom={props.zoom} />
            <TileLayer attribution="AgroControl"
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {props.soloLectura ?
                <>
                    <Marcador key={props.coordenada!.lat + props.coordenada!.lng}
                        {...props.coordenada!}
                    />

                </> : <ClickMapa setPunto={coordenadas => {
                    setCoordenadas([coordenadas]);
                    props.manejarClickMapa(coordenadas);
                }} />}

            {props.soloLectura || !coordenadas ? null : coordenadas.map(coordenada => <Marcador key={coordenada.lat + coordenada.lng}
                {...coordenada}
            />)}

        </MapContainer>
    )
}

interface mapaProps {
    height: string;
    coordenadas?: coordenadaDTO[];
    coordenada?: coordenadaDTO;
    manejarClickMapa(coordenadas: coordenadaDTO): void;
    soloLectura: boolean;
    zoom?: number;
}

Mapa.defaultProps = {
    zoom: 1,
    height: '600px',
    soloLectura: false,
    coordenada: { lat: -31.427438777130778, lng: -62.08448981788615 },
    manejarClickMapa: () => { }
}

function ClickMapa(props: clickMapaProps) {
    useMapEvent('click', e => {
        props.setPunto({ lat: e.latlng.lat, lng: e.latlng.lng })
    })
    return null;
}

interface clickMapaProps {
    setPunto(coordenadas: coordenadaDTO): void;
}

function Marcador(props: coordenadaDTO) {
    return (
        <>
            <Marker position={[props.lat, props.lng]}>
                {props.nombre ? <Popup>
                    {props.nombre}
                </Popup> : null}
            </Marker>
        </>
    )
}

