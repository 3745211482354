const apiURL = process.env.REACT_APP_API_URL;

export const urlGeneros = `${apiURL}/generos`
export const urlActores = `${apiURL}/actores`
export const urlCines = `${apiURL}/cines`
export const urlPeliculas = `${apiURL}/peliculas`
export const urlCuentas = `${apiURL}/cuentas`
export const urlRatings = `${apiURL}/rating`;
export const urlCampañas = `${apiURL}/campañas`

export const urlContactos = `${apiURL}/contactos`
export const urlContactosActivos = `${apiURL}/contactos/activos`

export const urlUsuarios = `${apiURL}/usuarios`
export const urlUsuarioFromEMail = `${apiURL}/usuarios/fromemail`
export const urlUsuarioFromUserId = `${apiURL}/usuarios/fromid`
export const urlUsuarioPermisos = `${apiURL}/usuarios/permisos`

export const urlCampañasActivos = `${apiURL}/campañas/activos`
export const urlCampañasEmpresas = `${apiURL}/campañas/empresas`
export const urlCampañasLotes = `${apiURL}/campañas/lotes`
export const urlEstablecimientos = `${apiURL}/establecimientos`
export const urlEstablecimientosActivos = `${apiURL}/establecimientos/activos`
export const urlEstablecimientosConLotes = `${apiURL}/establecimientos/conlotes`
export const urlLotes = `${apiURL}/lotes`
export const urlLotesEstablecimiento = `${apiURL}/lotes/establecimiento`
export const urlLoteAsociado = `${apiURL}/lotes/exist`
export const urlLotesAsociar = `${apiURL}/lotes/asociar`
export const urlLotesEspecies = `${apiURL}/lotes/especies`
export const urlLotesEnCampaña = `${apiURL}/lotes/encampaña`
export const urlLotesAsociados = `${apiURL}/lotes/asociados`
export const urlLabores = `${apiURL}/labores`
export const urlEspecies = `${apiURL}/especies`
export const urlEspeciesActivos = `${apiURL}/especies/activos`
export const urlEmpresas = `${apiURL}/empresas`
export const urlEmpresasActivos = `${apiURL}/empresas/activos`
export const urlUnidades = `${apiURL}/unidades`
export const urlUnidadesActivos = `${apiURL}/unidades/activos`
export const urlEstadios = `${apiURL}/estadios`
export const urlEstadiosInsumoExist = `${apiURL}/estadios/exist`
export const urlEstadiosByEspecie = `${apiURL}/estadios/especie`
export const urlDepositos = `${apiURL}/depositos`
export const urlDepositosActivos = `${apiURL}/depositos/activos`
export const urlDepositosEmpresa = `${apiURL}/depositos/empresa`
export const urlInsumos = `${apiURL}/insumos`
export const urlInsumosActivos = `${apiURL}/insumos/activos`

export const urlOrdenDeTrabajo = `${apiURL}/ordenesdetrabajo`
export const urlOrdenDeTrabajoFiltrado = `${apiURL}/ordenesdetrabajo/filtrado`
export const urlOrdenDeTrabajoEstado = `${apiURL}/ordenesdetrabajo/estado`

export const urlInsumosAplicacion = `${apiURL}/insumosaplicacion`
export const urlInsumosAplicacionExist = `${apiURL}/insumosaplicacion/exist`
export const urlInsumosAplicacionByInsumo = `${apiURL}/insumosaplicacion/insumo`
export const urlInsumoEspecieAplicacion = `${apiURL}/insumosaplicacion/insumoespecie`

export const urlGenericEntities = `${apiURL}/genericentities`
export const urlGenericEntitiesSetup = `${apiURL}/genericentities/setup`
export const urlGenericEntitiesRows = `${apiURL}/genericentitiesrows`


export const urlStockInventario = `${apiURL}/stock/inventario`
export const urlStockTransferencia = `${apiURL}/stock/transferencia`
export const urlStockBaja = `${apiURL}/stock/baja`
export const urlStockCompra = `${apiURL}/stock/compra`
export const urlStockMovimientos = `${apiURL}/stock/movimientos`

export const urlStock = `${apiURL}/stock`
export const urlStockDetalleInsumo = `${apiURL}/stock/detalleinsumo`

