import { Dialog } from 'primereact/dialog';
import { useState, useEffect } from 'react'
import Cargando from "utils/Cargando";
import { genericentityDTO } from "app/dto/genericentities.model"
import { Divider } from 'primereact/divider'
import { GenericService } from 'app/service/GenericService';
import { urlGenericEntities } from "app/service/Endpoints"
import { genericEntityFieldSetupDTO } from 'app/dto/genericentityfieldsetup.model'
import { TRecordField } from 'utils/interfaces'
import EntitySetupLoadRecordsCRUD from 'app/general/EntitySetupLoadRecordsCRUD'

export default function EntitySetupLoadRecords(props: entitySetupLoadRecordsProps) {
    const [dialog, setDialog] = useState(true)
    const [entity, setEntity] = useState<genericentityDTO>()
    const [loading, setLoading] = useState(true)
    const [fields, setFields] = useState<TRecordField[]>()

    const service = new GenericService();

    useEffect(() => {
        if (loading)
            if (props.id) {
                setLoading((x) => !x)
                //alert(props.id)
                service.getId({ url: urlGenericEntities, id: props.id }).then(response => {
                    if (response.success) {
                        //setErrores([]);
                        //console.log("get id", props.id)
                        setEntity(response.data);
                        service.getId({ url: urlGenericEntities, subOne: 'setup', id: props.id }).then(response => {
                            if (response.success) {
                                //console.log("get setup", response.data)
                                load(response.data)
                                //alert("Cargado u set loading false")
                            } else {
                                setLoading(false)
                            }
                        });


                    }
                    else {
                        //props.close!(response.error);
                    }
                }).catch(error => {
                    //alert("catch")
                    setLoading((x) => !x)
                    console.log(error);
                    // if (props.updateMode) {
                    //     props.close!();
                    // }
                })
            };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const load = (data: genericEntityFieldSetupDTO[]) => {
        if (data) {
            let newField: TRecordField;
            let newFields: TRecordField[];
            //console.log(data)
            data.forEach((element, index) => {
                //console.log(element)
                newField = {
                    fieldId: element.fieldId!,
                    fieldName: element.fieldName,
                    fieldType: element.fieldType,
                    fieldSize: element.fieldSize,
                }

                if (!newFields) {
                    newFields = [newField]
                } else {
                    newFields.push(newField)
                }
            });
            //console.log(newFields!)
            setFields(newFields!)
        }
    }


    const HeaderTemplate = () => {
        return (
            <div >
                {entity ?
                    "Entidad " + entity.name + " carga de registros."
                    : null}
            </div>

        )
    }

    const DialogFooter = () => {
        return (
            <>
                <Divider />
                {/* <Button label="Cancelar" icon="pi pi-times" className="p-button-text  p-button-danger" onClick={hideDialog} />
                <Button label="Guardar" icon="pi pi-check" className="p-button-text p-button-primary" onClick={save} /> */}
            </>
        )
    };


    return (
        <>

            {loading ? <Cargando /> :
                <Dialog className='crud-dialog' closable={true} modal closeOnEscape={true} visible={dialog} blockScroll={true} position={'right'} header={HeaderTemplate} footer={DialogFooter} onHide={() => { props.close!(); setDialog(false) }} >
                    <EntitySetupLoadRecordsCRUD title={'Registros'} id={props.id} columns={fields} />
                </Dialog>}

        </>
    )
}

interface entitySetupLoadRecordsProps {
    id: number,
    close?: (mensaje?: string) => void;
}