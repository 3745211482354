import { useState, useContext } from 'react';
import 'primeflex/primeflex.css';
import { CrudUsuarios } from 'app/general/EntidadesGestion'
import EntitiesContext from 'context/EntitiesContext'
import { perfilusuarioDTO } from 'app/dto/usuarios.model';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip'
import { Toolbar } from 'primereact/toolbar';
import AutenticacionContext from 'auth/AutenticacionContext';
import PerfilPermisos from './PerfilPermisos';


export default function Perfil() {

    const { user, setUserData } = useContext(EntitiesContext)
    // const [usuario, setUsuario] = useState<perfilusuarioDTO>();
    const [dialog, setDialog] = useState(false);
    const { claims } = useContext(AutenticacionContext);

    // useEffect(() => {
    //     //console.log("leyendo")
    //     setUsuario(user)
    // }, [user])

    const getEmail = () => {
        if (user) {
            return { email: user.email }
        }
    }

    const save = (row: any) => {
        console.log("row", row)
        let userData: perfilusuarioDTO = {
            id: row.id,
            aspnetuserid: user.aspnetuserid,
            email: user.email,
            nombres: row.nombres,
            apellido: row.apellido,
            domicilio: row.domicilio,
            provincia: row.provincia,
            departamento: row.departamento,
            localidad: row.localidad,
            telefonos: row.telefonos,
        }
        setUserData(userData)
        setDialog(false)
    }

    const toolbarLeftTemplate = () => {
        return (
            <>
                <Button label="Editar" icon="pi pi-plus" onClick={() => setDialog(true)} style={{ marginRight: '.5em' }} />
            </>
        )
    };
    const traducir = (role: string) => {
        switch (role) {
            case "admins":
                return "Administradores"
            case "admin":
                return "Administrador"
            case "owner":
                return "Propietario"
            case "user":
                return "Usuario"
            default:
                break;
        }
    }

    const Roles = () => {
        let indice = claims.findIndex(claim => claim.nombre === 'role')
        if (indice < 0) {
            return (<Chip label={'Visitante'} className="mr-2" />)
        } else {
            let roles = claims.filter(claim => claim.nombre === 'role')
            return (
                <>
                    {roles.map(x => <Chip label={traducir(x.valor)} key={x.valor} className="mr-2" />)}
                </>
            )
        }
    }

    return (
        <>
            <div className="grid">
                <div className="col-12">
                    <div className="card" >


                        <div className="surface-section">
                            {/* <div className="font-medium text-2xl text-900 mb-3">Perfil del usuario</div> */}
                            <h5>Perfil del usuario</h5>
                            <Toolbar left={toolbarLeftTemplate}></Toolbar>
                            <br />
                            {/* <div className="text-500 mb-5">Morbi tristique blandit turpis. In viverra ligula id nulla hendrerit rutrum.</div> */}
                            <ul className="list-none p-0 m-0">
                                <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                    <div className="text-500 w-6 md:w-2 font-medium">Usuario</div>
                                    <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{user?.email}</div>
                                </li>

                                <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                    <div className="text-500 w-6 md:w-2 font-medium">Apellido</div>
                                    <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{user?.apellido}</div>
                                </li>
                                <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                    <div className="text-500 w-6 md:w-2 font-medium">Nombres</div>
                                    <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{user?.nombres}</div>
                                </li>
                                <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                    <div className="text-500 w-6 md:w-2 font-medium">Domicilio</div>
                                    <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                                        <Chip label={user?.localidad} className="mr-2" />
                                        <Chip label={user?.departamento} className="mr-2" />
                                        <Chip label={user?.provincia} />
                                    </div>
                                </li>
                                <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                    <div className="text-500 w-6 md:w-2 font-medium">Teléfonos</div>
                                    <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{user?.telefonos}</div>
                                </li>
                                <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                    <div className="text-500 w-6 md:w-2 font-medium">Rol</div>
                                    <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                                        <Roles />
                                    </div>
                                </li>

                            </ul>
                        </div>
                        <div className="surface-section">
                            <PerfilPermisos />
                        </div>



                        <small></small>
                    </div>
                </div>
            </div>

            {dialog && <CrudUsuarios modalMode={true} updateMode={true} param={getEmail()} close={() => setDialog(false)} save={save} />}
        </>
    )
}
