import { ReactElement, useContext, useEffect, useState, useRef } from "react";
import AutenticacionContext from "./AutenticacionContext";
import EntitiesContext from 'context/EntitiesContext'
import NoAutorizado from "NoAutorizado";
import { Divider } from "primereact/divider";
import { TRole } from 'utils/interfaces'
import { traducirRole } from 'utils/formDataUtils'

export default function Autorizado(props: autorizadoProps) {
    const [estaAutorizado, setEstaAutorizado] = useState(false);
    const { claims } = useContext(AutenticacionContext);
    const [display, setDisplay] = useState<ReactElement>();
    const entitiesContext = useContext(EntitiesContext);

    const accessDenied = useRef(false)

    //console.log(claims)
    useEffect(() => {
        if (claims) {
            let user = claims.find(claim => claim.nombre === 'user')
            let roles = claims.filter(claim => claim.nombre === 'role')


            if (user?.valor) {
                //console.log(roles)
                const { nombres } = user?.valor
                setDisplay((
                    <>
                        <h5 style={{ color: 'red' }} ><i className="pi pi-lock p-mr-2"></i>Acceso denegado</h5>
                        <Divider />
                        <b>{nombres}</b>, no tienes acceso a utilizar {props.resource ? <b>{props.resource}</b> : <>este recurso.</>}
                        <br />
                        {props.role ? <p>Se requiere nivel de acceso <b>{traducirRole(props.role!)}</b></p> : ""}
                        {roles
                            ?
                            roles.map((r) => {
                                return <p>Tu nivel de acceso es <b>{traducirRole(r.valor)}</b>.<br />Solicite a un usuario autorizado un mayor nivel de acceso, o acceso al recurso deseado.</p>
                            })

                            : <p>No se puede acceder al nivel de acceso.</p>
                        }
                    </>
                ))
            }

            //console.log(entitiesContext.user)

            if (entitiesContext.user) {
                //console.log(entitiesContext.user.permisos)
                let proceso = entitiesContext.user.permisos?.find(x => x.recurso.toLowerCase() === props.resource?.toString().toLowerCase())
                //console.log("Proceso", proceso)
                if (proceso) {
                    if (proceso.consultar === false) {
                        accessDenied.current = true
                    }
                }
            }

            //console.log(props.resource)
            //console.log(roles.length)
            if (accessDenied.current === false) {
                if (props.role) {
                    let indice = claims.findIndex(claim =>
                        claim.nombre === 'role' && claim.valor === props.role)
                    if (indice < 0) {
                        indice = claims.findIndex(claim =>
                            claim.nombre === 'role' && claim.valor === 'owner')
                    }
                    setEstaAutorizado(indice > -1);
                    //setEstaAutorizado(true);
                } else {
                    //no se han cargado los claims

                    if (props.resource === 'topbar' && user) {
                        setEstaAutorizado(true)
                    } else {
                        if (roles.length === 0) {
                            setEstaAutorizado(false)
                        } else {
                            setEstaAutorizado(claims.length > 0);
                        }

                    }
                    //setEstaAutorizado(false)

                    //setEstaAutorizado(false)
                }
            }
        }
    }, [claims, entitiesContext.user, props.resource, props.role])

    return (
        <>
            {estaAutorizado ? props.autorizado : props.noAutorizado ? props.noAutorizado : <NoAutorizado display={display!} />}
        </>
    )
}

interface autorizadoProps {
    autorizado: ReactElement;
    noAutorizado?: ReactElement;
    resource?: string;
    role?: TRole;
}