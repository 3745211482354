import { useEffect, useState } from 'react';
import { Route } from 'react-router';
import { BrowserRouter, Redirect } from 'react-router-dom';
import configurarValidaciones from './validaciones'
import AutenticacionContext from './auth/AutenticacionContext'
import EntitiesState from 'context/EntitiesState'
import { claim } from 'auth/auth.model';
import { obtenerClaims } from 'auth/manejadorJWT';
import { configurarInterceptor } from 'utils/interceptores';
import { AppTopbar } from './AppTopBar'
import classNames from 'classnames';
import { AppMenu } from './AppMenu'
import { Login } from './auth/Login'
import { Registro } from './auth/Registro'
import PrimeReact from 'primereact/api';
import { AppConfig } from './AppConfig'
import { addLocale } from 'primereact/api';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import {
  CrudCampaña,
  CrudEstablecimientos,
  CrudEspecies,
  CrudEmpresas,
  CrudUnidades,
  CrudEstadios,
  CrudDepositos,
  CrudGenericEntities,
  CrudInsumos,
  CrudInsumoAplicacion,
  CrudLotes,
  CrudLotesAsociados,
} from 'app/general/Entidades'

import {
  CrudLabores,
  CrudContactos,
  CrudUsuarios
} from 'app/general/EntidadesGestion'

import { logout } from 'auth/manejadorJWT';
import { confirmDialog } from 'primereact/confirmdialog'; // To use confirmDialog method
import Perfil from 'app/usuarios/Perfil'
import LandingPage from 'app/home/LandingPage';

import OrdenDeTrabajo from 'app/ordenes/OrdenDeTrabajo'
import OrdenesDeTrabajo from 'app/ordenes/OrdenesDeTrabajo';
import Stock from 'app/stock/Stock'



//import 'primereact/resources/primereact.min.css';
// import 'primereact/resources/primereact.css';
// import 'primeflex/primeflex.css';


import './layout/layout.scss';
// import './App.css';
import './styles.css'


configurarValidaciones();
configurarInterceptor();

function App() {

  const [claims, setClaims] = useState<claim[]>([]);
  const [layoutMode, setLayoutMode] = useState('static');
  const [layoutColorMode, setLayoutColorMode] = useState('light')
  const [inputStyle, setInputStyle] = useState('filled');
  const [ripple, setRipple] = useState(true);
  const [staticMenuInactive, setStaticMenuInactive] = useState(false);
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
  const [redirect, setRedirect] = useState(false)


  let menuClick = false;
  let mobileTopbarMenuClick = false;

  PrimeReact.ripple = true;

  addLocale('es', {
    firstDayOfWeek: "1",
    dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
    dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
    dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
    monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
    monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
    today: 'Hoy',
    clear: 'Limpiar'
  });

  const addClass = (element: any, className: any) => {
    if (element.classList)
      element.classList.add(className);
    else
      element.className += ' ' + className;
  }

  const removeClass = (element: any, className: any) => {
    if (element.classList)
      element.classList.remove(className);
    else
      element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
  }

  useEffect(() => {

    let _claims: claim[] = []
    _claims = obtenerClaims()

    setRedirect(false)

    //console.log("obtener claims ", _claims)

    setClaims(_claims);

    if (mobileMenuActive) {
      addClass(document.body, "body-overflow-hidden");
    } else {
      removeClass(document.body, "body-overflow-hidden");
    }

    if (_claims.length === 0 && layoutMode === 'static') {
      setStaticMenuInactive(true)
    }

  }, [layoutMode, mobileMenuActive])

  const isDesktop = () => {
    return window.innerWidth >= 992;
  }

  const onToggleMenuClick = (event: any) => {

    let _claims: claim[] = []
    if (claims.length === 0) {
      _claims = obtenerClaims()
      //console.log("obtener claims ", _claims)
      setClaims(_claims);
    }

    menuClick = true;

    if (isDesktop()) {
      if (layoutMode === 'overlay') {
        if (mobileMenuActive === true) {
          setOverlayMenuActive(true);
        }
        setOverlayMenuActive((prevState) => !prevState);
        setMobileMenuActive(false);
      }
      else if (layoutMode === 'static') {
        if (claims.length !== 0 || _claims.length !== 0) {
          setStaticMenuInactive((prevState) => !prevState);
        } else {
          setStaticMenuInactive(true);
        }

      }
    }
    else {
      setMobileMenuActive((prevState) => !prevState);
    }
    event.preventDefault();
  }

  const onMenuItemClick = (event: any) => {
    setRedirect(false)
    if (!event.item.items) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }
  }

  const logoutConfirm = () => {
    confirmDialog({
      message: 'Confirma cerrar sesión?',
      header: 'Cerrar sesión',
      icon: 'pi pi-question-circle',
      rejectLabel: "Sí",
      rejectIcon: 'pi pi-check',
      acceptClassName: 'p-button-rounded p-button-success p-button-text',
      rejectClassName: 'p-button-rounded p-button-danger p-button-text',
      acceptIcon: 'pi pi-times',
      acceptLabel: "No",
      reject: () => {
        logout();
        actualizar([]);
        if (layoutMode === 'static') {
          setStaticMenuInactive(true)
        }
        setRedirect(true)
      },
      accept: () => null
    });
  }


  function actualizar(claims: claim[]) {
    setClaims(claims);
  }

  const onSidebarClick = () => {
    menuClick = true;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function esAdmin() {
    //console.log(claims.findIndex(claim => claim.nombre === 'role' && claim.valor === 'admin') > -1)
    return claims.findIndex(claim => claim.nombre === 'role' && claim.valor === 'admin') > -1;

  }

  // function esOwner() {
  //   return claims.findIndex(claim => claim.nombre === 'role' && claim.valor === 'owner') > -1;
  // }

  const onMobileTopbarMenuClick = (event: any) => {
    mobileTopbarMenuClick = true;

    setMobileTopbarMenuActive((prevState) => !prevState);
    event.preventDefault();
  }

  const onMobileSubTopbarMenuClick = (event: any) => {
    mobileTopbarMenuClick = true;

    event.preventDefault();
  }

  const onWrapperClick = (event: any) => {

    //console.log(event)
    if (!menuClick) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }

    if (!mobileTopbarMenuClick) {
      setMobileTopbarMenuActive(false);
    }

    mobileTopbarMenuClick = false;
    menuClick = false;
  }

  const menu = [
    // {
    //   label: 'Home',
    //   items: [{
    //     label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/'

    //   }]
    // },
    {
      label: 'Gestión', icon: 'pi pi-fw pi-clone',
      items: [
        { label: 'Órdenes de trabajo', icon: 'pi pi-fw pi-book', to: '/ordenesdetrabajo' },
        { label: 'Stock', icon: 'pi pi-fw pi-list', to: '/stock' },
      ]
    },

    {
      label: 'Principal', icon: 'pi pi-fw pi-sitemap',
      items: [
        { label: 'Empresas', icon: 'pi pi-fw pi-briefcase', to: '/empresas' },
        { label: 'Depósitos', icon: 'pi pi-fw pi-building', to: '/depositos' },
        { label: 'Establecimientos', icon: 'pi pi-fw pi-home', to: '/establecimientos' },
        { label: 'Lotes', icon: 'pi pi-fw pi-map-marker', to: '/lotes' },
        { label: 'Campañas', icon: 'pi pi-fw pi-calendar', to: '/campañas' },
        { label: 'Lotes Asociados', icon: 'pi pi-fw pi-map-marker', to: '/lotesasociados' },
      ]
    },


    {
      label: 'Generales', icon: 'pi pi-fw pi-sitemap',
      items: [

        { label: 'Especies', icon: 'pi pi-fw pi-cog', to: '/especies' },

        { label: 'Insumos', icon: 'pi pi-fw pi-cog', to: '/insumos' },
        { label: 'Insumo aplicación', icon: 'pi pi-fw pi-cog', to: '/insumoaplicacion' },



        { label: 'Contactos', icon: 'pi pi-fw pi-users', to: '/contactos' },
        { label: 'Labores', icon: 'pi pi-fw pi-briefcase', to: '/labores' },

      ]
    },
    {
      label: 'Configurar', icon: 'pi pi-fw pi-sitemap',
      items: [
        { label: 'Usuarios', icon: 'pi pi-fw pi-cog', to: '/usuarios' },
        { label: 'Estadios', icon: 'pi pi-fw pi-cog', to: '/estadios' },
        { label: 'Unidades', icon: 'pi pi-fw pi-cog', to: '/unidades' },
        { label: 'Entidades genéricas', icon: 'pi pi-fw pi-cog', to: '/genericentities' },
      ]
    },
    {
      label: 'Usuario',
      items: [
        //   command: () => {
        //     toast.current.show({ severity: 'success', summary: 'Updated', detail: 'Data Updated', life: 3000 });
        // }

        { label: 'Perfil', icon: 'pi pi-user-edit', to: '/perfil' },
        { label: 'Cerrar sesión', icon: 'pi pi-power-off', command: () => logoutConfirm() },
      ]
    },

  ];

  const wrapperClass = classNames('layout-wrapper', {
    'layout-overlay': layoutMode === 'overlay',
    'layout-static': layoutMode === 'static',
    'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
    'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
    'layout-mobile-sidebar-active': mobileMenuActive,
    'p-input-filled': inputStyle === 'filled',
    'p-ripple-disabled': ripple === false,
    'layout-theme-light': layoutColorMode === 'light',
    'layout-theme-dark': layoutColorMode === 'dark'
  });

  const onInputStyleChange = (inputStyle: string) => {
    setInputStyle(inputStyle);
  }

  const onRipple = (e: any) => {
    PrimeReact.ripple = e.value;
    setRipple(e.value)
  }

  const onLayoutModeChange = (mode: string) => {
    setLayoutMode(mode)
  }

  const onColorModeChange = (mode: string) => {
    setLayoutColorMode(mode)
  }


  return (
    <>
      <BrowserRouter>
        <div className={wrapperClass} onClick={onWrapperClick}>

          <EntitiesState>
            <AutenticacionContext.Provider value={{ claims, actualizar }}>

              <AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode}
                mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} onLogoutClick={logoutConfirm} />

              {claims.length !== 0 ?
                <div className="layout-sidebar" onClick={onSidebarClick}>
                  <AppMenu model={menu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
                </div> : null
              }

              {/* layout-main-container */}
              <div className="layout-main-container">

                {/* <Route path="/" exact component={Campaña} />
                <Route path="*" exact component={Campaña} /> */}

                <Route path="/especies" component={CrudEspecies} />
                <Route path="/unidades" component={CrudUnidades} />
                <Route path="/establecimientos" component={CrudEstablecimientos} />
                <Route path="/lotes" component={CrudLotes} />
                <Route path="/campañas" component={CrudCampaña} />
                <Route path="/lotesasociados" component={CrudLotesAsociados} />
                <Route path="/contactos" component={CrudContactos} />
                <Route path="/depositos" component={CrudDepositos} />
                <Route path="/labores" component={CrudLabores} />
                <Route path="/empresas" component={CrudEmpresas} />
                <Route path="/estadios" component={CrudEstadios} />
                <Route path="/insumos" component={CrudInsumos} />
                <Route path="/insumoaplicacion" component={CrudInsumoAplicacion} />
                {claims.length !== 0 ? <Route path="/" component={OrdenesDeTrabajo} exact /> : <Route path="/" children={<LandingPage onToggleMenuClick={onToggleMenuClick} />} exact />}
                {/* <Route path="/" component={LandingPage} exact /> */}

                <Route path="/ordenesdetrabajo" component={OrdenesDeTrabajo} />
                <Route path="/ordendetrabajo" component={OrdenDeTrabajo} />
                <Route path="/stock" component={Stock} />

                <Route path="/perfil" component={Perfil} />
                <Route path="/usuarios" component={CrudUsuarios} />

                {/* <Route path="/fieldsetup" component={FieldSetup} /> */}

                <Route path="/login" component={Login} />
                <Route path="/registro" component={Registro} />
                {/* <Route path="/usuarios" component={IndiceUsuarios} /> */}
                <Route path="/genericentities" component={CrudGenericEntities} />

                {/* <Switch> */}
                {/* {rutas.map(ruta =>
                  <Route key={ruta.path} path={ruta.path}
                    exact={ruta.exact}>
                    {ruta.esAdmin && esAdmin() ? <>
                      No tiene permiso para acceder a este componente
                    </> : <ruta.componente />}
                  </Route>)} */}
                {/* </Switch> */}

                {/* <AppFooter layoutColorMode={layoutColorMode} /> */}
              </div>

              <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange}
                layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />


              {/* <ModalExample /> */}


              {/* <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
              <div className="layout-mask p-component-overlay"></div>
            </CSSTransition> */}
              {redirect && <Redirect to={"/"} />}

            </AutenticacionContext.Provider>
          </EntitiesState>

        </div>

      </BrowserRouter>

    </>


  );
}

export default App;
