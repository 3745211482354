import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber'
import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column'
import { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown'
import { TRecordField } from 'utils/interfaces'

export default function FieldSetup(props: fieldSetupProps) {
    const [valueFieldName, setValueFieldName] = useState('')
    const [valueFieldType, setValueFieldType] = useState('')
    const [valueFieldSize, setValueFieldSize] = useState<number>(0)
    const [fieldSetupFieldSizeDisable, setFieldSetupFieldSizeDisable] = useState(true)
    const [submitted, setSubmitted] = useState(false)

    const [element, setElement] = useState('')
    const [elementFocus, setElementFocus] = useState('')

    const [error, setError] = useState('')

    const [records, setRecords] = useState<TRecordField[]>()

    useEffect(() => {
        if (element !== '') {
            document.getElementById(element)?.focus()
            setElement('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        //console.log("rows loading", props.rowsLoading)
        // if (!records && props.rowsLoading)
        setRecords(props.rowsLoading!)
    }, [props.rowsLoading])

    const renderFooter = () => {
        return (
            <div>
                <Button label="Aceptar" icon="pi pi-check" onClick={() => { setError(''); setElement(elementFocus) }} autoFocus />
            </div>
        );
    }

    const options = [
        { label: 'string', value: 'string' },
        { label: 'number', value: 'number' },
        { label: 'date', value: 'date' },
    ]

    const isValid = () => {
        if (valueFieldName && valueFieldType) {
            if (records?.find((k) => k.fieldName === valueFieldName)) {
                setElementFocus((x) => 'fieldSetupValueFieldName')
                setError("Ya existe el valor " + valueFieldName)
                setSubmitted(false)
                return false
            }

            switch (valueFieldType) {
                case 'string':
                    if (valueFieldSize === 0) {
                        setElementFocus((x) => 'fieldSetupValueFieldSize')
                        //elementFocus = "fieldSetupValueFieldSize"
                        setError('Debe indicar el tamaño del campo')
                        return false
                    }
                    break;
                default:
                    break;
            }
            return true
        }
        return false
    }


    const add = () => {
        setSubmitted(true)

        if (!isValid()) return

        //console.log(valueFieldType)
        //let _records: any[]

        let newRecord: TRecordField = { fieldName: valueFieldName, fieldType: valueFieldType, fieldSize: valueFieldSize }
        //console.log(records)
        //console.log(newRecord)
        //setRecords([newRecord])
        //setRecords([...records!, newRecord])

        setSubmitted((x) => !x)
        setValueFieldName('')
        setValueFieldType('')
        setValueFieldSize(0)
        if (records) {
            let _records = [...records, newRecord]
            console.log(_records)
            setRecords(_records)
            props.rowsChanged!(_records)
            //console.log(_records)
        } else {
            let _records = newRecord
            setRecords([_records])
            props.rowsChanged!([_records])
        }
        setElement("fieldSetupValueFieldName")
    }

    const remove = (rowData: any) => {
        // //console.log(rowData)
        let _records = records?.filter((r) => r.fieldName !== rowData.fieldName)
        setRecords(_records)
        props.rowsChanged!(_records!)
    }

    const actionBodyTemplate = (rowData: any) => {
        return (
            <div>
                <Button icon="pi pi-times" className="p-button-rounded p-button-danger p-button-text" onClick={() => remove(rowData)} />
            </div>
        );
    }

    const bodyTemplate = (data: any, props: any) => {
        //console.log(props.filterType, data)
        //console.log("fields", props.field)
        switch (props.filterType) {
            case 'string' || null:
                return (
                    <>
                        {data[props.field]}
                    </>
                );
        }
    }

    const bodyTemplateType = (data: any, props: any) => {
        //console.log(data)
        return (
            <>
                {data ? options.filter((e) => e.value === data[props.field])[0].label : null}
            </>
        );
    }

    const inputTextEditor = (productKey: string, props: any, field: string) => {
        return <InputText type="text" value={props.rowData[field]} onChange={(e) => onEditorValueChange(productKey, props, e.target.value)} />;
    }

    const onEditorValueChange = (productKey: string, props: any, value: any) => {

        let updatedRecords = [...props.value];
        //console.log(value)
        updatedRecords[props.rowIndex][props.field] = value;
        //console.log(updatedRecords)
        setRecords(updatedRecords)
        // dataTableFuncMap[`${productKey}`](updatedProducts);
    }

    const nameEdit = (productKey: string, props: any) => {
        //console.log(props)
        return inputTextEditor(productKey, props, 'fieldName');
    }

    return (
        <>
            <Dialog modal header="Atención" visible={error !== ''} style={{ width: '20vw' }} footer={renderFooter} onHide={() => { setError(''); setElement(elementFocus) }}>
                <p>{error}</p>
            </Dialog>
            <div className='p-grid' >
                <div className="col-12 ml-2">
                    <h6>{props.title!.toLocaleUpperCase()}</h6>
                </div>
                <div className="p-inputgroup col-12">
                    <div className="col-4">
                        <span className="p-float-label ">
                            <InputText id="fieldSetupValueFieldName" autoFocus={true} value={valueFieldName} onChange={(e) => setValueFieldName(e.target.value)} className={classNames({ 'p-invalid': submitted && !valueFieldName })}
                                onKeyPress={(e) => {
                                    if ((e.code === 'Enter' || e.code === 'NumpadEnter') && valueFieldName)
                                        setElement("fieldSetupValueFieldType")
                                }}
                            />
                            <label htmlFor="fieldSetupValueFieldName">Nombre</label>
                        </span>
                        {submitted && !valueFieldName && <small className="p-invalid">El nombre es requerido</small>}
                    </div>


                    <div className="col-4">
                        <span className="p-float-label">
                            <Dropdown inputId="fieldSetupValueFieldType" showOnFocus showClear options={options!} value={valueFieldType} onChange={(e) => {
                                setValueFieldType(e.target.value);

                                switch (e.target.value) {
                                    case 'string': //string
                                        setFieldSetupFieldSizeDisable(false);
                                        //setElement("fieldSetupValueFieldSize")
                                        break;

                                    default:
                                        setFieldSetupFieldSizeDisable(true);
                                        break;
                                }

                            }} className={classNames({ 'p-invalid': submitted && !valueFieldType })}
                            />
                            <label htmlFor="fieldSetupValueFieldType">Tipo</label>
                        </span>
                        {submitted && !valueFieldType && <small className="p-invalid">El valor es requerido</small>}
                    </div>

                    <div className="col-2">
                        <span className="p-float-label">
                            <InputNumber disabled={fieldSetupFieldSizeDisable} inputId="fieldSetupValueFieldSize" value={valueFieldSize} mode="decimal" onValueChange={(e) => setValueFieldSize(e.value)} className={classNames({ 'p-invalid': submitted && !valueFieldSize })} onKeyDown={(e) => {
                                if ((e.code === 'Enter' || e.code === 'NumpadEnter') && valueFieldName && valueFieldType)
                                    add()
                            }}
                            />
                            <label htmlFor="fieldSetupValueFieldSize">Tamaño</label>
                        </span>
                        {submitted && !valueFieldType && <small className="p-invalid">El valor es requerido</small>}
                    </div>

                    <div className="col-1">
                        <Button label="Agregar" icon="pi pi-plus" onClick={() => add()} />
                    </div>
                </div>
                {/* p-button-rounded p-button-danger p-button-text */}
                <div className="col-12 ml-2">
                    <DataTable value={records!} resizableColumns editMode="cell" columnResizeMode="fit" className="editable-cells-table" rowHover
                        emptyMessage={'No hay ' + props.title!}>
                        <Column field={'fieldName'} key={'fieldName'} header={'Nombre'} filterType={'string'}
                            body={bodyTemplate} editor={(props) => nameEdit('fieldName', props)} onEditorSubmit={() => props.rowsChanged!(records!)} />
                        <Column field={'fieldType'} key={'fieldType'} header={'Tipo'} body={bodyTemplateType} />
                        <Column field={'fieldSize'} key={'fieldSize'} header={'Tamaño'} filterType={'string'}
                            body={bodyTemplate} />
                        <Column body={actionBodyTemplate} header="Acciones" />
                    </DataTable>
                </div>
            </div>
        </>
    )
}

interface fieldSetupProps {
    title?: string, //nombre de la entidad que esta configurando
    rowsLoading?: TRecordField[], //registros en la base de datos actuales
    //envía al padre los datos de campos y filas cada vez que se agrega/modifica/elimina
    rowsChanged?: (records: TRecordField[]) => void,
}

FieldSetup.defaultProps = {
    title: "Campos",
}

