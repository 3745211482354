import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column'
import { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Dialog } from 'primereact/dialog';
import { TRecord } from 'utils/interfaces'


export default function KeysValue(props: keysValuesProps) {
    const [valueKeyName, setValueKeyName] = useState('')
    const [valueValue, setValueValue] = useState('')
    const [submitted, setSubmitted] = useState(false)

    const [element, setElement] = useState('')
    const [error, setError] = useState(false)

    const [records, setRecords] = useState<TRecord[]>([])

    const [loading, setLoading] = useState(true)

    const [editingCellRows, setEditingCellRows] = useState<TRecord[]>([]);


    useEffect(() => {
        if (element !== '') {
            document.getElementById(element)?.focus()
            setElement('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [element]);

    useEffect(() => {
        //console.log("keysvalues: loading & props.value", loading, props.value)
        if ((loading && props.value) || (!loading && props.value !== records)) {
            //console.log("KeysValues, cargar... ", props.value)
            //console.log("records", records)
            // props.value.forEach(element => {
            //     setValueKeyName(Object.values(element['key']).toString())
            //     setValueValue(Object.values(element['value']).toString())
            // });
            setRecords(props.value!)
            setLoading(false)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, props.value])

    // useEffect(() => {
    //     //debugger
    //     if (!loading && props.reset) {
    //         setLoading(false)
    //         props.onReset!()
    //     }
    // }, [props.reset])

    const renderFooter = () => {
        return (
            <div>
                <Button label="Aceptar" icon="pi pi-check" onClick={() => { setError(false); setElement("keysValuesKey") }} autoFocus />
            </div>
        );
    }




    const add = () => {
        setSubmitted(true)
        if (valueKeyName && valueValue) {
            if (records?.find((k) => k.key === valueKeyName)) {
                setError(true)
                return
            }

            let newRecord: TRecord = { key: valueKeyName, value: valueValue }
            //console.log(records)
            //console.log(newRecord)
            //setRecords([newRecord])
            //setRecords([...records!, newRecord])

            setSubmitted((x) => !x)
            setValueKeyName('')
            setValueValue('')
            if (records) {
                let _records = [...records, newRecord]
                setRecords(_records)
                props.rowsChanged!(_records!)
                console.log(_records)
            } else {
                setRecords([newRecord])
                props.rowsChanged!([newRecord])
            }
            setElement("keysValuesKey")
        }
    }
    const remove = (rowData: any) => {
        //console.log(rowData)
        let _records = records?.filter((r) => r.key !== rowData.key)
        setRecords(_records)
        props.rowsChanged!(_records!)
    }


    const actionBodyTemplate = (rowData: any) => {
        return (
            <div>
                <Button icon="pi pi-times" className="p-button-rounded p-button-danger p-button-text" onClick={() => remove(rowData)} />
            </div>
        );
    }

    const bodyTemplate = (data: any, props: any) => {
        //console.log(props.filterField, data)
        switch (props.filterType) {
            case 'string' || null:
                return (
                    <>
                        {data[props.field]}
                    </>
                );
        }
    }

    const onEditorInit = (e: any) => {
        const { rowIndex: index, field, rowData } = e.columnProps;
        let _editingCellRows: any[];
        _editingCellRows = [...editingCellRows];
        if (!editingCellRows[index]) {
            _editingCellRows[index] = { ...rowData };
        }
        if (!records) {
            _editingCellRows[index][field] = records[index][field]
        }
        setEditingCellRows(_editingCellRows!);
    };

    const onEditorCancel = (e: any) => {
        const { rowIndex: index, field } = e.columnProps;
        let _editingCellRows: any[] = [];
        let _records = [...records!];
        _editingCellRows = [...editingCellRows];
        if (!_editingCellRows) {
            records[index][field] = _editingCellRows[index][field];
        }
        delete _editingCellRows[index][field];
        setEditingCellRows(_editingCellRows);
        setRecords(_records);
    };

    const onEditorSubmit = (e: any) => {
        const { rowIndex: index, field } = e.columnProps;
        let _editingCellRows = [...editingCellRows];
        delete _editingCellRows[index][field];
        setEditingCellRows(_editingCellRows);
    };

    const onEditorValueChange = (productKey: any, props: any, value: any) => {
        let _updated = [...props.value];
        _updated[props.rowIndex][props.field] = value;
        setRecords(_updated)
        //dataTableFuncMap[`${productKey}`](_updated);
    }

    const inputTextEditor = (productKey: any, props: any, field: any) => {
        return <InputText className="col-2" type="text" value={props.rowData[field]} onChange={(e) => onEditorValueChange(productKey, props, e.target.value)} />;
    }


    return (
        <>
            <Dialog modal header="Atención" visible={error} style={{ width: '20vw' }} footer={renderFooter} onHide={() => setError(false)}>
                <p>Ya se encuentra cargado el nombre de {props.title!}</p>
            </Dialog>
            <div className="grid" >
                <div className="col-12 ml-2">
                    <h6>{props.title!.toLocaleUpperCase()}</h6>
                </div>
                <div className="p-fluid p-formgrid p-grid p-col-12">
                    <div className="p-field p-col-12 p-md-4">
                        <span className="p-float-label ">
                            <InputText id="keysValuesKey" value={valueKeyName} onChange={(e) => setValueKeyName(e.target.value)} className={classNames({ 'p-invalid': submitted && !valueKeyName })}
                                onKeyPress={(e) => {
                                    if ((e.code === 'Enter' || e.code === 'NumpadEnter') && valueKeyName) setElement("keysValuesValue")
                                }}
                            />
                            <label htmlFor="keysValuesKey">Nombre</label>
                        </span>
                        {submitted && !valueKeyName && <small className="p-invalid">El nombre es requerido</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <span className="p-float-label">
                            <InputText id="keysValuesValue" value={valueValue} onChange={(e) => setValueValue(e.target.value)} className={classNames({ 'p-invalid': submitted && !valueValue })} onKeyPress={(e) => {
                                if ((e.code === 'Enter' || e.code === 'NumpadEnter') && valueKeyName && valueValue)
                                    add()
                            }}
                            />
                            <label htmlFor="keysValuesValue">Valor</label>
                        </span>
                        {submitted && !valueValue && <small className="p-invalid">El valor es requerido</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <span>
                            <Button label="Agregar" icon="pi pi-plus" onClick={() => add()} />
                        </span>
                    </div>
                </div>

                {/* p-button-rounded p-button-danger p-button-text */}
                <div className="col-10 ml-2">
                    <DataTable value={records!} resizableColumns columnResizeMode="fit" editMode="cell" className="p-datatable-sm  editable-cells-table" rowHover emptyMessage={'No hay ' + props.title!}>
                        <Column field={'key'} key={'key'} header={'Nombre'} filterType={'string'}
                            body={bodyTemplate} editor={(props) => inputTextEditor('records', props, 'key')} onEditorInit={onEditorInit} onEditorCancel={onEditorCancel} onEditorSubmit={onEditorSubmit} />
                        <Column field={'value'} key={'value'} header={'Valor'} filterType={'string'}
                            body={bodyTemplate} editor={(props) => inputTextEditor('records', props, 'value')} onEditorInit={onEditorInit} onEditorCancel={onEditorCancel} onEditorSubmit={onEditorSubmit} />
                        <Column body={actionBodyTemplate} header="Acciones" />
                    </DataTable>
                </div>
            </div>
        </>
    )
}

interface keysValuesProps {
    title?: string,
    value?: TRecord[],
    reset?: boolean,
    onReset?: () => void;
    rowsChanged?: (records: TRecord[]) => void,
}

KeysValue.defaultProps = {
    title: "Propiedades",
}

