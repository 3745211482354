import axios from "axios";
import React, { useState, useContext } from 'react';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { classNames } from 'primereact/utils';
import AutenticacionContext from "./AutenticacionContext";
import { credencialesUsuario } from "./auth.model";
import { guardarTokenLocalStorage, obtenerClaims } from "./manejadorJWT";
import { urlCuentas } from "utils/endpoints";
import { useHistory } from "react-router-dom";
import MostrarErrores from "../utils/MostrarErrores"

import './Login.css';

export const Login = (props: { login?: () => void }) => {
    const history = useHistory();
    const { actualizar } = useContext(AutenticacionContext);
    const [showMessage, setShowMessage] = useState(false);
    const [errores, setErrores] = useState<string[]>([]);

    async function login(credenciales: credencialesUsuario) {
        try {
            const respuesta = await axios.post(`${urlCuentas}/login`, credenciales);
            //console.log(respuesta.data)
            guardarTokenLocalStorage(respuesta.data);
            actualizar(obtenerClaims());
            formik.resetForm();
            if (props.login) {
                props.login();
            } else {
                history.push("/ordenesdetrabajo");
            }

        }
        catch (error: any) {
            //console.log(error.message)
            setErrores(error.response ? error.response.data : [error.message]);
        }
    }

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',

        },
        validate: (data) => {
            let errors = { email: '', password: '' };
            let error: boolean = false;

            if (!data.email) {
                errors.email = 'Debe ingresar el correo.';
                error = true;
            }
            else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
                errors.email = 'Debe ingresar un correo válido Ej. example@email.com';
                error = true;
            }

            if (!data.password) {
                errors.password = 'Debe indicar la contraseña.';
                error = true;
            }

            if (error)
                return errors;
        },
        onSubmit: (data) => {
            login(data);
        },
    });

    //const isFormFieldValid = (name: string) => !!(formik.touched[name] && formik.errors[name]);
    const isFormFieldValid = (name: string) => !!(formik.getFieldMeta(name).touched && formik.getFieldMeta(name).error);
    const getFormErrorMessage = (name: string) => {
        // return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
        return isFormFieldValid(name) && <small className="p-error">{formik.getFieldMeta(name).error}</small>;
    };

    const dialogFooter = <div className="p-d-flex p-jc-center"><Button label="OK" className="p-button-text" autoFocus onClick={() => setShowMessage(false)} /></div>;
    const passwordHeader = <h6>Indicar una contraseña</h6>;
    const passwordFooter = (
        <React.Fragment>
            <Divider />
            <p className="p-mt-2">Sugerencias</p>
            <ul className="p-pl-2 p-ml-2 p-mt-0" style={{ lineHeight: '1.5' }}>
                <li>At least one lowercase</li>
                <li>At least one uppercase</li>
                <li>At least one numeric</li>
                <li>Minimum 8 characters</li>
            </ul>
        </React.Fragment>
    );

    return (
        <>
            <Dialog visible={showMessage} onHide={() => setShowMessage(false)} position="top" footer={dialogFooter} showHeader={false} breakpoints={{ '960px': '180vw' }} style={{ width: '30vw' }}>
                <div className="p-d-flex p-ai-center p-dir-col p-pt-6 p-px-3">
                    <i className="pi pi-check-circle" style={{ fontSize: '5rem', color: 'var(--green-500)' }}></i>
                    <h5>Registration Successful!</h5>
                </div>
            </Dialog>
            <div className="p-fluid col-12 p-mx-auto p-mt-3">
                <h6>Iniciar sesión</h6>
                <form onSubmit={formik.handleSubmit} >
                    <div className="p-field p-mt-5">
                        <span className="p-float-label p-input-icon-right">
                            <i className="pi pi-envelope" />
                            <InputText id="email" name="email" value={formik.values.email} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('email') })} autoFocus={true} />
                            <label htmlFor="email" className={classNames({ 'p-error': isFormFieldValid('email') })}>Email*</label>
                        </span>
                        {getFormErrorMessage('email')}
                    </div>
                    <div className="p-field p-mt-5">
                        <span className="p-float-label">
                            <Password id="password" name="password" value={formik.values.password} onChange={formik.handleChange} toggleMask
                                className={classNames({ 'p-invalid': isFormFieldValid('password') })} header={passwordHeader} footer={passwordFooter} feedback={false} weakLabel="Débil" mediumLabel="Regular" strongLabel="Fuerte" />
                            <label htmlFor="password" className={classNames({ 'p-error': isFormFieldValid('password') })}>Contraseña*</label>
                        </span>
                        {getFormErrorMessage('password')}
                    </div>
                    <div className="p-grid p-justify-end">
                        <Button style={{ width: "100px" }} type="submit" label="Iniciar" className="p-button-success p-col-2 p-mr-2 p-mt-2" />
                    </div>
                </form>

                <MostrarErrores errores={errores} />
            </div>

        </>
    );
}
