import { useRef, useState, useEffect, ReactNode, useContext } from 'react';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { GenericService } from 'app/service/GenericService';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { InputSwitch } from 'primereact/inputswitch';
import { Checkbox } from 'primereact/checkbox'
import { confirmDelete } from 'utils/Confirmar'
import { ITableColumn, ICustomAttributes, TComponentName, IDefaultColumn, IValidateAddNew, TRole } from 'utils/interfaces'
import * as func from 'utils/formDataUtils'
import { exportPDF } from 'app/service/exportPDF';
import CU from './CU'
import Modal from 'app/general/Modal'
import EntitiesContext from 'context/EntitiesContext'
import MostrarErrores from "utils/MostrarErrores";
import Autorizado from 'auth/Autorizado'
import * as userControl from 'auth/UserDataControl'
import * as template from 'app/general/DataTableTemplate'
import AutenticacionContext from 'auth/AutenticacionContext';

//import { exportCSV } from './service/exportCSV'

export default function RD<TEntidad, TEntidadCreacion>(props: rdInterfaceProps<TEntidad, TEntidadCreacion>) {

    const [row, setRow] = useState<TEntidadCreacion>(props.emptyRow);

    const { claims } = useContext(AutenticacionContext)

    const [rows, setRows] = useState<TEntidad[]>();

    const [dialog, setDialog] = useState(false);

    const [loading, setLoading] = useState(true);
    const [globalFilter, setGlobalFilter] = useState('');
    const toast = useRef<Toast>(null);
    const [editando, setEditando] = useState(false);

    const [refresh, setRefresh] = useState(false);

    const [showComponent, setShowComponent] = useState<TComponentName>()
    const [showComponentId, setShowComponentId] = useState<TComponentName>()
    const [showIsOpen, setShowIsOpen] = useState(false)

    const [defaultcolumn, setDefaultColumn] = useState<IDefaultColumn[]>()
    const [componentadjunt, setComponentAdjunt] = useState<TComponentName>()
    const [componentadjuntid, setComponentAdjuntId] = useState(0)

    const entitiesContext = useContext(EntitiesContext);

    const [errores, setErrores] = useState<string[]>([]);

    const service = new GenericService();

    useEffect(() => {
        //let entities: IGenericEntity[] = [];
        let entities = entitiesContext.entities;
        //let user = entitiesContext.user;
        //console.log("entidades", entities)
        if (props.columns!) {
            props.columns.forEach(element => {
                if (element.optionsFromEndPoint !== '' && element.optionsEntityId !== undefined) {
                    let entity = entities.find(e => e.name === element.field)
                    //console.log("entidad ", entity)
                    if (!entity) {
                        //console.log("Cargar", element.field, element.optionsEntityId)
                        entitiesContext.getEntity({ id: element.optionsEntityId, name: element.field, loaded: false, labelfield: element.optionName, valuefield: element.optionValue })
                    }
                }
            });
            if (entities.length) {
                //console.log(entities[0].id)
                //console.log(entitiesContext.getValue(entities[0].id, 3))
                //entitiesContext.getEntities()
            }

        }
    }, [props.columns])

    useEffect(() => {
        let roles = claims.filter(claim => claim.nombre === 'role')
        if (refresh) {
            toast.current!.show({ severity: 'success', detail: 'Leyendo datos...', life: 1000 });
            setRefresh(false)
        }
        if (roles.length !== 0) {
            service.get({ url: props.url, creacion: row }).then(response => {
                //console.log("data", data)
                //console.log(JSON.parse(data[3]['condiciones']))
                if (response.success) {
                    setRows(response.data);
                    setLoading(false)
                } else {
                    setErrores(response.error)
                    setLoading(false)
                }
            })
        } else {
            setLoading(false)
        }
        //console.log("rows", rows)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refresh]);

    useEffect(() => {
        //console.log("props.refreshComponent", props.refreshComponent)
        if (props.refreshComponent && !refresh) {
            setRefresh(true)
        }
    }, [props.refreshComponent])

    const openNew = () => {
        if (userControl.ProcesoHabilitado({ user: entitiesContext.user, resource: props.customAttibutes?.pluralEntity, process: 'agregar', showMessage: true })) {
            setEditando(false);
            setDialog(true)
        }
    }

    const editarEntidad = (rowData: TEntidadCreacion) => {
        //console.log(rowData)
        if (userControl.ProcesoHabilitado({ user: entitiesContext.user, resource: props.customAttibutes?.pluralEntity, process: 'editar', showMessage: true })) {
            if (props.customAttibutes?.onEdit) {
                props.customAttibutes?.onEdit(rowData)
                //console.log("Refresh")
            } else {
                setRow(rowData)
                setEditando(true);
                setDialog(true);
            }
        }
    }

    const hideDialog = () => {
        //setSubmitted(false);
        setDialog(false);
        //setEditando(false);
    }

    const updateRowsAfterSave = (row: any) => {
        //console.log("updateRows . editando " + editando, row)
        let idKey = idIndex() //indice del campo primario
        let _rows = [...rows!];
        if (editando) {
            toast.current!.show({ severity: 'success', summary: props.nombreEntidad, detail: 'Registro modificado', life: 2000 })
            let values = Object.values(row); //valores de la fila que se modifica
            const index = findIndexById(values[idKey]);
            _rows[index] = row;
            setRows(_rows);
        } else {
            toast.current!.show({ severity: 'success', summary: props.nombreEntidad, detail: 'Registro creado', life: 2000 })
            _rows = [...rows!, row];
            setRows(_rows);
        }
        setDialog(false);
    }

    const acceptDelete = (event: any) => {
        //alert(props.url)

        service.delete({ url: props.url, id: event.id, creacion: event }).then(data => {
            //alert(data)
            if (!data) {
                toast.current!.show({ severity: 'success', summary: 'Proceso finalizado', detail: 'Eliminar registro Id ' + event.id, life: 2000 })
                let _rows = [...rows!].filter(x => x !== event)
                setRows(_rows)
            } else {
                let men: ReactNode = <div>No se pudo eliminar el registro.<br />{data}</div>
                toast.current!.show({ severity: 'error', summary: 'Error', detail: men, life: 3000 });
            }
        })

    };

    const exportColumns = props.columns!.filter(col => col.printable !== false).map(col => ({ title: col.header, dataKey: col.header }));

    const exportPdf = () => {
        const exportRows: any[] = [];
        //console.log(rows)

        rows!.forEach(element => {

            let o = {}
            props.columns!.forEach((col, index) => {
                let value = Object.values(element)[index]
                if (col.showField) {
                    if (col.showField.includes('.') === true) {
                        let data = col.showField.split(".")
                        //console.log(data.length)
                        switch (data.length) {
                            case 2:
                                let object = data[0];
                                let property = data[1];
                                value = Object.values(element)[Object.keys(element).findIndex(x => x === object)][property]
                                break;
                            default:
                                break;
                        }
                    } else {
                        value = Object.values(element)[Object.keys(element).findIndex(x => x === col.showField)]
                    }
                }

                if (col.type === 'date') {
                    value = func.formatearFechaDMY(value)
                } else {
                    value = col.showMask ? func.showNumber(Number(value), col.showMask) : value
                }



                if (col.printable !== false) {
                    var key = col.header,
                        obj = {
                            [key]: value
                        };
                    o = { ...o, ...obj }
                }
            });
            //console.log(o)
            exportRows.push(o)


            // exportRows.push({ Id: element.producto.id, Insumo: element.producto.nombre, Presentación: element.producto.presentacion, Stock: element.stock, Unidad: element.producto.unidad })
        });
        //console.log(exportColumns)
        //exportPDF({ exportColumns: exportColumns, rows: rows, name: props.nombreEntidad })
        exportPDF({ exportColumns: exportColumns, rows: exportRows, name: props.nombreEntidad, title: props.customAttibutes?.pluralEntity, subtitle: undefined, columns: props.columns })
    }

    const dt = useRef(null);

    // const exportCSV = (selectionOnly: boolean) => {
    // }

    const toolbarLeftTemplate = () => {
        return (
            <>
                {!props.customAttibutes?.addDisable && <Button label="Agregar" icon="pi pi-plus" onClick={() => openNew()} style={{ marginRight: '.5em' }} />}

                <Button icon="pi pi-refresh" onClick={() => setRefresh(true)} className="p-button-success" style={{ marginRight: '.5em' }} tooltip="Actualizar datos" tooltipOptions={{ position: 'bottom' }} />
                {/* <Button icon="pi pi-file-o" onClick={() => exportCSV(false)} className="p-mr-2" data-pr-tooltip="CSV" style={{ marginRight: '.5em' }} /> */}
                {!props.customAttibutes?.exportPDFDisable && <Button icon="pi pi-file-pdf" onClick={exportPdf} className="p-button-warning p-mr-2" data-pr-tooltip="PDF" style={{ marginRight: '.5em' }} />}

            </>
        )
    };

    const TableHeader = (
        <div className="table-header">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText style={{ width: '300px' }} className="" value={globalFilter} onChange={(e) => { setGlobalFilter(e.target.value); console.log(e.target.value) }} placeholder="Buscar" />
            </span>
        </div>
    );

    const getGenericEntityValue = (fieldName: string, data: any) => {
        //console.log("getGenericEntityValue", fieldName)
        let col = props.columns?.find(c => c.field === fieldName)
        //console.log(col)
        if (col) {
            if (col.optionsShowValue) {
                //console.log("leer", fieldName, col.optionsFromEndPoint, col.optionsEntityId, data[fieldName])
                return entitiesContext.getValue(col.optionsEntityId!, data[fieldName])
            }
        }
        return data[fieldName]
    }

    const bodyTemplate = (data: any, colProps: any) => {
        //console.log(props, data)

        //const { showMask } = props.columns?.filter((f) => f.field === colProps.field)[0]
        let row = props.columns?.filter((f) => f.field === colProps.field)
        let mask = undefined;

        if (row?.length) {
            if (row[0].showMask) {
                mask = row[0].showMask;
            }
        }

        if (String(colProps.field).includes('.')) {
            let entity = String(colProps.field).split('.')
            //console.log(data[entity[0]])
            if (data[entity[0]] !== null) {
                let keys = Object.keys(data[entity[0]])
                let value: string;
                keys.forEach((element, index) => {
                    //console.log(element, entity[1])
                    if (element === entity[1]) {
                        let values: string[] = Object.values(data[entity[0]])
                        //console.log(values[index])
                        value = values[index]
                    }
                });
                if (value! !== '')
                    return (value!)
            }
        }

        switch (colProps.filterType) {
            case 'string' || null:
                return (
                    <>
                        <span className="p-column-title">{colProps.header}</span>
                        {data[colProps.field]}
                    </>
                );
            case 'dropdown':
                return (
                    <>
                        <span className="p-column-title">{colProps.header}</span>
                        {/* {data[props.field]} */}
                        {getGenericEntityValue(colProps.field, data)}
                    </>
                );

            case 'date':
                return (
                    <>
                        <span className="p-column-title">{colProps.header}</span>
                        {func.formatearFechaDMY(data[colProps.field])}
                    </>
                )
            case 'boolean':
                return (
                    <>
                        <span className="p-column-title">{colProps.header}</span>
                        {
                            data[colProps.field] ? <Checkbox checked={true} />
                                : <Checkbox checked={false} />
                        }
                    </>
                )
            case 'switch':
                return (
                    <>
                        <span className="p-column-title">{colProps.header}</span>
                        {
                            data[colProps.field] ? <InputSwitch checked={true} onChange={() => {
                                if (userControl.ProcesoHabilitado({ user: entitiesContext.user, resource: props.customAttibutes?.pluralEntity, process: 'activar', showMessage: true })) {
                                    switchActive(data, colProps.field)
                                }
                            }} />
                                : <InputSwitch checked={false} onChange={() => {
                                    if (userControl.ProcesoHabilitado({ user: entitiesContext.user, resource: props.customAttibutes?.pluralEntity, process: 'activar', showMessage: true })) {
                                        switchActive(data, colProps.field)
                                    }
                                }} />
                        }
                    </>
                )
            case 'location':
                return (
                    <>
                        <span className="p-column-title">{colProps.header}</span>
                        {data[colProps.field]}
                    </>
                );
            case 'number':
                return (
                    <>
                        <span className="p-column-title">{colProps.header}</span>
                        {mask ? func.showNumber(Number(data[colProps.field]), mask) : data[colProps.field]}
                    </>
                );
            default:
                return (
                    <>
                        <span className="p-column-title">{colProps.header}</span>
                        {data[colProps.field]}
                    </>
                );
        }
    };

    const switchActive = (rowData: TEntidad, field: string) => {
        let _rows = [...rows!];
        let _rowData = { ...rowData };
        let currentValue = false
        let idKey = idIndex()
        //console.log(rowData)
        Object.keys(_rowData).forEach((key, index) => {
            if (key === field) {
                //alert(Object.values(_rowData)[index])
                currentValue = Object.values(_rowData)[index]
            }
        })
        var key = field,
            obj = {
                [key]: !currentValue
            };

        _rowData = { ...rowData, ...obj }
        let id = Object.values(_rowData)[idKey]
        //console.log("id:" + id, _rowData)
        service.put({ url: props.url, id: id, creacion: _rowData }).then(data => {
            //toast.current!.show({ severity: 'success', summary: 'Proceso finalizado', detail: 'Campaña modificada', life: 2000 });
            const index = findIndexById(id);
            _rows[index] = _rowData
            setRows(_rows);
        }).catch(error => console.log(error));        //alert(data.target.data.id)
    }


    const findIndexById = (id: any) => {
        //alert(id)
        let idKey = idIndex()
        let index = -1;
        if (rows) {
            for (let i = 0; i < rows.length; i++) {
                let values = Object.values(rows[i])
                if (values[idKey] === id) {
                    index = i;
                    break;
                }
            }
        }
        //alert(id + ' ' + index)
        return index;
    }


    const idIndex = (): number => {
        let col = props.columns!.filter((col) => col.isKey === true)
        return col[0].id
    }


    const getValue = (row: any, field: string) => {
        //console.log(field)
        //return Object.values(row)[idColByName(field)]
        let keys = Object.keys(row)
        let value: any;
        //console.log(keys)
        //console.log(field)
        keys.forEach((e, index) => {
            if (e === field) {
                value = Object.values(row)[index]
            }
        });
        return value;
    }

    const showTarget = (component: TComponentName, rowData: any, defaults: IDefaultColumn[]) => {

        //carga los valores por defecto que se cargarán en los controles del formulario destino
        let id = getValue(rowData, props.dataKey)

        if (defaults) {
            defaults.forEach(element => {
                element.value = rowData[element.fieldsource]
                if (element.componentadjunt) {
                    setComponentAdjunt(element.componentadjunt)
                    setComponentAdjuntId(getValue(rowData, element.fieldsource))
                }
            });
            setDefaultColumn(defaults)
        }

        //un componente que se adjuntará al formulario actual.


        setShowComponent(component);
        setShowComponentId(id);
    }

    const actionBodyTemplate = (rowData: TEntidadCreacion) => {
        return (
            <div>

                {!props.customAttibutes?.editDisable ?
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-button-circle" onClick={() => editarEntidad(rowData)} style={{ height: 25, width: 25 }} /> : null}
                <span> </span>
                {!props.customAttibutes?.deleteDisable ?
                    <Button icon="pi pi-times" className="p-button-rounded p-button-danger p-button-circle" onClick={() => {
                        if (userControl.ProcesoHabilitado({ user: entitiesContext.user, resource: props.customAttibutes?.pluralEntity, process: 'eliminar', showMessage: true })) {
                            confirmDelete(rowData, acceptDelete)
                        }
                    }} style={{ height: 25, width: 25 }} /> : null}
                <span> </span>

                {/* { console.log(getValue(rowData, props.dataKey)) } */}

                {props.customAttibutes?.buttons ?
                    props.customAttibutes?.buttons?.map((b, index) => {
                        return (
                            <Button key={index} icon={b.icon} className={b.className} style={b.style} onClick={() => {
                                if (b.validateAccess !== true || userControl.ProcesoHabilitado({ user: entitiesContext.user, resource: props.customAttibutes?.pluralEntity, process: 'editar', showMessage: true })) {
                                    if (b.onClick) {
                                        b.onClick(rowData);
                                    } else {
                                        showTarget(b.loadComponent!, rowData, b.defaults!)
                                    }
                                }
                            }} />
                        )
                    }) : null
                }

            </div>
        );
    }

    return (
        <Autorizado role={props.role} resource={props.customAttibutes?.pluralEntity ? props.customAttibutes?.pluralEntity : props.nombreEntidad} autorizado={
            <>
                <div className="grid">
                    <div className="col-12">
                        <div className="card" >
                            <h5>{props.customAttibutes?.pluralEntity ? props.customAttibutes?.pluralEntity : props.nombreEntidad}</h5>
                            <Toolbar left={toolbarLeftTemplate}></Toolbar>
                            <Toast ref={toast} />
                            <br />
                            <DataTable ref={dt} value={rows!} resizableColumns columnResizeMode="fit" paginator={props.paginator} className="p-datatable-sm" rows={10} paginatorRight paginatorTemplate={template.dataTableTemplate}
                                rowHover sortMode={'multiple'} stripedRows loadingIcon={'pi pi-spinner'}
                                globalFilter={globalFilter} emptyMessage="No hay registros." loading={loading} header={TableHeader}>
                                {props.columns!.map(col =>
                                    !col.hide ?
                                        <Column field={col.showField ? col.showField : !col.fieldcamelCase ? col.field : col.fieldcamelCase} key={col.id} className={col.align ? 'p-text-' + col.align : 'p-text-left'} header={col.header} filterType={col.type}
                                            sortable={col.sortable} body={bodyTemplate}>
                                        </Column> : null)}
                                {props.customAttibutes?.deleteDisable && props.customAttibutes?.editDisable && !props.customAttibutes?.buttons ? null : <Column body={actionBodyTemplate} className="p-text-center" header="Acciones"></Column>}
                            </DataTable>
                            <MostrarErrores errores={errores} />
                        </div>
                    </div>
                </div>

                {
                    dialog ?
                        editando ?
                            <CU<TEntidad, TEntidadCreacion> dataKey="id" updateMode={true} url={props.url} columns={props.columns} nombreEntidad={props.nombreEntidad} validateAddNew={props.validateAddNew} dataRow={row} close={hideDialog} save={updateRowsAfterSave} customAttibutes={props.customAttibutes!} emptyRow={props.emptyRow} />
                            :
                            <CU<TEntidad, TEntidadCreacion> dataKey="id" updateMode={false} url={props.url} columns={props.columns} nombreEntidad={props.nombreEntidad} validateAddNew={props.validateAddNew} customAttibutes={props.customAttibutes!} close={hideDialog} save={updateRowsAfterSave} emptyRow={props.emptyRow} />
                        :
                        null
                }

                {
                    showComponentId && showComponent && !showIsOpen ?
                        <Modal id={showComponentId} defaults={defaultcolumn} componentadjunt={componentadjunt} componentadjuntid={componentadjuntid!} component={showComponent} close={() => { setShowComponent(undefined) }} /> : null
                }

            </>
        } />
    )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface rdInterfaceProps<TEntidad, TEntidadCreacion> {
    url: string;
    paginator?: boolean;
    nombreEntidad: string;
    customAttibutes?: ICustomAttributes;
    validateAddNew?: IValidateAddNew;
    dataKey: string;
    emptyRow: TEntidadCreacion;
    defaults?: IDefaultColumn[];
    columns?: ITableColumn[];
    refreshComponent?: boolean;
    role?: TRole;
}

RD.defaultProps = {
    paginator: true,
}