import { useState, useContext, useRef } from 'react'
import { ITableColumn, ICargaInventario } from 'utils/interfaces';
import { urlDepositosEmpresa, urlEmpresasActivos, urlInsumosActivos } from 'app/service/Endpoints'
import AGDropdown from 'app/components/AGDropdown'
import { inventarioCreacionDTO, stockCreacionDTO } from 'app/dto/stock.mode'
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';
import { confirmClear } from 'utils/Confirmar'
import classNames from 'classnames';
import * as func from 'utils/formDataUtils'
import showMensaje from 'utils/Mensaje'
import Insumos from './Insumos'
import DetalleInsumo from './DetalleInsumo'
import EntitiesContext from 'context/EntitiesContext'
import { urlStockInventario } from 'app/service/Endpoints'
import { GenericService } from 'app/service/GenericService';
import { Toast } from 'primereact/toast';


export default function Inventario(props: inventarioProps) {

    const emptyRow: inventarioCreacionDTO = {
        empresa: undefined,
        empresalastquery: undefined,
        fecha: new Date(),
        insumoseleccionado: undefined,
        cantidad: undefined,
        deposito: undefined,
        insumos: undefined,
    }

    const [record, setRecord] = useState<inventarioCreacionDTO>(emptyRow);
    const [submitted, setSubmitted] = useState(false)
    const [addsubmitted, setAddSubmitted] = useState(false)
    const entitiesContext = useContext(EntitiesContext);
    const [refreshToBack, setRefreshToBack] = useState(false)
    const service = new GenericService();
    const toast = useRef<Toast>(null);

    const columnas: ITableColumn[] = [
        { id: 0, field: 'empresa', hide: true, optionsFromEndPoint: urlEmpresasActivos, optionName: 'razonsocial', header: 'Empresa', required: true, disabled: false, colInput: 0, type: 'dropdown', sortable: false },
        { id: 1, field: 'deposito', hide: true, required: true, colInput: 1, header: 'Depósito', type: 'dropdown', optionsFromEndPoint: urlDepositosEmpresa, optionModeQuery: 'id', optionName: 'nombre', },
        { id: 2, field: 'fecha', required: true, hide: true, colInput: 2, header: 'Fecha', type: 'date' },
        { id: 3, field: 'insumoseleccionado', required: true, colInput: 3, header: 'Insumo', type: 'dropdown', optionsFromEndPoint: urlInsumosActivos, optionName: 'nombre', optionFilter: true },
        { id: 4, field: 'cantidad', required: false, colInput: 13, header: 'Cantidad', type: 'number', numberStep: 1, showButtons: true },
    ]

    const lista: ITableColumn[] = [
        { id: 0, field: 'insumo.nombre', hide: false, sortable: true, disabledEdit: true, header: 'Insumo' },
        { id: 1, field: 'cantidad', hide: false, sortable: true, type: 'number', showMask: '#.##0,00', align: 'right', disabledEdit: false, header: 'Cantidad' },
        { id: 2, field: 'insumo.unidad.nombre', hide: false, sortable: true, disabledEdit: true, header: 'Unidad' },
    ]

    function changed(field: string, value: any, source?: any) {

        let _record: inventarioCreacionDTO;
        var key = field,
            obj = {
                [key]: value
            };

        if (source) {
            _record = { ...source, ...obj }
        } else {
            _record = { ...record, ...obj }
        }

        console.log("field new record", field, _record)

        setRecord(x => x = _record)


    }

    const property = (fieldName: string) => {
        return columnas.find((x) => x.field === fieldName)
    }

    const toolbarLeftTemplate = () => {
        return (
            <>
                <Button label={'Volver'} className="p-button-help" icon="pi pi-arrow-left" onClick={() => props.hide && props.hide!(refreshToBack)
                } style={{ marginRight: '.5em' }} />
                <Button label={'Limpiar'} className="p-button-danger" icon="pi pi-file" onClick={() => record.insumos ? confirmClear(undefined, clear) : clear()} style={{ marginRight: '.5em' }} />
                <Button label="Guardar" disabled={false} className="p-button-primary" icon="pi pi-save" onClick={() => save()} style={{ marginRight: '.5em' }} />
            </>
        )
    };

    const save = () => {
        setSubmitted(true)
        if (record.empresa && record.deposito && record.fecha) {
            if (!record.insumos || record.insumos?.length === 0) {
                showMensaje(() => { }, `Debe cargar un insumo como mínimo.`)
            } else {
                let data: stockCreacionDTO = {
                    empresaid: record.empresa.id,
                    depositoorigenid: record.deposito.id,
                    fecha: record.fecha,
                    movimientotipo: 'Inventario',
                    comprobantetipo: 'Ajuste',
                    usuarioid: Number(entitiesContext.user.id!),
                    insumos: record.insumos
                }
                //console.log(data)
                //debugger
                setSubmitted(false)
                service.post({ url: urlStockInventario, creacion: data }).then(res => {
                    if (res.success) {
                        toast.current!.show({ severity: 'success', summary: "Inventario", detail: 'Se han registrados n movimientos', life: 2000 });
                        clear()
                        setRefreshToBack(true)
                    } else {
                        //setErrores(res.error);
                        //props.close!();
                    }
                }).catch(error => { console.log(error) });
            }
        }
    }

    const clear = () => {
        setSubmitted(false)
        setAddSubmitted(false)
        setRecord(emptyRow)
    }

    const add = () => {
        setSubmitted(true)
        setAddSubmitted(true)

        if (record.empresa && record.deposito && record.insumoseleccionado && Number(record.cantidad) >= 0) {

            //console.log("add", Number(record.cantidad) >= 0)
            setAddSubmitted(false)

            let _insumos: ICargaInventario[];
            let exist = false;

            record.insumos?.forEach(element => {
                if (element.insumo.id === record.insumoseleccionado.id) {
                    showMensaje(() => { }, `El insumo ${record.insumoseleccionado.nombre} ya se encuentra en la lista.`)
                    exist = true;
                }
            });

            if (!exist) {
                // console.log(rounded)
                //console.log("valor", func.formatNumber(record.dosis * hectareas, 2))
                const newInsumo: ICargaInventario = {
                    insumo: record.insumoseleccionado,
                    cantidad: record.cantidad,
                }

                //console.log("ADD", record.insumoseleccionado, record.dosis, record.deposito, record.insumos)
                // console.log("new Insumo ", newInsumo)
                // console.log("new hectareas ", hectareas)
                // console.log("record.hectareas ", record.hectareas)
                if (!record.insumos) {
                    _insumos = [newInsumo]
                } else {
                    _insumos = [...record.insumos, newInsumo]
                }
                //console.log("_insumos", _insumos)

                record.insumoseleccionado = undefined
                record.cantidad = undefined

                changed('insumos', _insumos)

            }
        }
    }


    return (
        <>
            <h5>Stock / Inventario</h5>
            <Toast ref={toast} />
            <Toolbar left={toolbarLeftTemplate}></Toolbar>
            <div className="card mt-5">
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <AGDropdown disabledUpdate={record.insumos?.length! > 0} updateMode={true} col={columnas.find((x) => x.field === 'empresa')!} value={record.empresa!} onChanged={
                            (x) => changed('empresa', x)} submitted={submitted} />
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <AGDropdown disabledUpdate={record.insumos?.length! > 0} updateMode={true} col={columnas.find((x) => x.field === 'deposito')!} value={record.deposito} id={record.empresa?.id} onChanged={(x) => changed('deposito', x)} submitted={submitted} />
                    </div>
                    {/* lastQueryExpression={lastDeposito} onUpdateQueryExpression={(e) => setLastDeposito(e)} */}
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor={columnas.find((x) => x.field === 'fecha')?.field}>{columnas.find((x) => x.field === 'fecha')?.header}</label>
                        <Calendar locale="es" style={{ marginTop: "0.3rem" }} showIcon id={columnas.find((x) => x.field === 'fecha')?.field} value={record.fecha ? new Date(record.fecha!) : undefined} onChange={(e) => changed('fecha', e.value)} showButtonBar dateFormat="dd/mm/yy" mask="99/99/9999" required={columnas.find((x) => x.field === 'fecha')?.required} className={classNames({ 'p-invalid': submitted && !record.fecha && property('fecha')?.required })} />
                        {submitted && !record.fecha && property('fecha')?.required && <small className="p-invalid">Debe indicar el valor de {property('fecha')?.header}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <AGDropdown col={columnas.find((x) => x.field === 'insumoseleccionado')!} value={record.insumoseleccionado} onChanged={(x) => changed('insumoseleccionado', x)} submitted={addsubmitted} />
                        {record.insumoseleccionado ? <DetalleInsumo insumo={record.insumoseleccionado} empresa={record.empresa} deposito={record.deposito} payload={(value) => { changed('stock', value) }} /> : null}

                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        {/* {record.insumoseleccionado ? record.insumoseleccionado.insumo.unidad.nombre : null} */}
                        <label htmlFor={property('cantidad')?.field}>{property('cantidad')?.header} {record.insumoseleccionado?.unidad?.nombre && `(${record.insumoseleccionado?.unidad?.nombre})`}</label>
                        <InputNumber locale="es" style={{ marginTop: "0.3rem" }} id={property('cantidad')?.field} value={record.cantidad!} mode='decimal' showButtons={property('cantidad')?.showButtons} inputId="minmax-buttons" step={property('cantidad')?.numberStep} maxFractionDigits={3} onValueChange={(e) => changed('cantidad', e.value)} required={property('cantidad')?.required} className={classNames({ 'p-invalid': addsubmitted && (record.cantidad === undefined || Number(record.cantidad) < 0) })} />
                        {addsubmitted && (record.cantidad === undefined || Number(record.cantidad) < 0) && <small className="p-invalid">Debe indicar un valor mayor o igual que cero.</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2 p-mt-5">
                        <Button label="Agregar" icon="pi pi-plus" onClick={() => add()} />
                    </div>
                </div>
                <div>
                    <Insumos<ICargaInventario> values={record.insumos} columns={lista} onChanged={(value) => changed('insumos', value)} />
                </div>
            </div>
        </>)

}
interface inventarioProps {
    hide?: (refresh?: boolean) => void
}