import React, { useEffect, useState, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { GenericService } from 'app/service/GenericService';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider'
import { Toast } from 'primereact/toast';
import { ITableColumn } from '../../utils/interfaces';
import { urlCampañasActivos, urlCampañasEmpresas, urlLotesEstablecimiento, urlEspeciesActivos, urlLotesAsociar, urlEstablecimientosConLotes } from 'app/service/Endpoints'
import AGDropdown from 'app/components/AGDropdown'
import 'primeflex/primeflex.css';
import Modal from './Modal'
import { loteDTO, loteAsociarCreacionDTO } from 'app/dto/lotes.model'


export default function AsociarEstablecimiento(props: asociarestablecimientoProps) {

    const service = new GenericService();

    const toast = useRef<Toast>(null);

    type rowAsociarEstablecimiento = {
        campaña: any,
        empresa: any,
        establecimiento: any,
        loteid: any,
    }

    const emptyRow: rowAsociarEstablecimiento = {
        campaña: undefined,
        empresa: undefined,
        establecimiento: undefined,
        loteid: 0,
    }

    const [record, setRecord] = useState<rowAsociarEstablecimiento>(emptyRow);
    const [lotes, setLotes] = useState<loteDTO[]>();
    const [dialogEspecies, setDialogEspecies] = useState(false)
    const [dialog, setDialog] = useState(true)

    const especies: ITableColumn[] = [
        { id: 0, field: 'especieantecesor', hide: false, required: false, colInput: 0, autoFocus: true, type: 'dropdown', optionsFromEndPoint: urlEspeciesActivos, optionName: 'nombre', header: 'Especie antecesor' },
        { id: 1, field: 'especieprimera', hide: false, required: true, colInput: 1, type: 'dropdown', optionsFromEndPoint: urlEspeciesActivos, optionName: 'nombre', header: 'Especie primera' },
        { id: 2, field: 'especiesegunda', hide: false, required: false, colInput: 2, type: 'dropdown', optionsFromEndPoint: urlEspeciesActivos, optionName: 'nombre', header: 'Especie segunda' },
    ]

    useEffect(() => {
        //console.log("AsociarEstablecimiento", props)

        if (props.campaña) {
            //alert(JSON.stringify(props.campaña))
            if (!record.campaña) {
                changed('campaña', props.campaña)
            }
        }
        if (props.empresa) {
            //alert(JSON.stringify(props.campaña))
            if (!record.empresa) {
                changed('empresa', props.empresa)
            }
        }
    })


    const columnas: ITableColumn[] = [
        { id: 0, field: 'campaña', hide: false, required: true, colInput: 0, disabledEdit: true, autoFocus: false, type: 'dropdown', optionsFromEndPoint: urlCampañasActivos, optionName: 'nombre', header: 'Campaña', sortable: true, optionFilter: false, disabledUpdate: true },
        { id: 1, field: 'empresa', hide: false, optionsFromEndPoint: urlCampañasEmpresas, optionName: 'razonsocial', optionModeQuery: 'id', header: 'Empresa', disabled: false, disabledEdit: true, colInput: 1, type: 'dropdown', sortable: false },
        { id: 2, field: 'establecimiento', hide: false, required: true, colInput: 2, autoFocus: true, type: 'dropdown', optionsFromEndPoint: urlEstablecimientosConLotes, optionName: 'nombre', header: 'Establecimiento', sortable: true, optionFilter: false },
    ]

    function changed(field: string, value: any) {
        //console.log("field record", field, record)
        var key = field,
            obj = {
                [key]: value
            };
        let _record = { ...record, ...obj }
        //console.log("new record", _record)
        setRecord(_record)

        if (field === 'establecimiento') {
            if (_record.establecimiento) {
                //console.log("leer...", _record.campaña, _record.empresa, _record.establecimiento)
                let params = {
                    campañaid: record?.campaña?.id,
                    empresaid: record?.empresa?.id,
                    establecimientoid: _record.establecimiento.id,
                }
                service.getId({ url: urlLotesEstablecimiento, params: params }).then(response => { setLotes(response.data) }).catch(error => console.log(error));

                // service.getId({ url: urlLotesEstablecimiento, id: _record.establecimiento.id }).then(response => { setLotes(response.data) }).catch(error => console.log(error));
            }
        }
    }
    // Asociados(_record.establecimiento.id, response.data)

    const bodyTemplate = (data: any, props: any) => {
        //console.log(props.filterField, data)
        switch (props.filterType) {
            case 'string' || null:
                return (
                    <>
                        {data[props.field]}
                    </>
                );
        }
    }

    const actionBodyTemplate = (rowData: any) => {
        return (
            <div>
                {!rowData.activo ? <Button icon="pi pi-plus" className="p-button-rounded p-button-text" onClick={() => SolicitarEspecies(rowData)} /> : <Button icon="pi pi-check" className="p-button-secondary p-button-rounded p-button-text" />}
            </div>
        );
    }

    const SolicitarEspecies = (rowData: any) => {
        //alert("solicitando")
        //console.log("Asociar", rowData)
        if (rowData) {
            changed('loteid', rowData.id)
        }
        setDialogEspecies(true)
    }

    const save = (row: any) => {
        setDialogEspecies(false)
        if (row) {
            let especieantecesorid = 0;
            let especieprimeraid = 0;
            let especiesegundaid = 0;
            const { especieantecesor, especieprimera, especiesegunda } = row;

            if (especieantecesor) especieantecesorid = especieantecesor.id;
            if (especieprimera) especieprimeraid = especieprimera.id;
            if (especiesegunda) especiesegundaid = especiesegunda.id;

            //console.log("especieprimera", especieprimera)
            let newRecord: loteAsociarCreacionDTO = {
                campañaid: record?.campaña?.id,
                empresaid: record?.empresa?.id,
                establecimientoid: record?.establecimiento?.id,
                loteid: record?.loteid,
                especieantecesorid: especieantecesorid,
                especieprimeraid: especieprimeraid,
                especiesegundaid: especiesegundaid,
            }
            service.post({ url: urlLotesAsociar, creacion: newRecord }).then(response => {
                if (response.success) {
                    toast.current!.show({ severity: 'success', summary: "Asociar lote", detail: 'Registro creado', life: 2000 })
                }
                //setLotes(response.data) 
            }).catch(error => console.log(error));
            //console.log(newRecord)
        }
    }

    const HeaderTemplate = () => {
        return (
            <div >
                {props.title}
            </div>
        )
    }

    const DialogFooter = () => {
        return (
            <>
                <Divider />
                <Button label="Aceptar" icon="pi pi-check" className="p-button-text p-button-primary" onClick={hideDialog} />
            </>
        )
    };

    const hideDialog = () => {
        setDialog(false);
        props.onSubmit();
    }


    return (
        <>
            <Toast ref={toast} />
            <Dialog className='crud-dialog' closable={true} modal closeOnEscape={true} visible={dialog} blockScroll={true} position={'right'} header={HeaderTemplate} footer={DialogFooter} onHide={hideDialog} >
                <div className="grid">
                    <div className="col-12">
                        <div className="card">
                            <h5>Nueva asociación</h5>
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col">
                                    <AGDropdown col={columnas.find((x) => x.field === 'campaña')!} onChanged={(x) => changed('campaña', x)} value={record?.campaña} />
                                </div>
                                <div className="p-field p-col">
                                    <AGDropdown col={columnas.find((x) => x.field === 'empresa')!} onChanged={(x) => changed('empresa', x)} value={record?.empresa} id={record?.campaña?.id} />
                                </div>
                                <div className="p-field p-col">
                                    <AGDropdown col={columnas.find((x) => x.field === 'establecimiento')!} onChanged={(x) => changed('establecimiento', x)} value={record?.establecimiento} />
                                </div>
                            </div>

                            <DataTable value={lotes} resizableColumns columnResizeMode="fit" className="p-datatable-sm" rowHover emptyMessage={'No hay lotes'}>
                                <Column field={'nombre'} key={'nombre'} header={'Lote'} filterType={'string'}
                                    body={bodyTemplate} />
                                <Column field={'hectareas'} key={'hectareas'} header={'Hectáreas'} filterType={'string'}
                                    body={bodyTemplate} />
                                <Column field={'ambiente'} key={'ambiente'} header={'Ambiente'} filterType={'string'}
                                    body={bodyTemplate} />
                                <Column body={actionBodyTemplate} header="Asociar" />
                            </DataTable>
                        </div>
                    </div>
                </div>
                {dialogEspecies ? <Modal component='especieslote' columns={especies} close={() => setDialogEspecies(false)} save={save} /> : null}
            </Dialog>
        </>

    )
}

interface asociarestablecimientoProps {
    title?: string,
    campaña: any,
    empresa: any,
    onSubmit: () => void;
}