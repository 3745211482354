import { useContext } from 'react';
import EntitiesContext from 'context/EntitiesContext';
import { GenericService } from 'app/service/GenericService';
import { urlUsuarioFromEMail, urlUsuarioFromUserId, urlUsuarioPermisos } from 'app/service/Endpoints';
import { perfilusuarioDTO, permisoDetalleCreacionDTO } from 'app/dto/usuarios.model';
import Swal from "sweetalert2";
import './Login.css';


export function ProcesoHabilitado(props: userDataControl): boolean {
    let habilitado = false;
    let proceso: string = "";
    //console.log("ProcesoHabilitado ", props)
    if (props.user) {
        let configAccess = props.user.permisos?.find(p => p.userid === props.user.id && p.recurso.toLowerCase() === props.resource?.toString().toLowerCase())
        if (configAccess) {
            switch (props.process) {
                case 'agregar':
                    proceso = "Agregar"
                    if (configAccess.agregar === true) {
                        habilitado = true;
                    }
                    break;
                case 'editar':
                    proceso = "Editar"
                    if (configAccess.editar === true) {
                        habilitado = true;
                    }
                    break;
                case 'eliminar':
                    proceso = "Eliminar"
                    if (configAccess.eliminar === true) {
                        habilitado = true;
                    }
                    break;
                case 'activar':
                    proceso = "Activar/Desactivar"
                    if (configAccess.activar === true) {
                        habilitado = true;
                    }
                    break;

                default:
                    proceso = "Sin definir"
                    habilitado = false;
                    break;
            }
        } else {
            proceso = " (Agregar/Editar/Eliminar/Activar/Desactivar) sobre "
        }

        //console.log("props ", props.processMessage)

        if (props.showMessage && !habilitado) {

            //console.log("x", props.processMessage)
            let message = props.processMessage ? props.processMessage?.toLowerCase() : proceso.toLowerCase()
            Swal.fire({
                text: props.user.nombres! + ", la acción " + message + " " + props.resource?.toLowerCase() + " no se encuentra habilitada en su cuenta.",
                confirmButtonText: "Aceptar",
                icon: 'info',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
            })
        }
    }


    return habilitado
}

export async function LoadUser(props: any) {
    const service = new GenericService();
    //const entitiesContext = useContext(EntitiesContext);
    let response: any;

    if (props.email) {
        response = await service.getId({ url: urlUsuarioFromEMail, creacion: undefined, params: { email: props.email } })
    } else {
        if (props.id) {
            response = await service.getId({ url: urlUsuarioFromUserId, creacion: undefined, params: { id: props.id } })
        }
    }

    //console.log(response)
    if (response.success) {
        if (response.data) {
            let userData: perfilusuarioDTO = {
                id: response.data.id,
                aspnetuserid: response.data.aspnetuserid,
                email: response.data.email,
                nombres: response.data.nombres,
                apellido: response.data.apellido,
                domicilio: response.data.domicilio,
                provincia: response.data.provincia,
                departamento: response.data.departamento,
                localidad: response.data.localidad,
                telefonos: response.data.telefonos,
            }
            //console.log("pre_cargar autorizaciones ", userData)
            userData = await CargarAutorizaciones(userData)
            return userData
        }
    }
}
export function RefreshAutorizaciones(userData: perfilusuarioDTO, permisos: permisoDetalleCreacionDTO[]): perfilusuarioDTO {
    permisos.forEach((element, index) => {
        if (index === 0) {
            userData.permisos = [{
                userid: userData.id,
                recurso: element.recurso,
                consultar: element.consultar,
                agregar: element.agregar,
                editar: element.editar,
                eliminar: element.eliminar,
                activar: element.activar
            }]
        } else {
            userData.permisos!.push({
                userid: userData.id,
                recurso: element.recurso,
                consultar: element.consultar,
                agregar: element.agregar,
                editar: element.editar,
                eliminar: element.eliminar,
                activar: element.activar
            })
        }
    });
    return userData
}

export async function CargarAutorizaciones(userData: perfilusuarioDTO) {
    // if (!userData.permisos) {
    //     if (userData.id) {
    //         userData.permisos = [{ userid: userData.id, recurso: 'Empresas', consultar: false }]
    //     }

    // } else {
    //     if (userData.id) {
    //         userData.permisos.push({ userid: userData.id, recurso: 'Empresas', consultar: false })
    //     }
    // }

    // userData.permisos!.push({ userid: userData.id!, recurso: 'Depósitos', consultar: true, agregar: false, editar: true, eliminar: true })
    const service = new GenericService();
    //const entitiesContext = useContext(EntitiesContext);
    let response: any;

    if (userData !== undefined) {
        response = await service.getId({ url: urlUsuarioPermisos, creacion: undefined, params: { id: userData.id } })
        //console.log("response ", response.data)
        //userData.permisos = undefined
        userData.permisos = response.data
        //console.log("CargarAutorizaciones ", userData)
        return userData
    } else {
        return userData
    }
}



export function GetUserName() {
    const entitiesContext = useContext(EntitiesContext);
    if (entitiesContext.user) {
        return entitiesContext.user.nombres! + ' ' + entitiesContext.user.apellido!;
    } else {
        //return claims.filter(x => x.nombre === "email")[0]?.valor
        return 'Anónimo'
    }
}

interface userDataControl {
    user: perfilusuarioDTO;
    resource?: string;
    process?: 'consultar' | 'agregar' | 'editar' | 'eliminar' | 'activar';
    processMessage?: string;
    showMessage?: boolean;
}