import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputNumber } from 'primereact/inputnumber';
import AGDropdown from 'app/components/AGDropdown'
import { ITableColumn, ILote } from 'utils/interfaces'
import { InputText } from 'primereact/inputtext'
import { urlEstadiosByEspecie, urlLotesEnCampaña } from 'app/service/Endpoints'
import { GenericService } from 'app/service/GenericService';
import { Button } from 'primereact/button';
import showMensaje from 'utils/Mensaje'
import './DataTableDemo.css'

export default function OrdenDeTrabajoLotes(props: ordenDeTrabajoLotesProps) {

    const ambiente = [
        { label: 'Malo', value: 'Malo' },
        { label: 'Regular', value: 'Regular' },
        { label: 'Bueno', value: 'Bueno' },
        { label: 'Muy bueno', value: 'Muy bueno' },
        { label: 'Excelente', value: 'Excelente' },
    ];


    const columns: ITableColumn[] = [
        { id: 0, field: 'id', hide: true, required: true, header: 'Id' },
        { id: 1, field: 'nombre', hide: false, required: true, header: 'Lote', disabledEdit: true },
        { id: 2, field: 'hectareasatrabajar', hide: false, hideLabel: true, type: 'number', required: true, header: 'Hectareas' },
        { id: 3, field: 'estadio', hide: false, hideLabel: true, required: true, header: 'Estadio', type: 'dropdown', optionsFromEndPoint: urlEstadiosByEspecie, optionModeQuery: 'id', optionName: 'nombre', optionsShowFromObjectKey: 'nombre' },
        { id: 4, field: 'condicion', hide: false, hideLabel: true, required: true, header: 'Condición', type: 'dropdown', options: ambiente, optionName: 'label', optionValue: 'value', sortable: true },
    ]

    const [loading, setLoading] = useState(true);
    const [lotes, setLotes] = useState<ILote[]>();
    const [previousvalue, setPreviousValue] = useState(null);

    const service = new GenericService();

    useEffect(() => {
        //console.log("orden", props)
        if (props.values) {
            setLotes(props.values!)
            setLoading(true)
        } else {
            if (loading && props.especie) {
                setLoading(false)
                const paramLote = {
                    campañaid: props.campaña.id,
                    empresaid: props.empresa.id,
                    establecimientoid: props.establecimiento ? props.establecimiento.id : undefined,
                    especieid: props.especie.id
                }
                service.getId({ url: urlLotesEnCampaña, params: paramLote }).then(response => { load(response) }).catch(error => console.log(error));
            }
            if (!props.especie) {
                setLotes(undefined)
            }
        }
    }, [props.especie, props.values]); // eslint-disable-line react-hooks/exhaustive-deps    

    const load = (response: any) => {
        //console.log(response)
        if (response.success) {
            let data: any[] = response.data;
            let listLotes: ILote[];
            let sumaHectareas: number = 0;
            data.forEach(element => {
                const { id, nombre, hectareas, ambiente } = element;
                sumaHectareas = sumaHectareas + hectareas
                let newLote: ILote = {
                    id: id,
                    nombre: nombre,
                    hectareas: hectareas,
                    hectareasatrabajar: hectareas,
                    estadio: undefined,
                    condicion: ambiente,
                }
                if (listLotes)
                    listLotes.push(newLote)
                else
                    listLotes = [newLote]
                //console.log(id, nombre, hectareas)
            });
            setLotes(listLotes!)
            props.onChanged!(listLotes!)
            //alert(sumaHectareas!)
            props.onChangedHectareas!(sumaHectareas!)
        }
    }

    let originalRows: any = {};

    const bodyTemplate = (data: any, props: any) => {
        //console.log(props.rowData)
        let keyToShow = (columns.find(f => f.field === props.field)?.optionsShowFromObjectKey)

        if (String(props.field).includes('.')) {
            let entity = String(props.field).split('.')
            //console.log(data[entity[0]])
            if (data[entity[0]] !== null) {
                let keys = Object.keys(data[entity[0]])
                let value: string;
                keys.forEach((element, index) => {
                    //console.log(element, entity[1])
                    if (element === entity[1]) {
                        let values: string[] = Object.values(data[entity[0]])
                        //console.log(values[index])
                        value = values[index]
                    }
                });
                if (value! !== '')
                    return (value!)
            }

        }
        switch (props.filterType) {
            case 'string' || null:
                return (
                    <>
                        <span className="p-column-title">{props.header}</span>
                        {data[props.field]}
                    </>
                );
            case 'dropdown':
                return (
                    <>
                        <span className="p-column-title">{props.header}</span>
                        {
                            keyToShow ? data[props.field] ? data[props.field][keyToShow] : null : null
                        }
                        {
                            !keyToShow ? data[props.field] : null
                        }
                    </>
                );
            case 'number':
                return (
                    <>
                        <span className="p-column-title">{props.header}</span>
                        {data[props.field]}
                    </>
                );
            default:
                return (
                    <>
                        <span className="p-column-title">{props.header}</span>
                        {data[props.field]}
                    </>
                );
        }
    };


    const onRowEditInit = (event: any) => {
        //console.log("init")
        if (previousvalue)
            originalRows = previousvalue
        originalRows[event.index] = { ...lotes![event.index] };
        setPreviousValue(originalRows)
    }
    const onRowEditCancel = (event: any) => {
        originalRows = previousvalue
        if (lotes) {
            let _lotes = [...lotes];
            if (originalRows[event.index]) {
                _lotes[event.index] = originalRows[event.index];
                delete originalRows[event.index];
            }
            setPreviousValue(originalRows)
            setLotes(_lotes);
        }
    }

    const onRowEditSave = (event: any) => {
        //console.log(event)
        if (!isValid(event.data)) {
            onRowEditCancel(event)
        } else {
            props.onChanged!(lotes)
        }
    }

    const isValid = (data: any) => {
        if (data) {
            if (data.hectareasatrabajar) {
                if (data.hectareasatrabajar >= 0 && data.hectareasatrabajar <= data.hectareas) {
                    return true
                }
            }
        }
        showMensaje(() => { }, `Debe indicar entre 1 y ${data.hectareas} hectareas a trabajar`)
        return false
    }

    const onEditorValueChange = (productKey: any, props: any, value: any, options?: any[]) => {
        //console.log(props.value)
        //console.log("change", value)
        //console.log(options)
        let _lotes = [...props.value];
        _lotes[props.rowIndex][props.field] = value;
        //console.log(_lotes)
        setLotes(_lotes)
    }

    // const isPositiveInteger = (val: any) => {
    //     let str = String(val);
    //     str = str.trim();
    //     if (!str) {
    //         return false;
    //     }
    //     str = str.replace(/^0+/, "") || "0";
    //     let n = Math.floor(Number(str));
    //     return n !== Infinity && String(n) === str && n >= 0;
    // }

    // const positiveIntegerValidator = (e: any) => {
    //     const { rowData, field } = e.columnProps;
    //     let isPositive = isPositiveInteger(rowData[field])
    //     console.log("es positivo", isPositive)
    //     return isPositive;
    // }



    const numberEdit = (fieldName: any, props: any, disabledEdit: boolean) => {
        return <InputNumber inputId="minmax-buttons" disabled={disabledEdit} showButtons value={props.rowData[fieldName]} mode="decimal" maxFractionDigits={3} onValueChange={(e) => onEditorValueChange(fieldName, props, e.value)} />
    }

    const textEdit = (fieldName: any, props: any, disabledEdit: boolean) => {
        return <InputText disabled={disabledEdit} value={props.rowData[fieldName]} />
    }

    const dropdownEdit = (fieldName: any, propsRow: any, disabledEdit: boolean) => {
        return <AGDropdown id={props.especie ? props.especie.id : undefined} col={columns.find(c => c.field === fieldName)!} disabledUpdate={disabledEdit} value={propsRow.rowData[fieldName]} onChanged={(value, options) => onEditorValueChange(fieldName, propsRow, value, options)} />
    }

    const actionBodyTemplate = (rowData: any) => {
        return (
            <div>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-text" onClick={() => remove(rowData)} />
            </div>
        );
    }

    const remove = (rowData: any) => {
        //console.log(rowData)
        let _lotes = lotes?.filter((r) => r.id !== rowData.id)
        setLotes(_lotes)
        props.onChanged!(_lotes?.length === 0 ? undefined : _lotes)
    }

    const edit = (column: ITableColumn, props: any) => {
        switch (column.type) {
            case 'number':
                return numberEdit(column.field, props, column.disabledEdit!)
            case 'dropdown':
                return dropdownEdit(column.field, props, column.disabledEdit!)
            default:
                return textEdit(column.field, props, column.disabledEdit!)
        }
    }

    return (
        <>
            <div className="datatable-responsive-demo">
                <div className="card">
                    <h6>Lotes afectados</h6>
                    <DataTable value={lotes} className="p-datatable-sm p-datatable-responsive-demo" editMode="row" dataKey="id" onRowEditInit={onRowEditInit} onRowEditSave={onRowEditSave} onRowEditCancel={onRowEditCancel} emptyMessage="No hay lotes.">
                        {columns!.map(col =>
                            !col.hide ?
                                <Column field={col.showField ? col.showField : !col.fieldcamelCase ? col.field : col.fieldcamelCase} key={col.id} header={col.header} editor={(props) => edit(col, props)} body={bodyTemplate} filterType={col.type} >
                                </Column> : null)}
                        <Column rowEditor header={'Editar'} headerStyle={{ width: '7rem' }} bodyStyle={{ textAlign: 'left' }}></Column>
                        <Column body={actionBodyTemplate} header="Eliminar" />
                    </DataTable>
                </div>
            </div>

        </>
    )
}
interface ordenDeTrabajoLotesProps {
    campaña: any,
    empresa: any,
    establecimiento: any,
    especie: any
    values?: any[];
    onChangedHectareas?: (value: number) => void;
    onChanged?: (value: any) => void;
}