import Swal from "sweetalert2";

export default function mensaje(
    onConfirm?: any,
    titulo: string = "Atención!",
    textoBotonConfirmacion: string = "Aceptar"
) {
    Swal.fire({
        title: titulo,
        confirmButtonText: textoBotonConfirmacion,
        icon: 'info',
        showCancelButton: false,
        confirmButtonColor: '#28a745',

    }).then(result => {
        if (result.isConfirmed) {
            onConfirm();
        }
    })
}
