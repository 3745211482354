import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useContext, useEffect, useState } from 'react';
import AutenticacionContext from 'auth/AutenticacionContext';
import Autorizado from 'auth/Autorizado'
import { Button } from 'primereact/button';
import EntitiesContext from 'context/EntitiesContext'
import { Login } from 'auth/Login';
import { Registro } from 'auth/Registro';
import * as userControl from 'auth/UserDataControl'

import { Dialog } from 'primereact/dialog';

export const AppTopbar = (props: any) => {

    const { claims } = useContext(AutenticacionContext);
    const entitiesContext = useContext(EntitiesContext);

    const [iniciarSesion, setIniciarSesion] = useState(false)
    const [registrar, setRegistrar] = useState(false)


    //console.log(claims)
    useEffect(() => {
        if (claims) {
            GetUser();
        }
    }, [claims])

    async function GetUser() {
        let user: any;
        user = claims.filter(x => x.nombre === "user")[0]?.valor
        if (user) {
            const userData = await userControl.LoadUser({ email: user.email })
            //console.log("userData ", userData)
            entitiesContext.setUserData(userData!)
        }
    }

    return (
        <>
            <div className={claims.length !== 0 ? 'layout-topbar' : 'layout-topbar disconected'}>
                <Link to={claims.length !== 0 ? "/ordenesdetrabajo" : "/"} className="layout-topbar-logo">
                    <img src={props.layoutColorMode === 'light' ? 'assets/layout/images/logo-dark.svg' : 'assets/layout/images/logo-white.svg'} alt="logo" />
                    <span>AgroControl</span>
                </Link>

                <button type="button" className="p-link layout-menu-button layout-topbar-button" onClick={props.onToggleMenuClick}>
                    <i className="pi pi-bars" />
                </button>
                {/* className={classNames("layout-topbar-menu lg:flex origin-middle", { 'layout-topbar-menu-mobile-active': props.mobileTopbarMenuActive })} */}
                <div className={classNames("layout-topbar-menu lg:flex origin-middle", { 'layout-topbar-menu-mobile-active': props.mobileTopbarMenuActive })} >
                    <Autorizado resource='topbar'
                        autorizado={<>
                            {/* <span className="nav-link">Hola, {obtenerNombreUsuario()}</span> */}
                            <div style={{ padding: "6px", marginRight: "5px" }}>{entitiesContext.user ? userControl.GetUserName() : 'Leyendo usuario...'}</div>
                            <div>
                                <Button
                                    //onClick={confirm}
                                    onClick={props.onLogoutClick}
                                    className="p-button-danger"
                                    icon="pi pi-power-off">
                                </Button>
                            </div>
                        </>}
                        noAutorizado={<>
                            <div style={{ marginRight: "15px" }}>
                                {/* <Link to="/Registro" className="p-link">
                                    Registro
                                </Link> */}
                                <Button
                                    //onClick={confirm}
                                    label='Registro'
                                    style={{ width: '120px' }}
                                    onClick={() => setRegistrar(true)}
                                    className="p-button-rounded p-shadow-2"
                                    icon="pi pi-user-plus">
                                </Button>
                            </div>
                            <div id="divid">
                                {/* <Link to="/Login" className="nav-link btn btn-link">
                                    Iniciar sesión
                                </Link> */}
                                <Button
                                    //onClick={confirm}
                                    label='Iniciar sesión'
                                    style={{ width: '120px' }}
                                    onClick={() => setIniciarSesion(true)}
                                    className="p-button-rounded p-button-success p-shadow-2"
                                    icon="pi pi-sign-in
                                    ">
                                </Button>
                            </div>
                        </>}
                    />
                </div>
                {/* style={{ width: '60vw' }} contentStyle={{ height: '250px' }} */}
                <Dialog className="dialogModal" closable={true} visible={iniciarSesion} header={'AgroControl'} focusOnShow onHide={() => {
                    //props.cancel && props.cancel!();
                    setIniciarSesion(false)
                }} >
                    <Login login={() => {
                        setIniciarSesion(false);
                        props.onToggleMenuClick();
                    }} />
                </Dialog>
                {/* style={{ width: '60vw' }} contentStyle={{ height: '320px' }} */}
                <Dialog closable={true} visible={registrar} header={'AgroControl'} focusOnShow onHide={() => {
                    //props.cancel && props.cancel!();
                    setRegistrar(false)
                }} >
                    <Registro />
                </Dialog>
            </div>
        </>
    );
}
