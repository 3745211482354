import axios from 'axios'


export class GenericService<TEntidad, TEntidadCreacion> {

    async get(props: genericServiceProps<TEntidad, TEntidadCreacion>) {
        //props.url = "http://192.168.0.23:5000/api/campañas/todos"
        //alert("get")
        //console.log(props)
        try {
            const response = await axios.get(props.url, { params: props.params })
            //let data = json.data;
            //console.log("OK", props.url, response.data)
            return {
                success: true,
                data: response.data,
                error: []
            };
        } catch (error: any) {
            console.log("Error", props.url, error)
            return {
                success: false,
                data: [],
                error: [error.message]
            };
        }
    }

    async getId(props: genericServiceProps<TEntidad, TEntidadCreacion>) {
        //props.url = "http://192.168.0.23:5000/api/campañas/todos"
        //alert("get")
        let url = props.url

        if (props.id) {
            url = url + "/" + props.id
        }

        if (props.subOne) {
            url = props.url + "/" + props.subOne + "/" + props.id;
        }
        //console.log(props)
        //console.log("getId uri", url)
        try {
            const json = await axios.get(url, { params: props.params })
            //console.log(json)
            let data = json.data;

            if (props.dataFromObject !== undefined) {
                //console.log(props.dataFromObject)
                data = json.data[props.dataFromObject]
                //console.log(data)
            }
            return {
                success: true,
                data: data,
                error: ['']
            };
        } catch (error: any) {
            //console.log(error.response)
            return {
                success: false,
                data: [],
                error: [error.message]
            };
        }
        //console.log(props.url, json.data)
        //return json.data;
    }

    async getExist(props: any) {
        //console.log("getByParams", props)
        try {
            const json = await axios.get(props.url, { params: props.params })
            //console.log(props.url, json)
            return {
                success: true,
                data: json.data,
                error: ['']
            };
        } catch (error: any) {
            //console.log(error.response)
            return {
                success: false,
                data: [],
                error: [error.message],
            };
        }
    }

    async post(props: genericServiceProps<TEntidad, TEntidadCreacion>) {
        let url = props.url
        //console.log(props.creacion)
        //console.log(url)
        try {
            const response = await axios.post(url, props.creacion)
            //console.log("respuesta", response)
            return {
                success: true,
                data: response.data,
                error: ['']
            };
        } catch (error: any) {
            //console.log("error", error.response)
            return {
                success: false,
                data: [],
                error: error.response ? error.response.data : [error.message],
            };
            //throw [error.response.data]
        }
    }

    async postId(props: genericServiceProps<TEntidad, TEntidadCreacion>) {
        let url = props.url + "/" + props.id
        //console.log(props.creacion)
        try {
            const response = await axios.post(url, props.creacion)
            //console.log("respuesta", response.data)
            return {
                success: true,
                data: response.data,
                error: ['']
            };
        } catch (error: any) {
            //console.log("error", error.response.data)
            return {
                success: false,
                data: [],
                error: error.response ? error.response.data : [error.message],
            };
            //throw [error.response.data]
        }
    }

    async put(props: genericServiceProps<TEntidad, TEntidadCreacion>) {
        let url = ""
        if (props.id) {
            url = props.url + "/" + props.id
        } else {
            url = props.url
        }

        try {
            let response: any;
            if (props.id) {
                response = await axios.put(url, props.creacion)
            } else {
                response = await axios.put(url, props.params)
            }
            //console.log("try", response)
            return {
                success: true,
                data: response.data,
                error: []
            };
        } catch (error: any) {
            //console.log("catch", error.response.data)
            return {
                success: false,
                data: [],
                error: error.response.data,
            };
        }
    }

    async delete(props: genericServiceProps<TEntidad, TEntidadCreacion>) {
        let url: string;
        if (props.id) {
            if (props.subOne)
                url = props.url + "/" + props.subOne + "/" + props.id;
            else
                url = props.url + "/" + props.id
        } else {
            if (props.subOne)
                url = props.url + "/" + props.subOne
            else
                url = props.url
        }
        //console.log(url, props.creacion)
        try {
            await axios.delete(url, props.creacion)
            //console.log(response)
            //alert("Ok")
            return ""
        } catch (error: any) {
            //alert("error" + error.response.data.mensaje)
            //console.log(error.response.data.mensaje)
            return error.response.data.mensaje
        }
    }
}

interface genericServiceProps<TEntidad, TEntidadCreacion> {
    id?: any,
    dataFromObject?: string,
    url: string,
    lectura?: TEntidad,
    creacion?: TEntidadCreacion,
    subOne?: string,
    params?: any;
}
