import { ReactElement } from "react";
import { Divider } from 'primereact/divider';

export default function NoAutorizado(props: noAutorizado) {

    const DefaultResponse = () => {
        return (
            <div className="grid">
                <div className="col-12">
                    <div className="card" style={{ minHeight: '86vh' }}>
                        <h5 style={{ color: 'red' }} ><i className="pi pi-lock p-mr-2"></i>Acceso denegado</h5>
                        <Divider />
                        <b>{props.nombres}</b>, no tienes acceso a utilizar {props.title ? <b>{props.title}</b> : <>este recurso.</>}
                        <br />
                        Solicite a un usuario autorizado un mayor nivel de acceso, o acceso al recurso deseado.
                    </div>
                </div>
            </div>
        )
    }


    return (
        <>
            {props.display
                ? <div className="grid">
                    <div className="col-12">
                        <div className="card" style={{ minHeight: '86vh' }}>
                            {props.display}
                        </div>
                    </div>
                </div>
                : <DefaultResponse />
            }
        </>)
}

interface noAutorizado {
    display?: ReactElement;
    title?: string;
    nombres?: string;
}
