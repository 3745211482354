import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { ICargaInsumo } from 'utils/interfaces'
import { InputNumber } from 'primereact/inputnumber';
import { formatNumber } from 'utils/formDataUtils'
import showMensaje from 'utils/Mensaje'


export default function OrdenDeTrabajoInsumos(props: ordenDeTrabajoInsumosProps) {

    const [insumos, setInsumos] = useState<ICargaInsumo[]>();
    // const [insumoaplicacion, setInsumoAplicacion] = useState();
    const [previousvalue, setPreviousValue] = useState(null);

    let originalRows: any = {};

    useEffect(() => {
        //console.log("orden", props)
        if (props.values) {
            let values: ICargaInsumo[] = [...props.values]
            //console.log(values)
            // if (props.hectareas) {
            //     values.map((element) => element.total = element.dosis! * props.hectareas!)
            // }
            setInsumos(values)
        }
        // setInsumoAplicacion(props.insumoseleccionadoaplicacion)
    }, [props.values])

    const actionBodyTemplate = (rowData: any) => {
        return (
            <div>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-text" onClick={() => remove(rowData)} />
            </div>
        );
    }

    const sort = (insumos: ICargaInsumo[]) => {
        insumos.forEach((element, index) => {
            element.orden = index + 1;
        });
        setInsumos(insumos);
        //console.log("insumos ordenados", insumos)
    }


    const remove = (rowData: any) => {
        //console.log("insumos", insumos)
        //console.log("rowData", rowData)
        let _insumos = insumos?.filter((r) => r.insumoid !== rowData.insumoid || r.depositoid !== rowData.depositoid)
        //console.log(_insumos)
        setInsumos(_insumos)
        props.onChanged(_insumos!)
    }

    const onEditorValueChange = (productKey: any, colProps: any, value: any, options?: any[]) => {
        //console.log(props.value)
        //console.log("change", value)
        //console.log(options)
        let _insumos = [...colProps.value];
        _insumos[colProps.rowIndex][colProps.field] = value;
        _insumos[colProps.rowIndex]['total'] = formatNumber(value * props.hectareas!, 2);
        //console.log(_lotes)
        setInsumos(_insumos)
    }

    const onRowReorder = (e: any) => {
        sort(e.value)
        //setInsumos(e.value);
    }

    const onRowEditInit = (event: any) => {

        if (previousvalue)
            originalRows = previousvalue
        originalRows[event.index] = { ...insumos![event.index] };
        setPreviousValue(originalRows)
    }
    const onRowEditCancel = (event: any) => {
        originalRows = previousvalue
        if (insumos) {
            let _insumos = [...insumos];
            if (originalRows[event.index]) {
                _insumos[event.index] = originalRows[event.index];
                delete originalRows[event.index];
            }
            setPreviousValue(originalRows)
            setInsumos(_insumos);
        }
    }

    const onRowEditSave = (event: any) => {
        //console.log(event)
        if (!isValid(event.data)) {
            onRowEditCancel(event)
        } else {
            props.onChanged!(insumos)
        }
    }

    const isValid = (data: any) => {
        //console.log("orden de trabajo insumos: insumo aplicación: ", props.insumoseleccionadoaplicacion)
        //console.log(data)
        if (data) {
            if (data.dosis) {
                if (data.aplicacion) {
                    if (data.dosis >= data.aplicacion.dosisdesde && (data.aplicacion.dosishasta === 0 || data.dosis <= data.aplicacion.dosishasta)) {
                        return true
                    } else {
                        showMensaje(() => { }, `Debe indicar una dosis entre ${data.aplicacion.dosisdesde} y ${data.aplicacion.dosishasta} por hectarea`)
                    }
                } else {
                    return true
                }
            } else {
                showMensaje(() => { }, `Debe indicar la dosis por hectarea`)
                return false
            }
        }
    }


    const numberEdit = (fieldName: any, colProps: any, disabledEdit: boolean) => {
        return <InputNumber showButtons inputId="minmax-buttons" step={0.1} disabled={disabledEdit} value={colProps.rowData[fieldName]} mode="decimal" maxFractionDigits={3} onValueChange={(e) => onEditorValueChange(fieldName, colProps, e.value)} />
    }

    const edit = (fieldName: string, colProps: any) => {
        switch (fieldName) {
            case 'dosis':
                return numberEdit('dosis', colProps, false)
        }
    }

    return (
        <>
            <div className="card">
                <h6>Insumos</h6>
                {/* {console.log("", props.insumoseleccionadoaplicacion)} */}
                {/* onRowEditInit={onRowEditInit} onRowEditSave={onRowEditSave} onRowEditCancel={onRowEditCancel} */}
                {/* editor={(props) => edit(col, props)} body={bodyTemplate} */}
                <DataTable value={insumos} sortField={'orden'} sortMode={'single'} editMode="row" dataKey="insumo" emptyMessage="No hay registros." rowHover onRowReorder={onRowReorder} sortOrder={1} className="p-datatable-sm" onRowEditInit={onRowEditInit} onRowEditSave={onRowEditSave} onRowEditCancel={onRowEditCancel}  >
                    <Column rowReorder style={{ width: '2em' }} />
                    <Column field={'orden'} ></Column>
                    <Column field={'insumo.nombre'} header={'Insumo'} ></Column>
                    <Column field={'dosis'} header={'Dosis'} editor={(colProps) => edit('dosis', colProps)} ></Column>
                    <Column field={'deposito.nombre'} header={'Depósito'} ></Column>
                    <Column field={'total'} header={'Total'} ></Column>
                    <Column field={'insumo.unidad.nombre'} header={'Unidad'} ></Column>
                    <Column rowEditor header={'Editar'} headerStyle={{ width: '7rem' }} bodyStyle={{ textAlign: 'left' }}></Column>
                    <Column body={actionBodyTemplate} header="Eliminar" />
                </DataTable>
            </div>

        </>
    )
}

interface ordenDeTrabajoInsumosProps {
    especieid?: number;
    insumoseleccionadoaplicacion?: any;
    values?: any[];
    hectareas?: number;
    onChanged: (value: any) => void;
}