import { createContext } from "react";
import { IGenericEntity } from 'utils/interfaces'
import { perfilusuarioDTO } from 'app/dto/usuarios.model'

const EntitiesContext = createContext<{
    entities: IGenericEntity[],
    getEntity(entity: IGenericEntity): void,
    getValue(entityId: number, id: number): void,
    setUserData(user: perfilusuarioDTO): void,
    user: perfilusuarioDTO,
}>({ entities: [], getEntity: () => { }, getValue: () => { }, setUserData: () => { }, user: {} });


export default EntitiesContext;
