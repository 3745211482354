import { useState, useRef, useEffect } from 'react';
import { GenericService } from 'app/service/GenericService';
import { urlStockDetalleInsumo } from 'app/service/Endpoints'
import { IStock } from 'app/dto/stock.mode'
import * as func from 'utils/formDataUtils'



export default function DetalleInsumo(props: detalleinsumoProps) {


    const service = new GenericService();
    const [detalleinsumo, setDetalleInsumo] = useState<IStock>();
    const [loading, setLoading] = useState(true)
    const [lastParam, setLastParam] = useState({ insumoid: undefined, empresaid: undefined, depositoid: undefined });

    useEffect(() => {
        console.log(props, lastParam)
        const { insumo, empresa, deposito } = props;
        if (!loading) {
            if (insumo.id !== lastParam.insumoid || empresa?.id !== lastParam.empresaid || deposito?.id !== lastParam.depositoid) {
                setLoading(true)
            }
        }

        if (insumo && loading) {
            let param = {
                insumoid: insumo.id,
                empresaid: empresa ? empresa.id : undefined,
                depositoid: deposito ? deposito.id : undefined,
            }
            setLastParam(param)
            //console.log("param: ", param)
            setLoading(false)
            service.get({ url: urlStockDetalleInsumo, creacion: undefined, params: param }).then(response => {
                //console.log("data", data)
                if (response.success) {
                    setDetalleInsumo(response.data)
                    props.payload && props.payload(response.data.stock)
                    //console.log(JSON.parse(data[3]['condiciones']))
                    //setRows(data);
                    //setLoading(false)
                }
            })
        }
    }, [props, loading]);

    return (
        <>
            {detalleinsumo && <span style={{ color: 'var(--teal-600)', fontSize: '0.8rem' }}>Presentación: {detalleinsumo.producto.presentacion} / Stock: {func.showNumber(detalleinsumo.stock)} {detalleinsumo.producto.unidad}</span>}
        </>
    )
}

interface detalleinsumoProps {
    insumo?: any,
    empresa?: any,
    deposito?: any,
    payload?: (stock: number) => void
}
