import { useState } from 'react'
import EntitiesContext from './EntitiesContext';
import { GenericService } from 'app/service/GenericService';
import { urlGenericEntitiesRows } from 'app/service/Endpoints';
import { genericentityrowDTO } from 'app/dto/genericentityrow.model';
import { IGenericEntity } from 'utils/interfaces';
import { perfilusuarioDTO } from 'app/dto/usuarios.model'

const EntitiesState = (props: any) => {

    const service = new GenericService();

    const [user, setUser] = useState<perfilusuarioDTO | undefined>();
    const [entities] = useState<IGenericEntity[]>([])

    const getEntity = (entity: IGenericEntity) => {
        service.getId({ url: urlGenericEntitiesRows, id: entity.id }).then(response => { setOptions(response, entity.name, entity.id, entity.labelfield, entity.valuefield) }).catch(error => console.log(error));
    };

    const setUserData = (user: perfilusuarioDTO) => {
        //console.log("setUser", user)
        setUser(user)
    }

    // const getUser = (email: string) => {
    //     service.getId({ url: urlGenericEntitiesRows, id: entity.id }).then(response => { setOptions(response, entity.name, entity.id, entity.labelfield, entity.valuefield) }).catch(error => console.log(error));
    // };


    const getValue = (entityId: number, id: number) => {

        //console.log("leer id ", entityId, id)
        let entity = entities.find(e => e.id === entityId)

        let keyIndex: number;
        let valueIndex: number;
        let value: any;

        if (entity) {
            if (entity.values?.length) {

                //console.log("valor id", entity?.valuefield)
                //console.log("return", entity?.labelfield)
                //console.log("values", entity?.values)
                let row = entity.values.find((e, index) => index === 0)
                let keys = Object.keys(row!)
                //console.log("keys", keys)
                keys.forEach((element, index) => {
                    if (element === entity?.valuefield) {
                        keyIndex = index
                    }
                    if (element === entity?.labelfield) {
                        valueIndex = index
                    }
                });

                //console.log("keyIndex", keyIndex!)
                //console.log("valueIndex", valueIndex!)

                entity.values!.forEach(element => {
                    let values = Object.values(element)
                    if (values[keyIndex] === id) {
                        value = values[valueIndex]
                    }
                });

                //let value = entity.values!.find((e, index) => e[index][keyIndex] === id)
                //console.log(value)

                if (value) {
                    return value
                }
            }


        }

    }

    const setOptions = (response: any, fieldName: string, entityid: number, labelfield?: string, valuefield?: string) => {
        if (response) {
            //console.log("setOptions fields", fieldName)
            //console.log("setOptions ", response)
            //console.log("setOptions entidades", entities)

            //carga y formatea los datos que se leen de entidades genéricas.
            if (entityid !== 0) {
                response = load(response.data, fieldName, labelfield!, valuefield!)
                //console.log("load: " + fieldName, entityid, response)
            }


            let entity = entities.find((e) => e.name === fieldName)

            if (entity) {
                entity.loaded = true;
                entity.values = response
            } else {
                entities.push({ id: entityid, name: fieldName, loaded: true, labelfield: labelfield, valuefield: valuefield, values: response })
            }
            //console.log(entities)
        }
    };

    const load = (data: genericentityrowDTO[], fieldName: string, labelfield: string, valuefield: string) => {

        //buscar información de los campos a presentar el que contiene el id y la columna descripción/nombre
        //se busca por el nombre del campo
        if (data) {

            let _records: any[];
            data.forEach(element => {
                let [cols] = [Object.values(element)]
                let fields: [{ name: string, value: any, id: number }] = (JSON.parse(cols[2]))
                //console.log(fields)
                let _values = {};
                fields.forEach(e => {
                    //console.log(e.name)
                    if (e.name === labelfield || e.name === valuefield) {
                        var key = e.name,
                            obj = {
                                [key]: e.value
                            }
                        _values = { ..._values, ...obj }
                    }
                });
                if (_records) {
                    _records = [..._records, _values]
                } else {
                    _records = [_values]
                }
            });
            //console.log(_records!)
            return _records!
        }
    };

    return (
        <EntitiesContext.Provider value={{
            entities: entities,
            getEntity,
            getValue,
            setUserData,
            user: user!
        }}>
            {props.children}
        </EntitiesContext.Provider>
    )

};


export default EntitiesState;
