import { Dropdown } from 'primereact/dropdown'
import { useState, useEffect } from 'react';
import { ITableColumn } from 'utils/interfaces';
import { genericentityrowDTO } from 'app/dto/genericentityrow.model'
import { GenericService } from 'app/service/GenericService';
import classNames from 'classnames';
import MostrarErrores from "utils/MostrarErrores";

export default function AGDropdown(props: agdropdownProps) {

    const [value, setValue] = useState(undefined)
    const [loading, setLoading] = useState(true);
    const [loadingDefault, setLoadingDefault] = useState(false);
    const [lastQueryExpression, setLastQueryExpression] = useState();
    const [errores, setErrores] = useState<string[]>([]);

    const service = new GenericService();

    type optionsEntity = {
        name: string,
        loaded: boolean,
        values: []
    }

    const [loadedOptions] = useState<optionsEntity[]>([]);

    useEffect(() => {

        //console.log("props", props, loading)
        //console.log("AGDropdown props", props)
        //console.log(props.col && props.params !== lastPropsParam)
        //console.log(props.col.optionsFromEndPoint !== undefined)
        //console.log(props.id === undefined && lastPropsId !== 0)
        //console.log(!loaded(props.col.field))
        //console.log("props", loading, props.id, props.params)

        // if ((props.col && loading) || (props.col && props.id !== lastPropsId) || (props.col && props.params !== lastPropsParam)) {

        let updateQuery = false;

        if (props.options) {
            setOptions(props.options, props.col.field, 0, true)
        }

        if (props.col) {
            if (props.col.optionModeQuery === 'id') {
                if (props.id === undefined) {
                    setLastQueryExpression(undefined)
                    setValue(undefined)
                    setOptions(undefined, props.col.field, 0, false, true)
                }
            }

            if (loading || props.id || props.params) {
                //lee las opciones de la base de datos
                //debugger
                if (props.col.optionsFromEndPoint !== undefined) {
                    //console.log("useEffect para dropdown ", element.optionsFromEndPoint + ' id ' + element.optionsEntityId)
                    if (props.col.optionModeQuery === 'id') {
                        if (props.id !== lastQueryExpression) {
                            updateQuery = true;
                            setLastQueryExpression(props.id);
                        }
                    }
                    if (props.col.optionModeQuery === 'param') {
                        if (props.params) {
                            if (Object.values(props.params).filter(v => v === undefined).length !== 0) {
                                //setOptions({ success: true, data: [], error: undefined }, props.col.field, 0, true, true)
                                setOptions(undefined, props.col.field, 0, false, true)
                            } else {
                                if (props.params !== lastQueryExpression) {
                                    updateQuery = true;
                                    setLastQueryExpression(props.params);
                                }
                            }
                        }
                    }

                    //console.log("id, param, lastExpression, update:", props.id, props.params, props.lastQueryExpression, updateQuery)
                    //console.log("updateQuery", updateQuery)

                    if (!loaded(props.col.field) || updateQuery) {
                        if (props.col.optionsEntityId === undefined) {
                            //console.log("AGDropdown Id: ", props.id)
                            switch (props.col.optionModeQuery) {
                                case 'id':
                                    service.getId({ url: props.col.optionsFromEndPoint, id: props.id, dataFromObject: props.col.optionsFromEndPointDataFromObjectName }).then(response => {
                                        if (response.success) {
                                            setOptions(response, props.col.field, 0, true, true)
                                        } else {
                                            setErrores(response.error)
                                        }
                                    })
                                    break;
                                case 'param':
                                    //console.log("parametos", props.params)
                                    service.getId({ url: props.col.optionsFromEndPoint, params: props.params, dataFromObject: props.col.optionsFromEndPointDataFromObjectName }).then(response => {
                                        if (response.success) {
                                            setOptions(response, props.col.field, 0, true, true)
                                        } else {
                                            setErrores(response.error)
                                        }
                                    })
                                    break;

                                default:
                                    service.get({ url: props.col.optionsFromEndPoint }).then(response => {
                                        if (response.success) {
                                            setOptions(response.data, props.col.field, 0, true)
                                        } else {
                                            setErrores(response.error)
                                        }
                                    })
                                    break;

                            }
                        } else {
                            service.getId({ url: props.col.optionsFromEndPoint, id: props.col.optionsEntityId }).then(response => {
                                if (response.success) {
                                    //console.log(response)
                                    setOptions(response, props.col.field, props.col.optionsEntityId!, true)
                                }
                            });
                        }
                    }
                } else {
                    //lista que se encuentra hardcodeada
                    if (props.col.options) {
                        setOptions(props.col.options, props.col.field, 0, true)
                    }
                }

                setLoading((x) => x = false)
                setLoadingDefault((x) => x = true)
            } else {
                //console.log("establece valor")
                setValue(props.value)
                //console.log("valor", props.value)
            }


        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.col, props.id, props.params, props.options])


    useEffect(() => {
        if (props.value && loadingDefault) {
            if (!value) {
                setValue((x) => x = props.value)
                setLoadingDefault((x) => x = false)
            }
        }
    }, [loadingDefault, props.value, value])

    function compareObj(a: any, b: any) {
        var aKeys = Object.keys(a).sort();
        var bKeys = Object.keys(b).sort();
        if (aKeys.length !== bKeys.length) {
            return false;
        }
        if (aKeys.join('') !== bKeys.join('')) {
            return false;
        }
        for (var i = 0; i < aKeys.length; i++) {
            if (a[aKeys[i]] !== b[bKeys[i]]) {
                return false;
            }
        }
        return true;
    }


    const setOptions = (response: any, fieldName: string, entityid: number, refresh?: boolean, fromData?: boolean) => {
        if (response) {
            //console.log("setOptions fields", fieldName)
            //console.log("setOptions ", response)

            //carga y formatea los datos que se leen de entidades genéricas.
            if (entityid !== 0) {
                response = load(response.data, fieldName)
                //console.log("load: " + fieldName, entityid, response)
            }
            loadedOptions.forEach(element => {
                //console.log(element.name, element.loaded)
                if (element.name === fieldName) {
                    element.loaded = true;
                    if (fromData) {
                        element.values = response.data
                    } else {
                        element.values = response
                    }

                    //console.log(element.values)

                    if (value) {
                        let exist = false;
                        //debugger
                        element.values.forEach(element => {
                            if (compareObj(element, value)) {
                                exist = true;
                            }
                        });
                        if (!exist) {
                            setValue(undefined)
                        }
                    }

                }
            });

            if (refresh) {
                setLoading(true)
            }
            if (props.onLoaded)
                props.onLoaded!(getOptions(fieldName)!)


        } else {
            loadedOptions.forEach(element => {
                if (element.name === fieldName) {
                    element.loaded = true;
                    element.values = []
                }
            });
            setValue(undefined)
            if (props.onLoaded)
                props.onLoaded!(getOptions(fieldName)!)

        }
    }

    const load = (data: genericentityrowDTO[], fieldName: string) => {
        //buscar información de los campos a presentar el que contiene el id y la columna descripción/nombre
        //se busca por el nombre del campo
        let label: string;
        let value: string;

        if (data) {
            if (props.col.field === fieldName) {
                label = props.col.optionName!;
                value = props.col.optionValue ? props.col.optionValue : 'Id';
            }
            let _records: any[];
            data.forEach(element => {
                let [cols] = [Object.values(element)]
                let fields: [{ name: string, value: any, id: number }] = (JSON.parse(cols[2]))
                //console.log(fields)
                let _values = {};
                fields.forEach(e => {
                    //console.log(e.name)
                    if (e.name === label || e.name === value) {
                        var key = e.name,
                            obj = {
                                [key]: e.value
                            }
                        _values = { ..._values, ...obj }
                    }
                });
                if (_records) {
                    _records = [..._records, _values]
                } else {
                    _records = [_values]
                }
            });
            return _records!
        }
    }

    const loaded = (fieldName: string) => {
        let _loaded = false;
        loadedOptions.forEach(element => {
            //console.log(element)
            if (element.name === fieldName) {
                if (element.loaded)
                    _loaded = true;
            }
        });
        return _loaded
    }

    const getOptions = (fieldName: string) => {

        let optionIndex = -1;
        if (fieldName !== '') {

            for (let index = 0; index < loadedOptions.length; index++) {
                const element = loadedOptions[index];
                if (element.name === fieldName) {
                    if (element.loaded)
                        return element.values!
                    else
                        optionIndex = index
                }
            }

            if (optionIndex === -1) {
                //console.log("getOptions", fieldName + " / " + optionIndex)
                loadedOptions.push({ name: fieldName, loaded: false, values: [] })
                optionIndex = loadedOptions.length - 1
                return loadedOptions[optionIndex].values!
                //console.log(fieldName, optionIndex)
            }
        }
    }

    const style = {
        marginTop: '0.3rem'
    }

    return (
        <>
            {!props.col.hideLabel ? <label htmlFor={props.col.field}>{props.col.header}</label> : null}
            <Dropdown id={props.col.field} disabled={props.col.disabledEdit || (props.disabledUpdate && props.updateMode)} value={value} style={style} placeholder={'---seleccione---'} onChange={(e) => {
                //console.log("onChange AGDropdown ", e)
                setValue(e.target.value)
                if (props.onChanged) { props.onChanged!(e.target.value, getOptions(props.col.field)) }
            }} options={getOptions(props.col.field)} optionLabel={props.col.optionName!} filter={props.col.optionFilter} filterBy={props.col.optionName} emptyMessage={'No hay registros'} emptyFilterMessage={'No hay resultados'} showClear showFilterClear ariaLabel={'Lista ' + props.col.field} optionValue={props.col.optionValue!} showOnFocus={props.col.showOnFocus} required={props.col.required} autoFocus={props.col.autoFocus} className={classNames({ 'p-invalid': props.submitted && !value && props.col.required })} />
            {props.submitted && !value && props.col.required && <small className="p-invalid">El valor de {props.col.header} es requerido</small>}
            <MostrarErrores errores={errores} />
        </>
    )
}

interface agdropdownProps {
    id?: any;
    params?: any;
    requeridid?: boolean,
    disabledUpdate?: boolean;
    updateMode?: boolean;
    value?: any;
    submitted?: boolean;
    options?: any;
    onChanged?: (value: any, options?: any) => void;
    onUpdateQueryExpression?: (query: any) => void;
    onLoaded?: (values: any[]) => void;
    col: ITableColumn;
}