import { useContext, useEffect, useState } from "react";
import { IUsuarioPermisosDTO } from '../dto/usuarios.model';
import { DataTable } from 'primereact/datatable';
import { ITableColumn } from '../../utils/interfaces';
import EntitiesContext from 'context/EntitiesContext';
import { Column } from "primereact/column";

export default function PerfilPermisos() {

    const [rows, setRows] = useState<IUsuarioPermisosDTO[]>();
    const entitiesContext = useContext(EntitiesContext);

    //console.log(entitiesContext.user.permisos)
    useEffect(() => {
        if (entitiesContext.user) {
            if (entitiesContext.user.permisos?.length !== 0) {
                setRows(entitiesContext.user.permisos)
            }
        }
    }, [entitiesContext.user])


    const columns: ITableColumn[] = [
        { id: 0, field: 'userid', hide: true, isKey: true, disabled: true, header: 'UserId', sortable: false },
        { id: 1, field: 'recurso', header: 'Entidad', sortable: true },
        { id: 2, field: 'consultar', header: 'Consultar', type: 'boolean', sortable: true },
        { id: 3, field: 'agregar', header: 'Agregar', sortable: true },
        { id: 4, field: 'editar', header: 'Editar', sortable: true },
        { id: 5, field: 'eliminar', header: 'Eliminar', sortable: true },
        { id: 6, field: 'activar', hide: true, header: 'Activar', sortable: true },

    ]

    const bodyTemplate = (data: any, colProps: any) => {
        switch (colProps.field) {
            case 'recurso':
                return data[colProps.field]

            default:
                if (data[colProps.field] === false)
                    return (
                        <i className="pi pi-lock" style={{ 'color': 'red', 'fontSize': 17 }} ></i>
                    )
                else
                    //return 'Sí'
                    return (
                        <i className="pi pi-lock-open" style={{ 'color': 'green', 'fontSize': 17 }} ></i>
                    )
        }
    }


    return (
        <>
            <h5>Permisos</h5>
            <DataTable value={rows!} resizableColumns columnResizeMode="fit" paginator={false} className="p-datatable-sm" rows={20} rowHover sortMode={'multiple'} stripedRows loadingIcon={'pi pi-spinner'}
                emptyMessage="No hay registros."  >
                {columns!.map(col =>
                    !col.hide ?
                        <Column className={col.align ? 'p-text-' + col.align : 'p-text-left'} field={col.showField ? col.showField : !col.fieldcamelCase ? col.field : col.fieldcamelCase} key={col.id} header={col.header} filterType={col.type}
                            sortable={col.sortable} body={bodyTemplate}>
                        </Column> : null)}
                {/* <Column body={actionBodyTemplate} className="p-text-center" header="Acciones"></Column> */}
            </DataTable>
        </>
    )

}