import Cargando from "utils/Cargando";
import React, { useState, useEffect, useRef, useContext } from 'react'
import { Button } from 'primereact/button'
import { Divider } from 'primereact/divider'
import { Dialog } from 'primereact/dialog';
import { ITableColumn } from 'utils/interfaces'
import { IUsuarioPermisosDTO, perfilusuarioDTO, permisosCreacionDTO, permisoDetalleCreacionDTO } from 'app/dto/usuarios.model'
import { Toolbar } from 'primereact/toolbar';
import EntitiesContext from 'context/EntitiesContext'
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import * as func from 'utils/formDataUtils'
import * as userControl from 'auth/UserDataControl'
import { InputSwitch } from 'primereact/inputswitch';
import { urlUsuarioPermisos } from 'app/service/Endpoints'
import { GenericService } from 'app/service/GenericService';


export default function Permisos(props: permisosProps) {
    const [loading, setLoading] = useState(true)
    const [dialog, setDialog] = useState(true)
    const [rows, setRows] = useState<IUsuarioPermisosDTO[]>();
    const [user, setUser] = useState<perfilusuarioDTO>();

    const entitiesContext = useContext(EntitiesContext);
    const service = new GenericService();

    const toast = useRef<Toast>(null);

    const columns: ITableColumn[] = [
        { id: 0, field: 'id', isKey: true, hide: true, disabled: true, header: 'Id' },
        { id: 1, field: 'recurso', hide: false, required: true, colInput: 0, type: 'string', header: 'Proceso' },
        { id: 2, field: 'consultar', hide: false, required: true, colInput: 1, type: 'switch', header: 'Consultar' },
        { id: 3, field: 'agregar', hide: false, required: true, colInput: 2, type: 'switch', header: 'Agregar' },
        { id: 4, field: 'editar', hide: false, required: true, colInput: 3, type: 'switch', header: 'Editar' },
        { id: 5, field: 'eliminar', hide: false, required: true, colInput: 4, type: 'switch', header: 'Eliminar' },
        { id: 6, field: 'activar', hide: false, required: true, colInput: 5, type: 'switch', header: 'Activar' },
    ]
    async function AddResource() {
        const resourcesList: any[] = ['Órden de trabajo', 'Stock', 'Empresas', 'Depósitos', 'Establecimientos', 'Lotes', 'Lotes Asociados', 'Campañas', 'Especies', 'Insumos', 'Insumo Aplicación', 'Contactos', 'Labores', 'Estadios', 'Unidades', 'Entidades genéricas']

        let resource: IUsuarioPermisosDTO;
        let resources: IUsuarioPermisosDTO[];

        resourcesList.forEach(element => {
            resource = {
                recurso: element,
                consultar: false,
                agregar: false,
                editar: false,
                eliminar: false,
                activar: false
            }
            if (resources)
                resources.push(resource)
            else
                resources = [resource]
        });

        let response: perfilusuarioDTO | undefined = await userControl.LoadUser({ id: props.id })
        //console.log("response AddResource ", response)

        //busca en cada uno de los recursos las configuración descargada de la base de datos
        resources!.forEach((element, index) => {
            let permiso = response?.permisos?.find(x => x.recurso === element.recurso)
            if (permiso !== undefined) {
                resources[index].consultar = permiso.consultar
                resources[index].agregar = permiso.agregar
                resources[index].editar = permiso.editar
                resources[index].eliminar = permiso.eliminar
                resources[index].activar = permiso.activar
            }
        });

        setUser(response)

        setRows(resources!)

    }

    useEffect(() => {
        if (loading) {
            AddResource()
            setLoading((x) => !x)
        }
    }, []);

    const toolbarLeftTemplate = () => {
        return (
            <>
                <Button label="" icon="pi pi-check" className="p-button-success" onClick={() => {
                    //let newRows = rows!
                    //console.log(rows)
                    let _rows = [...rows!];
                    _rows!.map((x) => {
                        x.consultar = true;
                        x.agregar = true;
                        x.editar = true;
                        x.eliminar = true;
                        x.activar = true
                    })
                    //alert("Tildar todo")
                    //console.log("Tildar todo")
                    //console.log(rows)

                    setRows(_rows)
                }} style={{ marginRight: '.5em' }} tooltip="Seleccionar todos" tooltipOptions={{ position: 'bottom' }} />

                <Button icon="pi pi-ban" className="p-button-danger" onClick={() => {
                    let _rows = [...rows!];
                    _rows!.map((x) => {
                        x.consultar = false;
                        x.agregar = false;
                        x.editar = false;
                        x.eliminar = false;
                        x.activar = false
                    })
                    setRows(_rows)
                    //alert("Destildar todo")
                    //console.log("Destildar todo")
                }} style={{ marginRight: '.5em' }} tooltip="Deseleccionar todos" tooltipOptions={{ position: 'bottom' }} />
                {/* <Button icon="pi pi-file-o" onClick={() => exportCSV(false)} className="p-mr-2" data-pr-tooltip="CSV" style={{ marginRight: '.5em' }} /> */}

            </>
        )
    };

    const HeaderTemplate = () => {
        return (
            <div >
                Configuración de permisos.
            </div>
        )
    }

    const DialogFooter = () => {
        return (
            <>
                <Divider />
                <Button label="Cancelar" icon="pi pi-times" className="p-button-text  p-button-danger" onClick={hideDialog} />
                <Button label="Guardar" icon="pi pi-check" className="p-button-text p-button-primary" onClick={save} />
            </>
        )
    };

    const actionBodyTemplate = (rowData: any) => {
        return (
            <div>
                <Button icon="pi pi-check" className="p-button-rounded p-button-success" onClick={() => {
                    let _row = rows!.find(x => x.recurso === rowData['recurso'])
                    if (_row !== undefined) {
                        _row.consultar = true
                        _row.agregar = true
                        _row.editar = true
                        _row.eliminar = true
                        _row.activar = true
                        let _rows = [...rows!, _row];
                        setRows(_rows)
                    }
                }} style={{ height: 25, width: 25 }} />
                <span> </span>
                <Button icon="pi pi-ban" className="p-button-rounded p-button-danger" onClick={() => {
                    let _row = rows!.find(x => x.recurso === rowData['recurso'])
                    if (_row !== undefined) {
                        _row.consultar = false
                        _row.agregar = false
                        _row.editar = false
                        _row.eliminar = false
                        _row.activar = false
                        let _rows = [...rows!, _row];
                        setRows(_rows)
                    }
                }} style={{ height: 25, width: 25 }} />

            </div>
        );
    }

    const hideDialog = () => {
        props.close!(); setDialog(false)
    }

    const bodyTemplate = (data: any, colProps: any) => {
        //console.log(data)
        let row = columns?.filter((f: any) => f.field === colProps.field)
        const { type } = row[0]
        //console.log(type)
        let style: string = ''
        return (
            type === 'switch'
                ?
                <InputSwitch checked={data[row[0].field]} onChange={() => {
                    let _rows = [...rows!];
                    data[row[0].field] = !data[row[0].field]
                    setRows(_rows)
                }} ></InputSwitch>
                :
                <React.Fragment>
                    <span className={style}>
                        {
                            func.bodyTemplate(data, colProps, { columns: columns })
                        }
                    </span>
                </React.Fragment>
        )
    };

    const save = () => {

        //console.log("save ", rows)
        //let dataPermisos: permisoDetalleCreacionDTO;
        let permisos: permisoDetalleCreacionDTO[];

        rows!.forEach((element, index) => {
            //console.log(index)
            //console.log(element.recurso.toString())
            if (permisos) {
                permisos.push({
                    recurso: element.recurso,
                    consultar: element.consultar,
                    agregar: element.agregar,
                    editar: element.editar,
                    eliminar: element.eliminar,
                    activar: element.activar
                })
            } else {
                permisos = [{
                    recurso: element.recurso,
                    consultar: element.consultar,
                    agregar: element.agregar,
                    editar: element.editar,
                    eliminar: element.eliminar,
                    activar: element.activar
                }]
            }

        });

        let data: permisosCreacionDTO = {
            iduser: props.id,
            permisos: permisos!,
        }

        service.post({ url: urlUsuarioPermisos, creacion: data }).then(response => {
            if (response.success) {
                toast.current!.show({ severity: 'success', summary: "Permisos de usuario", detail: 'Se han registrados los permisos', life: 2000 });
            } else {
                //setErrores(res.error);
                //props.close!();
            }
        }).catch(error => { console.log(error) });

        const userData = userControl.RefreshAutorizaciones(user!, permisos!)
        console.log("userData ", userData)
        entitiesContext.setUserData(userData!)

        props.close!(); setDialog(false)
    }


    return (
        <>
            {loading ? <Cargando /> :
                <Dialog className='crud-dialog' closable={true} modal closeOnEscape={true} visible={dialog} blockScroll={true} position={'right'} header={HeaderTemplate} footer={DialogFooter} onHide={() => { props.close!(); setDialog(false) }} >
                    <h5>Usuario: {user?.apellido + ', ' + user?.nombres} </h5>
                    <Toolbar left={toolbarLeftTemplate}></Toolbar>
                    <Toast ref={toast} />

                    <br />
                    <DataTable value={rows!} resizableColumns columnResizeMode="fit" paginator={true} className="p-datatable-sm" rows={10} rowHover sortMode={'multiple'} stripedRows loadingIcon={'pi pi-spinner'}
                        emptyMessage="No hay registros." loading={loading} >
                        {columns!.map(col =>
                            !col.hide ?
                                <Column className={col.align ? 'p-text-' + col.align : 'p-text-left'} field={col.showField ? col.showField : !col.fieldcamelCase ? col.field : col.fieldcamelCase} key={col.id} header={col.header} filterType={col.type}
                                    sortable={col.sortable} body={bodyTemplate}>
                                </Column> : null)}
                        <Column body={actionBodyTemplate} className="p-text-center" header="Acciones"></Column>
                    </DataTable>
                    {/* <MostrarErrores errores={errores} /> */}
                </Dialog>}
        </>
    )



}





interface permisosProps {
    id: number,
    close?: (mensaje?: string) => void;
    save?: (row: any) => void;

}