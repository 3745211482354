import { useEffect, useState } from "react";
import './home.css'
import './nicepage.css'
import { Link } from 'react-router-dom';
import { Login } from "auth/Login";
import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';

export default function LandingPage(props: any) {

    const [iniciarSesion, setIniciarSesion] = useState(false)

    useEffect(() => {

    }, [])

    return (
        <>
            <Dialog closable={true} visible={iniciarSesion} header={'AgroControl'} focusOnShow onHide={() => {
                //props.cancel && props.cancel!();
                setIniciarSesion(false)
            }} >
                <Login login={() => {
                    setIniciarSesion(false);
                    props.onToggleMenuClick!();
                }} />
            </Dialog>

            <body className="u-backlink-hidden u-body">
                <section className="u-clearfix u-section-1" id="sec-cc32">
                    <div className="u-clearfix u-sheet u-sheet-1">
                        <div className="u-expanded-width-xs u-image u-image-default u-image-1" />
                        <div className="u-container-style u-group u-group-1">
                            <div className="u-container-layout u-container-layout-1">
                                <h2 className="u-heading-font u-text u-text-palette-1-base u-text-1">agrocontrol</h2>
                                <h1 className="u-heading-font u-text u-text-2">la solucion definitiva para la gestion de sus cultivos</h1>
                                <div className="u-border-3 u-border-palette-1-base u-line u-line-horizontal u-line-1"></div>
                                <p className="u-text u-text-font u-text-3">Tecnología puesta al servicio del productor, AgroControl es una herramienta robusta e intuitiva que cambiará para siempre la gestión de su negocio.</p>
                                <Button onClick={() => setIniciarSesion(true)} label="INGRESAR" className="u-btn u-btn-round u-button-style u-custom u-palette-1-base u-radius-4 u-text-white u-btn-1" />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="u-clearfix u-white u-section-2" id="sec-e8bd">
                    <div className="u-clearfix u-sheet u-sheet-1">
                        <div className="u-align-center u-container-style u-group u-similar-fill u-group-1">
                            <div className="u-container-layout u-container-layout-1">
                                <h3 className="u-heading-font u-text u-text-palette-1-base u-text-1">REGÍSTRESE</h3>
                                <h3 className="u-heading-font u-text u-text-palette-1-base u-text-1">&</h3>
                                <h2 className="u-heading-font u-text u-text-2">ACCEDA A NUESTRAS HERRAMIENTAS</h2>
                                <div className="u-border-3 u-border-palette-1-base u-line u-line-horizontal u-line-1"></div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="u-clearfix u-white u-section-3" id="sec-ffe9">
                    <div className="u-clearfix u-expanded-width u-layout-wrap u-layout-wrap-1">
                        <div className="u-layout">
                            <div className="u-layout-row">
                                <div className="u-container-style u-image u-layout-cell u-left-cell u-shading u-similar-fill u-size-20 u-size-20-md u-image-1" data-image-width="866" data-image-height="577">
                                    <div className="u-container-layout u-valign-bottom">
                                        <h3 className="u-heading-font u-text u-text-1">GESTION DE LABORES</h3>
                                        <div className="u-border-3 u-border-palette-1-base u-line u-line-horizontal u-line-1"></div>
                                        <p className="u-text u-text-font u-text-2">Genere órdenes de trabajo de una manera ágil y controlada.</p>
                                    </div>
                                </div>
                                <div className="u-container-style u-image u-layout-cell u-shading u-similar-fill u-size-20 u-size-20-md u-image-2" data-image-width="445" data-image-height="445">
                                    <div className="u-container-layout u-valign-bottom">
                                        <h3 className="u-heading-font u-text u-text-3">CONTROL DE STOCK</h3>
                                        <div className="u-border-3 u-border-palette-1-base u-line u-line-horizontal u-line-2"></div>
                                        <p className="u-text u-text-font u-text-4">Planifique sus campañas teniendo un amplio control de los insumos que posee y necesita.</p>
                                    </div>
                                </div>
                                <div className="u-container-style u-image u-layout-cell u-right-cell u-shading u-similar-fill u-size-20 u-size-20-md u-image-3" data-image-width="2560" data-image-height="1707">
                                    <div className="u-container-layout u-valign-bottom">
                                        <h3 className="u-heading-font u-text u-text-5">RENTABILIDAD</h3>
                                        <div className="u-border-3 u-border-palette-1-base u-line u-line-horizontal u-line-3"></div>
                                        <p className="u-text u-text-6" data-animation-name="" data-animation-duration="0" data-animation-delay="0" data-animation-direction=""> Partiendo de los costos de producción, conozca la rentabilidad de la campaña.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="u-clearfix u-white u-section-4" id="sec-4b72">
                    <div className="u-clearfix u-sheet u-sheet-1">
                        <div className="u-clearfix u-expanded-width u-layout-wrap u-layout-wrap-1">
                            <div className="u-layout">
                                <div className="u-layout-row">
                                    <div className="u-container-style u-layout-cell u-left-cell u-similar-fill u-size-30 u-layout-cell-1">
                                        <div className="u-container-layout u-container-layout-1">
                                            <h3 className="u-heading-font u-text u-text-palette-1-base u-text-1">UN DESARROLO DE</h3>
                                            <h2 className="u-heading-font u-text u-text-2">SISTEMAS KEOPS</h2>
                                            <div className="u-border-3 u-border-palette-1-base u-line u-line-horizontal u-line-1"></div>
                                            <p className="u-text u-text-font u-text-grey u-text-3">Más de 30 años brindando soluciones a nuestros clientes.</p>
                                        </div>
                                    </div>
                                    <div className="u-container-style u-layout-cell u-right-cell u-similar-fill u-size-30 u-layout-cell-2">
                                        <div className="u-container-layout">
                                            <p className="u-text u-text-font u-text-4">Nuestra fortaleza ha sido siempre Ud. el cliente, todas nuestras acciones están dirigidas a un solo objetivo, hacer su vida más simple,&nbsp; optimizando la gestión de su negocio.&nbsp;<br />
                                                <br />Constantemente adaptamos nuestros productos a las necesidades cambiantes del cliente y del mercado. Nuevos requerimientos impositivos, nuevas formas de comercialización, cambios en las estrategias de la empresa, etc.<br />
                                                <br />
                                            </p>
                                            <a href="#" className="u-btn u-btn-round u-button-style u-custom u-palette-1-base u-radius-4 u-text-white u-btn-1">LEER MAS</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="u-clearfix u-white u-section-5" id="sec-0e4e">
                    <div className="u-clearfix u-sheet u-sheet-1">
                        <div className="u-align-center u-container-style u-group u-similar-fill u-group-1">
                            <div className="u-container-layout u-valign-middle u-container-layout-1">
                                <h3 className="u-heading-font u-text u-text-palette-1-base u-text-1"><span className="u-icon u-icon-1"><svg className="u-svg-content" viewBox="0 0 58 58" x="0px" y="0px" style={{ width: '1em', height: '1em' }}><g><path d="M54.319,37.839C54.762,35.918,55,33.96,55,32c0-9.095-4.631-17.377-12.389-22.153c-0.473-0.29-1.087-0.143-1.376,0.327
		c-0.29,0.471-0.143,1.086,0.327,1.376C48.724,15.96,53,23.604,53,32c0,1.726-0.2,3.451-0.573,5.147C51.966,37.051,51.489,37,51,37
		c-3.86,0-7,3.141-7,7s3.14,7,7,7s7-3.141,7-7C58,41.341,56.509,39.024,54.319,37.839z M51,49c-2.757,0-5-2.243-5-5s2.243-5,5-5
		s5,2.243,5,5S53.757,49,51,49z"></path><path d="M38.171,54.182C35.256,55.388,32.171,56,29,56c-6.385,0-12.527-2.575-17.017-7.092C13.229,47.643,14,45.911,14,44
		c0-3.859-3.14-7-7-7s-7,3.141-7,7s3.14,7,7,7c1.226,0,2.378-0.319,3.381-0.875C15.26,55.136,21.994,58,29,58
		c3.435,0,6.778-0.663,9.936-1.971c0.51-0.211,0.753-0.796,0.542-1.307C39.267,54.213,38.681,53.971,38.171,54.182z M2,44
		c0-2.757,2.243-5,5-5s5,2.243,5,5s-2.243,5-5,5S2,46.757,2,44z"></path><path d="M4,31.213c0.024,0.002,0.048,0.003,0.071,0.003c0.521,0,0.959-0.402,0.997-0.93c0.712-10.089,7.586-18.52,17.22-21.314
		C23.142,11.874,25.825,14,29,14c3.86,0,7-3.141,7-7s-3.14-7-7-7c-3.851,0-6.985,3.127-6.999,6.975
		C11.42,9.922,3.851,19.12,3.073,30.146C3.034,30.696,3.449,31.175,4,31.213z M29,2c2.757,0,5,2.243,5,5s-2.243,5-5,5s-5-2.243-5-5
		S26.243,2,29,2z"></path>
                                </g></svg></span>
                                </h3>
                                <h2 className="u-heading-font u-text u-text-2">PLANES</h2>
                                <div className="u-border-3 u-border-palette-1-base u-line u-line-horizontal u-line-1"></div>
                            </div>
                        </div>
                        <div className="u-clearfix u-disable-padding u-expanded-width u-gutter-20 u-layout-wrap u-layout-wrap-1">
                            <div className="u-layout">
                                <div className="u-layout-row">
                                    <div className="u-align-left u-container-style u-layout-cell u-left-cell u-similar-fill u-size-20 u-size-20-md u-layout-cell-1">
                                        <div className="u-container-layout">
                                            <div className="u-expanded-width u-image u-image-1" />
                                            <h3 className="u-heading-font u-text u-text-3">GRATUITO</h3>
                                            <h4 className="u-align-left u-heading-font u-text u-text-grey u-text-4">1 Empresa / 1 Campaña / 1 Usuario</h4>
                                            <p className="u-text u-text-5"> Con el objetivo de que conozca nuestro producto, le ofrecemos éste plan en donde Ud. podrá realizar todas las acciones asociadas a una sola campaña.</p>
                                            <a href="#" className="u-border-1 u-custom u-link u-text-black u-link-1">leer más</a>
                                        </div>
                                    </div>
                                    <div className="u-align-left u-container-style u-layout-cell u-similar-fill u-size-20 u-size-20-md u-layout-cell-2">
                                        <div className="u-container-layout">
                                            <div className="u-expanded-width u-image u-image-2" />
                                            <h3 className="u-heading-font u-text u-text-6">CONTROL&nbsp;</h3>
                                            <h4 className="u-heading-font u-text u-text-grey u-text-7">1 Empresa / 3 Usuarios /&nbsp;<span className="u-file-icon u-icon u-icon-2"><img src="images/875089.png" alt="" /></span> Campañas
                                            </h4>
                                            <p className="u-text u-text-8"> Pensado para pequeños productores que quieran ingresar en el mundo de la gestión digital.</p>
                                            <a href="#" className="u-border-1 u-custom u-link u-text-black u-link-2"> leer más</a>
                                        </div>
                                    </div>
                                    <div className="u-align-left u-container-style u-layout-cell u-right-cell u-similar-fill u-size-20 u-size-20-md u-layout-cell-3">
                                        <div className="u-container-layout">
                                            <div className="u-expanded-width u-image u-image-3" />
                                            <h3 className="u-heading-font u-text u-text-9">CONTROL TOTAL</h3>
                                            <h4 className="u-align-left u-heading-font u-text u-text-grey u-text-10">Multi-empresas / Usuarios / Campañas</h4>
                                            <p className="u-text u-text-11"> Pensado para grandes productores que desarrollan actividades de explotación para múltiples empresas y establecimientos.</p>
                                            <a href="#" className="u-border-1 u-custom u-link u-text-black u-link-3"> leer más</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="u-clearfix u-palette-1-base u-section-6" id="sec-4b3c">
                    <div className="u-clearfix u-sheet u-valign-middle-sm u-valign-middle-xs u-sheet-1">
                        <div className="u-clearfix u-expanded-width u-gutter-0 u-layout-wrap u-layout-wrap-1">
                            <div className="u-layout">
                                <div className="u-layout-row">
                                    <div className="u-container-style u-layout-cell u-left-cell u-size-34 u-layout-cell-1">
                                        <div className="u-container-layout u-valign-middle">
                                            <h2 className="u-heading-font u-text u-text-white u-text-1">AgroControl</h2>
                                            <p className="u-text u-text-body-alt-color u-text-2"> El control de su inversión en un solo lugar.</p>
                                        </div>
                                    </div>
                                    <div className="u-align-left-sm u-align-right-lg u-align-right-md u-align-right-xl u-align-right-xs u-container-style u-layout-cell u-right-cell u-similar-fill u-size-26 u-layout-cell-2">
                                        <div className="u-container-layout u-container-layout-2">
                                            <a href="#" className="u-border-2 u-border-white u-btn u-btn-round u-button-style u-custom u-radius-4 u-btn-1">CONTRATAR AHORA</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>





            </body>
        </>
    )

}