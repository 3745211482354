import { Ripple } from 'primereact/ripple';
import { classNames } from 'primereact/utils';
import { PaginatorTemplate } from 'primereact/paginator';
import { Dropdown } from 'primereact/dropdown'

export const dataTableTemplate: PaginatorTemplate = {
    layout: 'CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown',
    FirstPageLink: undefined,
    LastPageLink: undefined,
    JumpToPageInput: null,
    CurrentPageReport: (options) => {
        return (
            <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '150px', textAlign: 'left' }}>
                Registros {options.first} al {options.last} de {options.totalRecords}
            </span>
        )
    },
    PrevPageLink: undefined,
    NextPageLink: undefined,
    PageLinks: (options: any) => {
        if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
            const className = classNames(options.className, { 'p-disabled': true });
            return <span className={className} style={{ userSelect: 'none' }}>...</span>;
        }
        return (
            <button type="button" className={options.className} onClick={options.onClick}>
                {options.page + 1}
                <Ripple />
            </button>
        )
    },
    RowsPerPageDropdown: (options: any) => {
        const dropdownOptions = [
            { label: 10, value: 10 },
            { label: 20, value: 20 },
            { label: 50, value: 50 },
            { label: 'Todos', value: options.totalRecords }
        ];

        return (
            <>
                <span className="p-mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>Items por página: </span>
                <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} appendTo={document.body} />
            </>
        );
    },


};


