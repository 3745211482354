import React, { useState, useEffect, useRef } from 'react';
import { AutoComplete } from 'primereact/autocomplete';
import { jsonService } from 'app/service/jsonService'
import Mapa from 'utils/Mapa'
import { coordenadaDTO } from 'utils/coordenadas.model'
import Cargando from 'utils/Cargando'
import classNames from 'classnames';

// const selectProvincia = () => {
//     if (provincias && props.provincia) {
//         provincias.forEach((p: any) => {
//             if (p.nombre === props.provincia) {
//                 return p    
//         }})}
// }

export default function Location(props: locationProps) {
    const service = new jsonService();

    let myRef = useRef(null);

    const [provincias, setProvincias] = useState<any>();
    const [provinciaSeleccionada, setProvinciaSeleccionada] = useState<any>();
    const [provinciasSeleccionadas, setProvinciasSeleccionadas] = useState<any>(null);

    const [departamentos, setDepartamentos] = useState<any>();
    const [departamentoSeleccionado, setDepartamentoSeleccionado] = useState<any>();
    const [departamentosfiltrado, setDepartamentosFiltrado] = useState<any>('');
    const [departamentosSeleccionados, setDepartamentosSeleccionados] = useState<any>(null);

    const [localidades, setLocalidades] = useState<any>();
    const [localidadesfiltrado, setLocalidadesFiltrado] = useState<any>('');
    const [localidadSeleccionada, setLocalidadSeleccionada] = useState<any>(null);
    const [localidadesSeleccionadas, setLocalidadesSeleccionadas] = useState<any>(null);

    const [coordenada, setCoordenada] = useState<coordenadaDTO>({ lat: -31.427438777130778, lng: -62.08448981788615 });
    const [zoom, setZoom] = useState(5)
    const [loading, setLoading] = useState(true)
    const [inicio, setInicio] = useState(true)

    useEffect(() => {

        service.GetProvincias().then(data => {
            //console.log(data.length)
            data.sort((a: any, b: any) => {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            })
            setProvincias(data)
        })

        service.GetDepartamentos().then(data => {
            setDepartamentos(data);
            //activarDepartamento(data, 'Córdoba', 'San Justo');
        });

        service.GetLocalidades().then(data => {
            setLocalidades(data);
            setLoading(false)
        });


    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    //        

    const loadDepartamentos = (provincia: any) => {
        //console.log("loadDepartamento", provincia)
        let ordenar = null;
        if (provincia && departamentos) {
            ordenar = departamentos.filter((d: any) => d.provincia.nombre === provincia)
        }
        else
            if (departamentos) {
                ordenar = departamentos
            }
        ordenar.sort((a: any, b: any) => {
            if (a.nombre > b.nombre) {
                return 1;
            }
            if (a.nombre < b.nombre) {
                return -1;
            }
            return 0;
        })

        setDepartamentosFiltrado(ordenar)

    }

    const loadLocalidad = (props: any) => {

        //console.log("loadLocalidad", props)
        if (props && localidades) {

            let _localidades = null;

            if (props.provincia) {
                _localidades = localidades.filter((d: any) => d.provincia.nombre === props.provincia.nombre)
            }
            //console.log(_localidades)

            if (props.departamento) {
                //console.log(props.departamento)
                if (props.provincia) {
                    _localidades = _localidades.filter((d: any) => d.departamento.nombre === props.departamento.nombre)
                } else {
                    _localidades = localidades.filter((d: any) => d.departamento.nombre === props.departamento.nombre)
                }
            }

            _localidades.sort((a: any, b: any) => {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            })
            //console.log("cantidad", _localidades.length)
            setLocalidadesFiltrado(_localidades)
        }
    }

    const posicionar = (e: any, zoom: number) => {
        if (e) {
            setCoordenada({ lat: e.lat, lng: e.lon, nombre: e.nombre })
        }
        setZoom(zoom)
    }



    const Activar = (props: any) => {
        //console.log("props", props)
        //console.log("departamentos", departamentos)
        //console.log(provincias)
        //alert("aaa")
        if (provincias && props.provinciaNombre) {
            provincias.forEach((p: any) => {
                if (p.nombre === props.provinciaNombre) {
                    setProvinciaSeleccionada(p)

                    //console.log(p)
                    datosProvincia(p)
                }
            });

            if (departamentos && props.departamentoNombre) {
                departamentos.forEach((p: any) => {
                    if (p.provincia.nombre === props.provinciaNombre && p.nombre === props.departamentoNombre) {
                        if (!departamentoSeleccionado) {
                            datosDepartamento(p)
                            setDepartamentoSeleccionado(p)
                        }
                    }
                });
            }

            if (localidades && props.localidadNombre) {
                let localidadesFiltrado = localidades.filter((localidad: any) => String(localidad.provincia.nombre).toLocaleUpperCase() === String(props.provinciaNombre).toLocaleUpperCase())
                //console.log(localidadesFiltrado)
                if (props.departamentoNombre) {
                    localidadesFiltrado = localidadesFiltrado.filter((localidad: any) => String(localidad.departamento.nombre).toLocaleUpperCase() === String(props.departamentoNombre).toLocaleUpperCase())
                    //console.log(localidadesFiltrado)
                }
                localidadesFiltrado.forEach((p: any) => {
                    //console.log(p.nombre, props.localidadNombre)
                    if (String(p.nombre).toLocaleUpperCase() === String(props.localidadNombre).toLocaleUpperCase()) {
                        setLocalidadSeleccionada(p)
                        posicionar(p.centroide, 12)
                    }
                });
            }

        }

        setInicio(false)

        return null
    }

    const datosProvincia = (provincia: any) => {
        //limpias los ingresos de departamento y localidad
        //console.log("Inicializa provincia", provincia)
        setDepartamentoSeleccionado('');
        setLocalidadSeleccionada('');
        setDepartamentosFiltrado('');
        setLocalidadesFiltrado('');
        //filtra los departamentos de la provincia seleccionada y las localidades
        if (provincia) {
            //console.log(e.value.nombre)
            posicionar(provincia.centroide, 5)
            loadDepartamentos(provincia.nombre);
            loadLocalidad({ provincia: provincia })
        }
    }

    const datosDepartamento = (departamento: any) => {
        //console.log("Inicializa departamento", departamento)
        if (departamento) {
            posicionar(departamento.centroide, 8)
            setLocalidadSeleccionada('');
            if (departamento.nombre) {
                //filtra localidades
                //console.log("nuevo", e.value)
                loadLocalidad({ departamento: departamento });
            }
        }
    }

    const searchProvincia = (event: { query: string }) => {
        //console.log(event.query)
        setTimeout(() => {
            let _filteredPronvincias;
            if (!event.query.trim().length) {
                _filteredPronvincias = [...provincias];
            }
            else {
                _filteredPronvincias = provincias.filter((provincia: any) => {
                    return provincia.nombre.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
            setProvinciasSeleccionadas(_filteredPronvincias);
        }, 10);
    }

    const searchDepartamento = (event: { query: string }) => {
        setTimeout(() => {
            let _filteredDepartamentos = null;
            if (!event.query.trim().length) {
                _filteredDepartamentos = [...departamentosfiltrado];
            }
            else {
                _filteredDepartamentos = departamentosfiltrado.filter((depto: any) => {
                    return depto.nombre.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }

            //console.log(_filteredDepartamentos.length)
            // if (_filteredDepartamentos.length === 1) {
            //     datosDepartamento(_filteredDepartamentos[0])
            //     setDepartamentoSeleccionado(_filteredDepartamentos[0]);
            //     setFocus('localidad')
            // }
            setDepartamentosSeleccionados(_filteredDepartamentos);
        }, 10);
    }

    const searchLocalidad = (event: { query: string }) => {
        setTimeout(() => {
            let _filteredLocalidades;
            if (!event.query.trim().length) {
                _filteredLocalidades = [...localidadesfiltrado];
            }
            else {
                _filteredLocalidades = localidadesfiltrado.filter((localidad: any) => {
                    return localidad.nombre.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
            setLocalidadesSeleccionadas(_filteredLocalidades);
        }, 10);
    }

    return (

        loading ? <Cargando /> :
            <>
                <div>
                    <div className="card" style={props.style!} >

                        <h6>Ubicación</h6>

                        {/* field="nombre" */}
                        {/* style={{ display: 'inline-flex' }} */}
                        <div className="inline-flex"  >
                            {/* style={{ marginRight: '1em', width: '20vw' }} */}
                            <div >
                                <AutoComplete id="provincia" style={{ marginRight: '1em', width: '20vw' }} ref={myRef} placeholder="Provincia*" autoHighlight value={provinciaSeleccionada} dropdown dropdownMode={'current'} forceSelection suggestions={provinciasSeleccionadas} completeMethod={searchProvincia} field="nombre"
                                    onFocus={() => {
                                        if (!provinciasSeleccionadas && provinciaSeleccionada) {
                                            searchProvincia({ query: provinciaSeleccionada.nombre })
                                        }
                                    }} onSelect={(e) => props.onSelect('provincia', e.value.nombre)} onChange={(e) => {
                                        // if (!e.value && provinciaSeleccionada) {
                                        //     console.log("value:", e.value)
                                        //     console.log("seleccionada:", provinciaSeleccionada)
                                        //     e.value = provinciaSeleccionada
                                        //     return
                                        // }
                                        //console.log("Provincia, evento change provincia", provinciaSeleccionada)
                                        //console.log("Provincia, evento change evento", e.value)
                                        setProvinciaSeleccionada(e.value);
                                        if (e.value) {

                                            if (provinciaSeleccionada) {
                                                if (provinciaSeleccionada.nombre !== e.value.nombre) {
                                                    datosProvincia(e.value)
                                                }
                                            } else {
                                                datosProvincia(e.value)
                                            }
                                        } else {
                                            datosProvincia(null)
                                        }
                                    }} className={classNames({ 'p-invalid': props.submitted && !provinciaSeleccionada && props.provinciaRequerido })} />
                                {props.submitted && !provinciaSeleccionada && props.provinciaRequerido && <small className="p-invalid">Debe indicar la provincia</small>}
                            </div>

                            <div >
                                <AutoComplete placeholder="Departamento" style={{ marginRight: '1em', width: '20vw' }} value={departamentoSeleccionado} autoHighlight dropdown dropdownMode={'current'} forceSelection disabled={!departamentosfiltrado} suggestions={departamentosSeleccionados} completeMethod={searchDepartamento} field="nombre"
                                    onSelect={(e) => props.onSelect('departamento', e.value.nombre)}
                                    onFocus={() => {
                                        if (!departamentosSeleccionados && departamentoSeleccionado) {
                                            searchDepartamento({ query: departamentoSeleccionado.nombre })
                                        }
                                    }}
                                    onChange={(e) => {
                                        //console.log(e.value)
                                        // if (!e.value && departamentoSeleccionado) {
                                        //     e.value = departamentoSeleccionado
                                        // }
                                        setDepartamentoSeleccionado(e.value)
                                        //console.log(e.value)
                                        if (e.value) {
                                            if (departamentoSeleccionado) {
                                                //console.log("Departamento, evento change evento", e.value)
                                                if (departamentoSeleccionado.nombre !== e.value.nombre) {
                                                    datosDepartamento(e.value)
                                                }
                                            } else {
                                                datosDepartamento(e.value)
                                            }
                                        } else {
                                            if (provinciaSeleccionada && departamentoSeleccionado) {
                                                if (!localidadSeleccionada)
                                                    posicionar(provinciaSeleccionada.centroide, 5)
                                                loadLocalidad({ provincia: provinciaSeleccionada })
                                            }
                                        }
                                    }} className={classNames({ 'p-invalid': props.submitted && !departamentoSeleccionado && props.departamentoRequerido })} />
                                {props.submitted && !departamentoSeleccionado && props.departamentoRequerido && <small className="p-invalid">Debe indicar el departamento</small>}
                            </div>

                            <div >
                                <AutoComplete autoFocus={true} id="IdLocalidad" placeholder="Localidad*" autoHighlight={true}
                                    value={localidadSeleccionada} dropdown dropdownMode={'current'} forceSelection disabled={!localidadesfiltrado} suggestions={localidadesSeleccionadas} completeMethod={searchLocalidad} field="nombre" onSelect={(e) => {
                                        props.onSelect('localidad', e.value.nombre);

                                    }}
                                    onFocus={() => {
                                        if (!localidadesSeleccionadas && localidadSeleccionada) {
                                            searchLocalidad({ query: localidadSeleccionada.nombre })
                                        }
                                    }}
                                    onChange={(e) => {
                                        setLocalidadSeleccionada(e.value)
                                        if (e.value) {
                                            if (localidadSeleccionada) {
                                                if (localidadSeleccionada.nombre !== e.value.nombre) {
                                                    posicionar(e.value.centroide, 12)
                                                }
                                            } else {
                                                // if (!departamentoSeleccionado) {
                                                //console.log(departamentosfiltrado.find((d: any) => d.nombre = e.value.departamento.nombre))
                                                //     setDepartamentoSeleccionado(departamentosfiltrado.find((d: any) => d.nombre = e.value.departamento.nombre))
                                                // }
                                                posicionar(e.value.centroide, 12)
                                            }
                                        } else {
                                            if (departamentoSeleccionado) {
                                                posicionar(departamentoSeleccionado.centroide, 8)
                                            } else if (provinciaSeleccionada) {
                                                //console.log(localidadSeleccionada)
                                                //setDepartamentoSeleccionado(provinciaSeleccionada)
                                                //datosDepartamento(provinciaSeleccionada.departamento)
                                                posicionar(provinciaSeleccionada.centroide, 5)
                                            }
                                        }

                                    }} className={classNames({ 'p-invalid': props.submitted && !localidadSeleccionada && props.localidadRequerido })}
                                />
                                {props.submitted && !localidadSeleccionada && props.localidadRequerido && <small className="p-invalid">Debe indicar la localidad</small>}
                            </div>
                        </div>

                        <br />
                        <br />
                        <br />
                        <div className="p-card">
                            {!props.hidemap ? <Mapa soloLectura={true} height={'300px'} zoom={zoom} coordenada={coordenada}></Mapa> : null}
                        </div>

                        {!loading && inicio ? <Activar provinciaNombre={props.provincia} departamentoNombre={props.departamento} localidadNombre={props.localidad} /> : null}

                    </div>
                </div>

            </>
    )
}

interface locationProps {
    provincia: any,
    provinciaRequerido: boolean,
    departamento: any,
    departamentoRequerido: boolean,
    localidad: any,
    localidadRequerido: boolean,
    style?: any,
    submitted: boolean,
    hidemap?: boolean,
    onSelect: (campo: string, valor: any) => void
}

Location.defaultProps = {
    provincia: '',
    departamento: '',
    localidad: '',
    provinciaRequerido: false,
    departamentoRequerido: false,
    localidadRequerido: false,
}