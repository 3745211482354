import { useEffect, useState } from 'react'
import { ITableColumn } from 'utils/interfaces';
import { urlDepositosEmpresa, urlEmpresasActivos, urlGenericEntitiesRows } from 'app/service/Endpoints'
import AGDropdown from 'app/components/AGDropdown'
import { IStockFiltros } from 'app/dto/stock.mode'
import { Fieldset } from 'primereact/fieldset';

export default function Filtros(props: filtrosProps) {

    const emptyRow: IStockFiltros = {
        empresa: undefined,
        deposito: undefined,
        insumotipo: undefined,
    }

    const [record, setRecord] = useState<IStockFiltros>(emptyRow);
    const [loading, setLoading] = useState(true);

    const columnas: ITableColumn[] = [
        { id: 0, field: 'empresa', hide: true, optionsFromEndPoint: urlEmpresasActivos, optionName: 'razonsocial', header: 'Empresa', required: true, disabled: false, colInput: 0, type: 'dropdown', sortable: false },
        { id: 1, field: 'deposito', hide: true, required: true, colInput: 1, header: 'Depósito', type: 'dropdown', optionsFromEndPoint: urlDepositosEmpresa, optionModeQuery: 'id', optionName: 'nombre', },
        { id: 2, field: 'insumotipo', hide: false, required: true, colInput: 0, autoFocus: true, type: 'dropdown', optionsFromEndPoint: urlGenericEntitiesRows, optionsEntityId: 10, optionsShowValue: true, optionName: 'Nombre', header: 'Tipo de insumo', sortable: true },
        //optionValue: 'Id'

    ];

    useEffect(() => {
        if (props.values && loading) {
            //console.log("en filtros", props.values)
            setLoading(false)
            changed('empresa', props.values.empresa)
            //setRecord(props.values)
            //changed('deposito', props.values.deposito)
        }
    })


    function changed(field: string, value: any, source?: IStockFiltros) {
        let _record: IStockFiltros;
        let _empresaid: number;

        var key = field,
            obj = {
                [key]: value
            };

        if (source) {
            _empresaid = source.empresa?.id;
            _record = { ...source, ...obj }
        } else {
            _empresaid = record.empresa?.id;
            _record = { ...record, ...obj }
        }

        if (field === 'empresa' && (_record.empresa === undefined || _empresaid !== _record.empresa.id)) {
            _record.deposito = undefined;
        }

        //console.log("field new record", field, _record)
        setRecord(x => x = _record)
        props.onChanged(_record);
    }

    return (
        <>
            <div className="card mt-2">
                <div className="col-12">
                    {/* <h6>Filtros</h6> */}
                    <Fieldset collapsed={false} legend="Filtros" toggleable>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-4">
                                <AGDropdown col={columnas.find((x) => x.field === 'empresa')!} value={record.empresa!} onChanged={
                                    (x) => changed('empresa', x)} />
                            </div>
                            <div className="p-field p-col-12 p-md-4">
                                <AGDropdown col={columnas.find((x) => x.field === 'deposito')!} value={record.deposito} id={record.empresa?.id} onChanged={(x) => changed('deposito', x)} />
                            </div>
                            <div className="p-field p-col-12 p-md-4">
                                <AGDropdown col={columnas.find((x) => x.field === 'insumotipo')!} value={record.insumotipo} onChanged={(x) => { ///console.log(x); 
                                    changed('insumotipo', x)
                                }} />
                            </div>
                        </div>
                    </Fieldset>
                </div>
            </div>

        </>
    )
}

interface filtrosProps {
    values?: IStockFiltros;
    onChanged: (values: IStockFiltros) => void;
}