import FieldSetup from "./FieldSetup";
import { Dialog } from 'primereact/dialog';
import { useState, useEffect } from 'react'
import { genericentityDTO } from "app/dto/genericentities.model"
import { urlGenericEntities, urlGenericEntitiesSetup } from "app/service/Endpoints"
import { GenericService } from 'app/service/GenericService';
import { Button } from 'primereact/button'
import { Divider } from 'primereact/divider'
import { genericEntityFieldSetupCreacionDTO, genericEntityFieldSetupDTO } from 'app/dto/genericentityfieldsetup.model'
import { TRecordField } from 'utils/interfaces'
import Cargando from "utils/Cargando";

export default function EntitySetup(props: entitySetupProps) {

    const [dialog, setDialog] = useState(true)
    const [entity, setEntity] = useState<genericentityDTO>()
    const [loading, setLoading] = useState(true)

    const [rowsLoading, setRowsLoading] = useState<TRecordField[]>()
    const [rows, setRows] = useState<TRecordField[]>()

    const service = new GenericService();

    useEffect(() => {
        if (loading)
            if (props.id) {
                setLoading((x) => !x)
                //alert(props.id)
                service.getId({ url: urlGenericEntities, id: props.id }).then(response => {
                    if (response.success) {
                        //setErrores([]);
                        //console.log("get id", props.id)
                        setEntity(response.data);
                        service.getId({ url: urlGenericEntities, subOne: 'setup', id: props.id }).then(response => {
                            if (response.success) {
                                //console.log("get setup", response.data)
                                load(response.data)
                                //alert("Cargado u set loading false")
                            } else {
                                setLoading((x) => !x)
                            }
                        });

                        //setDialog(true);
                    }
                    else {
                        //props.close!(response.error);
                    }
                }).catch(error => {
                    //alert("catch")
                    setLoading((x) => !x)
                    console.log(error);
                    // if (props.updateMode) {
                    //     props.close!();
                    // }
                })
            };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const HeaderTemplate = () => {
        return (
            <div >
                {entity ?
                    "Propiedades de la entidad " + entity.name
                    : null}
            </div>

        )
    }

    const DialogFooter = () => {
        return (
            <>
                <Divider />
                <Button label="Cancelar" icon="pi pi-times" className="p-button-text  p-button-danger" onClick={hideDialog} />
                <Button label="Guardar" icon="pi pi-check" className="p-button-text p-button-primary" onClick={save} />
            </>
        )
    };

    const hideDialog = () => {
        props.close!(); setDialog(false)
    }

    const load = (data: genericEntityFieldSetupDTO[]) => {
        if (data) {
            let newField: TRecordField;
            let newFields: TRecordField[];
            //console.log(data)
            data.forEach((element, index) => {
                //console.log(element)
                newField = {
                    fieldId: element.fieldId!,
                    fieldName: element.fieldName,
                    fieldType: element.fieldType,
                    fieldSize: element.fieldSize,
                }

                if (!newFields) {
                    newFields = [newField]
                } else {
                    newFields.push(newField)
                }
            });
            //console.log(newFields!)
            setRowsLoading(newFields!)
        }
    }


    const save = () => {

        //alert("Actualizando " + rows?.length + " campos")
        //service.post({ url: urlGenericEntities })

        if (rows) {

            let newField: genericEntityFieldSetupCreacionDTO;
            let newFields: genericEntityFieldSetupCreacionDTO[];

            //console.log("rows", rows)
            //console.log("index 0", rows[0])


            rows.forEach((element, index) => {
                newField = {
                    fieldposition: index,
                    fieldname: element.fieldName,
                    fieldtype: element.fieldType,
                    fieldsize: element.fieldSize,
                    genericentityid: props.id,
                }

                if (!newFields) {
                    newFields = [newField]
                } else {
                    newFields.push(newField)
                }
            });

            service.postId({ url: urlGenericEntitiesSetup, creacion: newFields!, id: props.id! })

            //console.log(newFields!)

            // let newFields = rows.map((e: TRecordField) => {
            //     return :genericEntityFieldSetupCreacionDTO {

            //     }
            // })
        }



        props.close!(); setDialog(false)
    }

    return (
        <>
            {loading ? <Cargando /> :
                <Dialog className='crud-dialog' closable={true} modal closeOnEscape={true} visible={dialog} blockScroll={true} position={'right'} header={HeaderTemplate} footer={DialogFooter} onHide={() => { props.close!(); setDialog(false) }} > <FieldSetup title={'Propiedad'} rowsLoading={rowsLoading} rowsChanged={(rows) => setRows(rows)} />
                </Dialog>}
        </>
    )

}

interface entitySetupProps {
    id: number,
    close?: (mensaje?: string) => void;
    save?: (row: any) => void;
}