export default function MostrarErrores(props: mostrarErroresProps) {
    const style = { color: 'red' }

    const traducir = (error: string) => {
        switch (error) {
            case "Network Error":
                return "Servidor no disponible"

            case "Request failed with status code 401":
                return "No tiene autorización para acceder a los datos."

            case "Request failed with status code 402":
                return "No tiene autorización para acceder a los datos."

            case "Request failed with status code 403":
                return "No tiene autorización para acceder a los datos."

            case "Request failed with status code 404":
                return "No tiene autorización para acceder a los datos."

            case "Request failed with status code 405":
                return "No tiene autorización para acceder a los datos."

            default:
                return error
        }
    }


    return (
        <>
            {props.errores ? <ul style={style}>
                <small>{props.errores.map((error, indice) => <li key={indice}>{traducir(error)}</li>)}</small>
            </ul> : null}
        </>
    )
}

interface mostrarErroresProps {
    errores?: string[];
}