import React from "react";
import { Document, Page, Text, View, Image, Canvas, Link, StyleSheet, Line } from "@react-pdf/renderer";
import DocumentWeb from "./DocumentWeb";
import './styles.css'
import { formatearFechaDMY } from 'utils/formDataUtils'


const DocumentPDF = ({ props }) => {

  console.log("props", props)
  const orden = props.values;
  const { campaña } = props.values;
  //console.log(orden)

  const styles = StyleSheet.create({
    page: { display: "flex", flexDirection: "column", justifyContent: "top", alignItems: "center", backgroundColor: "white", textAlign: "left", },
    container: {
      flexDirection: 'column',
      borderBottomWidth: 1,
      borderBottomColor: '#112131',
      borderBottomStyle: 'solid',
      alignItems: 'stretch',
      height: '15%',
    },
    header: { width: '95%', height: '5%', marginTop: '5px', padding: '0px', backgroundColor: 'rgba(200, 200, 200, 0.8)', border: '1px solid rgba(0, 0, 0)', justifyContent: 'center', fontSize: '12px' },
    body: { width: '95%', height: '85%', padding: '0px', backgroundColor: 'white', border: '1px solid rgba(0, 0, 0, 1)', justifyContent: 'flex-start', fontSize: '12px' },

    col1: { left: '5mm', top: '0mm' },
    col8: { left: '80mm', top: '0mm' },
    col13: { left: '130mm', top: '0mm' },

  });

  return (
    <Document title={'ORDEN DE TRABAJO ' + props.values.id} >
      <Page
        size="A4"
        style={styles.page}

      >
        <View style={styles.header} >
          <View style={styles.col1} >
            <Text>
              {'ORDEN DE TRABAJO Nº ' + orden.id}
            </Text>
            <Text style={{ position: 'absolute', left: '130mm', top: '0' }}>
              {'CAMPAÑA: ' + orden.campaña.nombre}
            </Text>
            <Text style={{ position: 'relative', left: '130mm', marginTop: '1px' }}>
              {'FECHA: ' + formatearFechaDMY(orden.fecha)}
            </Text>
          </View>
        </View>
        <View style={styles.body} >
          <View style={styles.container} >
            <Text style={{ position: 'absolute', left: '5mm', top: '5mm' }}>
              {"EMPRESA"}
            </Text>
            <Text style={{ position: 'absolute', left: '80mm', top: '5mm' }}>
              {orden.empresa.razonsocial}
            </Text>
            <Text style={{ position: 'absolute', left: '5mm', top: '11mm' }}>
              {"ESTABLECIMIENTO"}
            </Text>
            <Text style={{ position: 'absolute', left: '80mm', top: '11mm' }}>
              {orden.establecimiento.nombre}
            </Text>

            <Text style={{ position: 'absolute', left: '5mm', top: '17mm' }}>
              {"USUARIO"}
            </Text>
            <Text style={{ position: 'absolute', left: '80mm', top: '17mm' }}>
              {"Juan Pérez"}
            </Text>
          </View>
        </View>

      </Page>
    </Document>
  );
};

export default DocumentPDF;
