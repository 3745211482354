import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { GenericService } from 'app/service/GenericService';
import { urlInsumosAplicacionByInsumo } from 'app/service/Endpoints'
import { insumoaplicacionDTO } from 'app/dto/insumosaplicacion.mode'

export default function InsumoAplicaciones(props: any) {

    const [rows, setRows] = useState<insumoaplicacionDTO[]>();
    const [refresh, setRefresh] = useState(true);

    const service = new GenericService();

    useEffect(() => {
        if (refresh) {
            let id: number;
            //debugger
            if (props.id) {
                id = props.id
            } else {
                id = 0
            }
            console.log(urlInsumosAplicacionByInsumo, id)
            service.getId({ url: urlInsumosAplicacionByInsumo, id: id }).then(data => { setRows(data.data); console.log(data) }).catch(error => console.log(error));
            setRefresh(false)
        }
    }, [refresh]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <div className="grid">
                <DataTable value={rows} emptyMessage="No hay registros." loading={refresh}  >
                    <Column field={'especienombre'} header="Especie"></Column>
                    <Column field={'estadiodesdenombre'} header="Desde"></Column>
                    <Column field={'estadiohastanombre'} header="Hasta"></Column>
                    <Column field={'dosissugerida'} header="Dosis sugerida"></Column>
                </DataTable>
            </div>
        </div>
    );
}
